import { Button } from "@primitives/button";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { TabNavbar } from "@components/tab-navbar";
import { Dispatch, SetStateAction } from "react";
import { CsvButton } from "@pages/simple-search/components/csv-button";
import { Column } from "@tanstack/react-table";
import { Organization } from "../../../../../api-contracts/customer-list/organization";

export const Header = ({
  tab,
  openOrganizationSheet,
  openCustomerSheet,
  columns,
  columnData,
}: {
  tab: "organization" | "people";
  openOrganizationSheet: (org: Organization | undefined) => void;
  openCustomerSheet: Dispatch<SetStateAction<boolean>>;
  columns: Column<Organization, unknown>[];
  columnData: Organization[] | undefined;
}) => {
  const { module } = useProfileContext();
  const { t } = useTranslation();

  const tabItems = [
    {
      value: "organization",
      label: t("company"),
      to: `/${module}/${ROUTES.CUSTOMER_LIST}/organization`,
    },
    {
      value: "people",
      label: t("people"),
      to: `/${module}/${ROUTES.CUSTOMER_LIST}/people`,
      disabled: true,
    },
  ];
  return (
    <div className="flex flex-col">
      <TabNavbar items={tabItems} value={tab} />
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-2">
          <h1 className="font-itc text-[30px] font-bold text-primary-text">
            {t("customers")}
          </h1>
          <div className="flex">
            <div className="mx-4">
              <CsvButton
                columns={columns}
                data={columnData ?? []}
                buttonContent={t("export")}
              />
            </div>
            <Button
              onClick={
                tab === "organization"
                  ? () => openOrganizationSheet(undefined)
                  : () => openCustomerSheet(true)
              }
            >
              {tab === "organization" ? t("new-company") : t("new-people")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
