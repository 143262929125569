import { NavigationArrows } from "@components/navigation-arrows";
import { useToast } from "@hooks/use-toast";
import { Button } from "@primitives/button";
import { Card } from "@primitives/card";
import DatePicker from "@primitives/date-picker";
import { FloatingLabelInput } from "@primitives/floating-input";
import { Label } from "@primitives/label";
import { SearchBar } from "@primitives/search-bar";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { Switch } from "@primitives/switch";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { ROUTES } from "@shared/types/navigation";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Filter,
  HotelIcon,
  Loader2,
  SquareStack,
  User2,
  Webhook,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ModuleName } from "../../../../../api-contracts/admin";
import { Image, PostImageResponse } from "../../../../../api-contracts/images";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import { queryClient } from "query-client";
import { useCreateSiteAdmin } from "@api/admin";
import { addYears, format } from "date-fns";
import { SystemConfig } from "../../../../../api-contracts/sites";
import { Checkbox } from "@primitives/checkbox";
import { useGetSiteRoles } from "@api/roles";
import { Role } from "../../../../../api-contracts/roles";
import { useGetImage, useUploadImage } from "@api/images";
import { FileUploader } from "react-drag-drop-files";
import { imageFileTypes } from "@components/media-library";
import CountrySelect from "@components/country-select";
import { Popover } from "@primitives/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import { User } from "../../../../../api-contracts/users";
import { useGetSite, useUpdateSite } from "@api/site.ts";
import { useGetUsers, useGetUsers1 } from "@api/users.ts";

export const SITE_TABS = {
  INFORMATION: "information",
  CONFIG: "config",
  ROLES: "roles",
  USERS: "users",
};

const initialSystemConfig: SystemConfig = {
  siteId: "new",
  name: "",
  organizationNumber: "",
  organizationName: "",
  hotelCode: null,
  personInCharge: null,
  anonymizationDelayDays: 0,
  messagingEnabled: false,
  locale: "sv-SE",
  tz: "Europe/Stockholm",
  currency: "SEK",
  addressInfo: {
    address: null,
    address2: null,
    zip: null,
    city: null,
    state: null,
    country: null,
    email: null,
  },
  billingInfo: {
    address: null,
    address2: null,
    zip: null,
    city: null,
    state: null,
    country: null,
    email: null,
    reference: null,
  },
  startDate: format(new Date(), "yyyy-MM-dd"),
  endDate: format(addYears(new Date(), 1), "yyyy-MM-dd"),
  active: false,
  phone: null,
  url: null,
};

const initialModules: ModuleName[] = ["roomr"];

const SuperadminSitePage = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { id, tab } = useParams();
  const activeTab = tab ?? SITE_TABS.INFORMATION;
  const [systemConfig, setSystemConfig] =
    useState<SystemConfig>(initialSystemConfig);
  const [modules, setModules] = useState<ModuleName[]>(initialModules);
  const [thumbnail, setThumbnail] = useState<Image>();
  const [uploadedImage, setUploadedImage] = useState<PostImageResponse>();
  const newSite = id === "new";
  const [isEditing, setIsEditing] = useState<boolean>(id === "new");

  const updateSite = useUpdateSite();
  const createSite = useCreateSiteAdmin();
  const uploadImg = useUploadImage();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetSite.getKey(),
    });
  }, []);

  const { data, isLoading, isRefetching } = useGetSite({
    variables: { siteId: id ?? "" },
    enabled: !newSite,
  });

  const {
    data: imageData,
    isLoading: imageIsLoading,
    isRefetching: imageIsRefetching,
  } = useGetImage({
    variables: {
      id: uploadedImage?.id ?? thumbnail?.id ?? "",
      siteId: id,
      variant: "thumbnail",
    },
    enabled: !!uploadedImage || !!thumbnail,
  });

  useEffect(() => {
    setSystemConfig(
      data ? { ...data.systemConfig } : { ...initialSystemConfig },
    );
    setModules(data ? data.modules : initialModules);
    setThumbnail(data ? (data.thumbnail ?? undefined) : undefined);
  }, [data]);

  const handleCancel = () => {
    setSystemConfig(
      data ? { ...data.systemConfig } : { ...initialSystemConfig },
    );
    setModules(data ? [...data.modules] : initialModules);
    setThumbnail(data ? (data.thumbnail ?? undefined) : undefined);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      if (id && id !== "new") {
        await updateSite.mutateAsync({
          id,
          name: systemConfig.name,
          thumbnail: uploadedImage?.id ?? thumbnail?.id ?? null,
          modules,
          systemConfig: {
            organizationNumber: systemConfig.organizationNumber,
            organizationName: systemConfig.organizationName,
            hotelCode: systemConfig.hotelCode?.trim() ?? null,
            personInCharge: systemConfig.personInCharge?.trim() ?? null,
            anonymizationDelayDays: 30, // TODO remove?
            messagingEnabled: true, // TODO remove?
            locale: systemConfig.locale,
            tz: systemConfig.tz,
            currency: systemConfig.currency,
            addressInfo: {
              address: systemConfig.addressInfo.address?.trim() ?? null,
              address2: systemConfig.addressInfo.address2?.trim() ?? null,
              zip: systemConfig.addressInfo.zip?.trim() ?? null,
              city: systemConfig.addressInfo.city?.trim() ?? null,
              state: systemConfig.addressInfo.state?.trim() ?? null,
              country: systemConfig.addressInfo.country?.trim() ?? null,
              email: systemConfig.addressInfo.email?.trim() ?? null,
            },
            billingInfo: {
              reference: systemConfig.billingInfo.reference?.trim() ?? null,
              address: systemConfig.billingInfo.address?.trim() ?? null,
              address2: systemConfig.billingInfo.address2?.trim() ?? null,
              zip: systemConfig.billingInfo.zip?.trim() ?? null,
              city: systemConfig.billingInfo.city?.trim() ?? null,
              state: systemConfig.billingInfo.state?.trim() ?? null,
              country: systemConfig.billingInfo.country?.trim() ?? null,
              email: systemConfig.billingInfo.email?.trim() ?? null,
            },
            startDate: systemConfig.startDate,
            endDate: systemConfig.endDate,
            phone: systemConfig.phone?.trim() ?? null,
            url: systemConfig.url?.trim() ?? null,
          },
        });
      } else {
        await createSite.mutateAsync({
          name: systemConfig.name,
          modules,
          thumbnail: uploadedImage?.id ?? thumbnail?.id ?? null,
          systemConfig: {
            organizationNumber: systemConfig.organizationNumber,
            organizationName: systemConfig.organizationName,
            hotelCode: systemConfig.hotelCode?.trim() ?? null,
            personInCharge: systemConfig.personInCharge?.trim() ?? null,
            anonymizationDelayDays: 30, // TODO remove?
            messagingEnabled: true, // TODO remove?
            locale: systemConfig.locale,
            tz: systemConfig.tz,
            currency: systemConfig.currency ?? "",
            addressInfo: {
              address: systemConfig.addressInfo.address?.trim() ?? null,
              address2: systemConfig.addressInfo.address2?.trim() ?? null,
              zip: systemConfig.addressInfo.zip?.trim() ?? null,
              city: systemConfig.addressInfo.city?.trim() ?? null,
              state: systemConfig.addressInfo.state?.trim() ?? null,
              country: systemConfig.addressInfo.country?.trim() ?? null,
              email: systemConfig.addressInfo.email?.trim() ?? null,
            },
            billingInfo: {
              reference: systemConfig.billingInfo.reference?.trim() ?? null,
              address: systemConfig.billingInfo.address?.trim() ?? null,
              address2: systemConfig.billingInfo.address2?.trim() ?? null,
              zip: systemConfig.billingInfo.zip?.trim() ?? null,
              city: systemConfig.billingInfo.city?.trim() ?? null,
              state: systemConfig.billingInfo.state?.trim() ?? null,
              country: systemConfig.billingInfo.country?.trim() ?? null,
              email: systemConfig.billingInfo.email?.trim() ?? null,
            },
            startDate: systemConfig.startDate,
            endDate: systemConfig.endDate,
            phone: systemConfig.phone?.trim() ?? null,
            url: systemConfig.url?.trim() ?? null,
          },
        });
      }

      if (uploadedImage) {
      }

      queryClient.invalidateQueries({
        queryKey: useGetSite.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: systemConfig.name }),
        className: "text-status-success",
        variant: "success",
      });

      setIsEditing(false);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const uploadImage = async (file: File, tag: string, description: string) => {
    try {
      const res = await uploadImg.mutateAsync({
        file: file,
        tags: [tag],
        description,
        siteId: id,
      });
      setUploadedImage(res);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div className="p-4 text-primary-text">
        <NavigationArrows rootPath={`/admin/${ROUTES.SITES}`} />
        <h2 className=" mt-4 text-2xl font-bold">
          {newSite ? t("create-new-site") : systemConfig.name}
        </h2>
        <div className=" flex flex-nowrap justify-between">
          <Tabs className=" mb-4 mt-4" value={activeTab}>
            <TabsList>
              <Link
                to={`/admin/${ROUTES.SITES}/${id}/${SITE_TABS.INFORMATION}`}
              >
                <TabsTrigger value={SITE_TABS.INFORMATION}>
                  {t(SITE_TABS.INFORMATION)}
                </TabsTrigger>
              </Link>
              <Link to={`/admin/${ROUTES.SITES}/${id}/${SITE_TABS.CONFIG}`}>
                <TabsTrigger value={SITE_TABS.CONFIG}>
                  {t(SITE_TABS.CONFIG)}
                </TabsTrigger>
              </Link>
              <Link to={`/admin/${ROUTES.SITES}/${id}/${SITE_TABS.ROLES}`}>
                <TabsTrigger value={SITE_TABS.ROLES}>
                  {t(SITE_TABS.ROLES)}
                </TabsTrigger>
              </Link>
              {newSite ? (
                <Tooltip delayDuration={100}>
                  <TooltipTrigger>
                    <TabsTrigger value={SITE_TABS.USERS} disabled>
                      {t(SITE_TABS.USERS)}
                    </TabsTrigger>
                  </TooltipTrigger>
                  <TooltipContent className=" bg-primary-button-backplate">
                    {t("save-site-before-configuring-users")}
                  </TooltipContent>
                </Tooltip>
              ) : (
                <Link to={`/admin/${ROUTES.SITES}/${id}/${SITE_TABS.USERS}`}>
                  <TabsTrigger value={SITE_TABS.USERS}>
                    {t(SITE_TABS.USERS)}
                  </TabsTrigger>
                </Link>
              )}
            </TabsList>
          </Tabs>
          {tab !== SITE_TABS.USERS && (
            <div className=" flex items-center space-x-4">
              {isEditing && <Button onClick={handleSave}>{t("save")}</Button>}
              {isEditing && !newSite && (
                <Button variant="secondary" onClick={handleCancel}>
                  {t("cancel")}
                </Button>
              )}
              {!isEditing && (
                <Button onClick={() => setIsEditing(true)}>{t("edit")}</Button>
              )}
            </div>
          )}
        </div>
        <div>
          {tab === SITE_TABS.INFORMATION && (
            <div className=" flex space-x-4">
              <Card className=" flex-1">
                {id && (
                  <h3 className=" text-sm font-bold">
                    {t("customer-number")} {id}
                  </h3>
                )}
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("organization-number")}
                  required
                  value={systemConfig?.organizationNumber}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      organizationNumber: e.target.value,
                    })
                  }
                  id="organization-number"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  required
                  label={t("company-name")}
                  value={systemConfig?.organizationName}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      organizationName: e.target.value,
                    })
                  }
                  id="company-name"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("website")}
                  value={systemConfig?.url ?? ""}
                  onChange={(e) =>
                    setSystemConfig({ ...systemConfig, url: e.target.value })
                  }
                  id="website"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("responsible-person")}
                  value={systemConfig?.personInCharge ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      personInCharge: e.target.value,
                    })
                  }
                  id="responsible-person"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("phone")}
                  value={systemConfig?.phone ?? ""}
                  onChange={(e) =>
                    setSystemConfig({ ...systemConfig, phone: e.target.value })
                  }
                  id="phone"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("email")}
                  value={systemConfig?.addressInfo.email ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      addressInfo: {
                        ...systemConfig.addressInfo,
                        email: e.target.value,
                      },
                    })
                  }
                  id="email"
                  className=" mt-4"
                />
                <h3 className=" my-4 text-sm font-bold">{t("active")}</h3>
                <div className=" flex space-x-4 ">
                  <div className=" flex-1">
                    <Label className="text-xs font-normal">{t("from")}</Label>
                    <DatePicker
                      disabled={!isEditing}
                      clearable={false}
                      value={new Date(systemConfig.startDate)}
                      onValueChange={(date) =>
                        date &&
                        setSystemConfig({
                          ...systemConfig,
                          startDate: format(date, "yyyy-MM-dd"),
                        })
                      }
                    />
                  </div>
                  <div className=" flex-1">
                    <Label className="text-xs font-normal">{t("to")}</Label>
                    <DatePicker
                      disabled={!isEditing}
                      clearable={false}
                      value={new Date(systemConfig.endDate)}
                      onValueChange={(date) =>
                        date &&
                        setSystemConfig({
                          ...systemConfig,
                          endDate: format(date, "yyyy-MM-dd"),
                        })
                      }
                    />
                  </div>
                </div>
              </Card>
              <Card className=" flex-1">
                <h3 className=" text-sm font-bold">{t("address")}</h3>
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("address-row-1")}
                  value={systemConfig?.addressInfo.address ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      addressInfo: {
                        ...systemConfig.addressInfo,
                        address: e.target.value,
                      },
                    })
                  }
                  id="address-1"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("address-row-2")}
                  value={systemConfig?.addressInfo.address2 ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      addressInfo: {
                        ...systemConfig.addressInfo,
                        address2: e.target.value,
                      },
                    })
                  }
                  id="address-2"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("zip-code")}
                  value={systemConfig?.addressInfo.zip ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      addressInfo: {
                        ...systemConfig.addressInfo,
                        zip: e.target.value,
                      },
                    })
                  }
                  id="zip-code"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("city")}
                  value={systemConfig?.addressInfo.city ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      addressInfo: {
                        ...systemConfig.addressInfo,
                        city: e.target.value,
                      },
                    })
                  }
                  id="city"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("state/province/region")}
                  value={systemConfig?.addressInfo.state ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      addressInfo: {
                        ...systemConfig.addressInfo,
                        state: e.target.value,
                      },
                    })
                  }
                  id="state/province/region"
                  className=" mt-4"
                />
                <div className=" mt-4 ">
                  <CountrySelect
                    disabled={!isEditing}
                    label={t("country")}
                    id="country"
                    value={systemConfig?.addressInfo.country ?? ""}
                    onValueChange={(v) =>
                      setSystemConfig({
                        ...systemConfig,
                        addressInfo: {
                          ...systemConfig.addressInfo,
                          country: v,
                        },
                      })
                    }
                  />
                </div>
              </Card>
              <Card className=" flex-1">
                <h3 className=" text-sm font-bold">{t("billing-address")}</h3>
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("reference")}
                  value={systemConfig?.billingInfo.reference ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        reference: e.target.value,
                      },
                    })
                  }
                  id="billing-reference"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("address-row-1")}
                  value={systemConfig?.billingInfo.address ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        address: e.target.value,
                      },
                    })
                  }
                  id="billing-address-1"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("address-row-2")}
                  value={systemConfig?.billingInfo.address2 ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        address2: e.target.value,
                      },
                    })
                  }
                  id="billing-address-2"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("zip-code")}
                  value={systemConfig?.billingInfo.zip ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        zip: e.target.value,
                      },
                    })
                  }
                  id="billing-zip-code"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("city")}
                  value={systemConfig?.billingInfo.city ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        city: e.target.value,
                      },
                    })
                  }
                  id="billing-city"
                  className=" mt-4"
                />
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("state/province/region")}
                  value={systemConfig?.billingInfo.state ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        state: e.target.value,
                      },
                    })
                  }
                  id="billing-state/province/region"
                  className=" mt-4"
                />
                <div className=" mt-4 ">
                  <CountrySelect
                    disabled={!isEditing}
                    label={t("country")}
                    id="billing-country"
                    value={systemConfig?.billingInfo.country ?? ""}
                    onValueChange={(v) =>
                      setSystemConfig({
                        ...systemConfig,
                        billingInfo: {
                          ...systemConfig.billingInfo,
                          country: v,
                        },
                      })
                    }
                  />
                </div>
                <FloatingLabelInput
                  disabled={!isEditing}
                  label={t("e-invoice-address")}
                  value={systemConfig?.billingInfo.email ?? ""}
                  onChange={(e) =>
                    setSystemConfig({
                      ...systemConfig,
                      billingInfo: {
                        ...systemConfig.billingInfo,
                        email: e.target.value,
                      },
                    })
                  }
                  id="billing-email"
                  className=" mt-4"
                />
              </Card>
            </div>
          )}
          {tab === SITE_TABS.CONFIG && (
            <div className=" flex space-x-4">
              <Card className=" w-1/3">
                <h3 className=" text-sm font-bold">{t("site-information")}</h3>
                <FloatingLabelInput
                  disabled={!isEditing}
                  required
                  label={t("site-name")}
                  value={systemConfig?.name}
                  onChange={(e) =>
                    setSystemConfig({ ...systemConfig, name: e.target.value })
                  }
                  id="site-name"
                  className=" mb-4 mt-4"
                />

                <div className=" flex items-center space-x-4 rounded-lg bg-primary-button-backplate p-4">
                  <FileUploader
                    disabled={!isEditing}
                    handleChange={(file: File) =>
                      uploadImage(file, "site", "site")
                    }
                    name="file"
                    types={imageFileTypes}
                  >
                    <div className=" flex items-center space-x-4">
                      {imageData?.length ? (
                        <img
                          className=" h-[80px] w-[80px]"
                          aria-description={
                            imageData[0]?.description ?? undefined
                          }
                          src={imageData[0]?.url}
                        />
                      ) : (
                        <div className=" flex h-[80px] w-[80px] items-center justify-center rounded bg-secondary-card-backplate">
                          <HotelIcon size={24} />
                        </div>
                      )}

                      <Button size="sm" disabled={!isEditing}>
                        {t("select-image")}
                      </Button>
                    </div>
                  </FileUploader>
                  <Button
                    disabled={!isEditing}
                    variant="danger"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setUploadedImage(undefined);
                      setThumbnail(undefined);
                    }}
                  >
                    {t("remove")}
                  </Button>
                </div>
                <div className=" mt-2">
                  <Label
                    htmlFor="timezone"
                    className=" text-xs text-secondary-text"
                  >
                    {t("timezone")}
                  </Label>
                  <Select value={systemConfig.tz} disabled={!isEditing}>
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("timezone")} />
                    </SelectTrigger>
                    <SelectContent></SelectContent>
                  </Select>
                </div>
                <div className=" mt-2">
                  <Label
                    htmlFor="language"
                    className=" text-xs text-secondary-text"
                  >
                    {t("language")}
                  </Label>
                  <Select value={systemConfig.locale} disabled={!isEditing}>
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("language")} />
                    </SelectTrigger>
                    <SelectContent></SelectContent>
                  </Select>
                </div>
                <div className=" mt-2">
                  <Label
                    htmlFor="currency"
                    className=" text-xs text-secondary-text"
                  >
                    {t("currency")}
                  </Label>
                  <Select value={systemConfig.currency} disabled={!isEditing}>
                    <SelectTrigger className="min-w-[170px] text-xs">
                      <SelectValue placeholder={t("currency")} />
                    </SelectTrigger>
                    <SelectContent></SelectContent>
                  </Select>
                </div>
              </Card>
              <Card className=" w-2/3">
                <h3 className=" text-sm font-bold">{t("modules")}</h3>
                <div className=" mt-4 flex space-x-4 ">
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Roomr</label>
                    <Switch
                      disabled={!isEditing}
                      checked={modules.includes("roomr")}
                      onCheckedChange={(checked) =>
                        setModules((m) =>
                          checked
                            ? [...m, "roomr"]
                            : m.filter((mod) => mod !== "roomr"),
                        )
                      }
                    />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">WBE</label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Maintainr</label>
                    <Switch
                      disabled={!isEditing}
                      checked={modules.includes("maintainr")}
                      onCheckedChange={(checked) =>
                        setModules((m) =>
                          checked
                            ? [...m, "maintainr"]
                            : m.filter((mod) => mod !== "maintainr"),
                        )
                      }
                    />
                  </div>
                </div>
                <div className=" mt-4 flex space-x-4 ">
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Campr</label>
                    <Switch
                      disabled={!isEditing}
                      checked={modules.includes("campr")}
                      onCheckedChange={(checked) =>
                        setModules((m) =>
                          checked
                            ? [...m, "campr"]
                            : m.filter((mod) => mod !== "campr"),
                        )
                      }
                    />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">
                      {t("guest-portal")}
                    </label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Stockr</label>
                    <Switch disabled />
                  </div>
                </div>
                <div className=" mt-4 flex space-x-4 ">
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Meetr</label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">POS</label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">MV CRM</label>
                    <Switch disabled />
                  </div>
                </div>
                <div className=" mt-4 flex space-x-4 ">
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Eatr</label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">
                      MV Personal
                    </label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">
                      {t("channel-manager")}
                    </label>
                    <Switch disabled />
                  </div>
                </div>
                <div className=" mt-4 flex space-x-4 ">
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Relaxr</label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg bg-secondary-card-backplate p-3">
                    <label className=" text-sm font-extrabold">Parkr</label>
                    <Switch disabled />
                  </div>
                  <div className=" flex w-1/3 justify-between rounded-lg  p-3" />
                </div>
              </Card>
            </div>
          )}
          {tab === SITE_TABS.USERS && (
            <div className=" flex space-x-4">
              <Card className=" w-full">
                <UserList siteId={id} />
              </Card>
            </div>
          )}
          {tab === SITE_TABS.ROLES && (
            <div className=" flex space-x-4">
              <Card className=" w-full">
                <div className=" flex items-center justify-between">
                  <h3 className=" text-sm font-bold">{t("roles")}</h3>
                  {isEditing && <Button>{t("add-default-roles")}</Button>}
                </div>
                <RoleList siteId={id} />
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

interface UserListProps {
  siteId?: string;
}

const UserList = ({ siteId }: UserListProps) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [userSearchTerm, setUserSearchTerm] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<boolean[]>([false, true]);
  const navigate = useNavigate();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetUsers.getKey(),
    });
  }, []);

  const { data, isLoading, isRefetching } = useGetUsers({
    variables: {
      siteId,
    },
  });

  const filteredData = useMemo(() => {
    const search = userSearchTerm.trim();
    let filteredData = data;
    if (filteredData) {
      filteredData = filteredData.filter((u) =>
        statusFilter.includes(u.active ?? false),
      );
    }
    if (search && filteredData) {
      return filteredData.filter(
        (u) =>
          u.email?.toLowerCase().includes(search) ||
          `${u.firstName?.toLowerCase() ?? ""} ${u.lastName?.toLowerCase() ?? ""}`.includes(
            search,
          ) ||
          u.phone?.toLowerCase().includes(search),
      );
    } else {
      return filteredData ?? [];
    }
  }, [data, userSearchTerm, statusFilter]);

  const columns: ColumnDef<User>[] = [];

  columns.push({
    cell: (row) => (
      <div className=" flex items-center space-x-2">
        {row.row.original.image?.url ? (
          <img
            aria-description={row.row.original.image?.description ?? undefined}
            src={row.row.original.image?.url}
            className=" min-h-[45px] min-w-[45px] rounded-full object-contain"
          />
        ) : (
          <div className=" flex h-[45px] min-h-[45px]  w-[45px] min-w-[45px] items-center justify-center rounded-full bg-highlighted-backplate">
            <User2 size={18} />
          </div>
        )}
        <div>
          {row.row.original.firstName} {row.row.original.lastName}
        </div>
      </div>
    ),
    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "name",
  });

  columns.push({
    cell: (row) => row.row.original.description,
    accessorKey: "description",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("description")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "description",
  });

  columns.push({
    cell: (row) =>
      row.row.original.employmentStartDate &&
      format(row.row.original.employmentStartDate, "yyyy-MM-dd"),
    accessorKey: "employmentStartDate",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("employment-date")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "employment-date",
  });

  columns.push({
    cell: (row) => row.row.original.email,
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("email")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "email",
  });

  columns.push({
    cell: (row) => row.row.original.phone,
    accessorKey: "phone",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("phone")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "phone",
  });

  columns.push({
    cell: (row) =>
      row.row.original.active ? (
        <div className=" inline rounded-lg bg-status-success-500-10 p-2 text-xs font-extrabold text-status-success">
          {t("active")}
        </div>
      ) : (
        <div className=" inline rounded-lg bg-status-error-500-10 p-2 text-xs font-extrabold text-status-error">
          {t("inactive")}
        </div>
      ),
    accessorKey: "active",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("status")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "status",
  });

  columns.push({
    cell: (row) => (
      <div className=" flex justify-end">
        <Button variant="secondary" size="sm">
          {t("imitate")}
        </Button>
      </div>
    ),
    id: "action",
  });

  const table = useReactTable({
    data: filteredData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <>
      <div className=" flex justify-between">
        <h3 className=" text-sm font-bold">{t("users")}</h3>
        <div className=" flex space-x-4">
          <Popover.Root open={filterOpen} onOpenChange={setFilterOpen}>
            <Popover.Trigger asChild>
              <Button variant="secondary">
                <Filter size={18} className="mr-2" />
                {t("status")}
              </Button>
            </Popover.Trigger>
            <Popover.Content
              className="w-auto min-w-fit p-2 shadow-xl"
              align="end"
            >
              <div className=" flex items-center space-x-2 px-2 py-1">
                <Checkbox
                  checked={statusFilter.includes(true)}
                  onCheckedChange={(v) => {
                    if (v) {
                      setStatusFilter((statusFilter) => [
                        ...statusFilter,
                        true,
                      ]);
                    } else {
                      setStatusFilter((statusFilter) =>
                        statusFilter.filter((f) => !f),
                      );
                    }
                  }}
                  id={"active-filter"}
                />
                <label
                  className=" cursor-pointer text-sm font-medium"
                  htmlFor={"active-filter"}
                >
                  {t("active")}
                </label>
              </div>
              <div className=" flex items-center space-x-2 px-2 py-1">
                <Checkbox
                  checked={statusFilter.includes(false)}
                  onCheckedChange={(v) => {
                    if (v) {
                      setStatusFilter((statusFilter) => [
                        ...statusFilter,
                        false,
                      ]);
                    } else {
                      setStatusFilter((statusFilter) =>
                        statusFilter.filter((f) => f),
                      );
                    }
                  }}
                  id={"inactive-filter"}
                />
                <label
                  className=" cursor-pointer text-sm font-medium"
                  htmlFor={"inactive-filter"}
                >
                  {t("inactive")}
                </label>
              </div>
            </Popover.Content>
          </Popover.Root>
          <Button
            onClick={() =>
              navigate(
                `/admin/${ROUTES.SITES}/${siteId}/${SITE_TABS.USERS}/new`,
              )
            }
          >
            {t("create-new")}
          </Button>
        </div>
      </div>
      <SearchBar
        className="mt-2 max-w-[400px]"
        value={userSearchTerm}
        onChange={(e) => setUserSearchTerm(e.target.value)}
      />
      <div className="p-4">
        <div className="relative mt-4">
          {(isLoading || isRefetching) && (
            <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
              <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
            </div>
          )}

          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    className=" cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/admin/${ROUTES.SITES}/${siteId}/${SITE_TABS.USERS}/${row.id}`,
                      );
                    }}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center text-primary-text"
                  >
                    {t("no-results")}.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

interface RoleListProps {
  siteId?: string;
}

const RoleList = ({ siteId }: RoleListProps) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const navigate = useNavigate();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetSiteRoles.getKey(),
    });
  }, []);

  const { data, isLoading, isRefetching } = useGetSiteRoles({
    variables: {
      siteId: siteId ?? "",
    },
    enabled: !!siteId,
  });

  const columns: ColumnDef<Role>[] = [];

  columns.push({
    cell: (row) => (
      <div className=" flex items-center space-x-4">
        <p>{row.row.original.name}</p>
      </div>
    ),
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center space-x-4"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <p>{t("name")}</p>
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    id: "name",
    size: 50,
    minSize: 20,
    maxSize: 100,
  });

  // write
  if (data) {
    Object.entries(data.permissionTree).forEach(([key, value]) => {
      columns.push({
        cell: (row) => {
          const actions = row.row.original.actions;
          return (
            <div className="flex flex-wrap space-x-2">
              {actions.some((a) => a === "write" || a === `write/${key}`) ? (
                <div className=" flex space-x-2 rounded-lg bg-primary-color-50 p-2">
                  <Webhook size={14} />
                  <p className=" text-xs font-extrabold">{t("all")}</p>
                </div>
              ) : (
                Object.entries(value).map(([key1, value1]) =>
                  actions.some((a) => a.startsWith(`write/${key}/${key1}`)) ? (
                    <>
                      <div className=" flex space-x-2 rounded-lg bg-primary-color-25 p-2">
                        <SquareStack size={14} />
                        <p className=" text-xs font-extrabold">{t(key1)}</p>
                      </div>
                      {Object.entries(value1).map(([key2, value2]) =>
                        actions.some((a) =>
                          a.startsWith(`write/${key}/${key1}/${key2}`),
                        ) ? (
                          <div className=" flex space-x-2 rounded-lg bg-primary-color-10 p-2">
                            <p className=" text-xs font-extrabold">{t(key2)}</p>
                          </div>
                        ) : null,
                      )}
                    </>
                  ) : null,
                )
              )}
            </div>
          );
        },
        accessorKey: key,
        header: ({ column }) => {
          return (
            <div
              className="flex cursor-pointer items-center space-x-4"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <p>{t(key)}</p>
              <CaretSortIcon className="ml-2 h-4 w-4" />
            </div>
          );
        },
        id: `write-${key}`,
      });
    });
  }

  // read
  if (data) {
    Object.entries(data.permissionTree).forEach(([key, value]) => {
      columns.push({
        cell: (row) => {
          const actions = row.row.original.actions;
          return (
            <div className="flex flex-wrap space-x-2">
              {actions.some(
                (a) =>
                  a === "write" ||
                  a === "read" ||
                  a === `write/${key}` ||
                  a === `read/${key}`,
              ) ? (
                <div className=" flex space-x-2 rounded-lg bg-primary-color-50 p-2">
                  <Webhook size={14} />
                  <p className=" text-xs font-extrabold">{t("all")}</p>
                </div>
              ) : (
                Object.entries(value).map(([key1, value1]) =>
                  actions.some(
                    (a) =>
                      a.startsWith(`write/${key}/${key1}`) ||
                      a.startsWith(`read/${key}/${key1}`),
                  ) ? (
                    <>
                      <div className=" flex space-x-2 rounded-lg bg-primary-color-25 p-2">
                        <SquareStack size={14} />
                        <p className=" text-xs font-extrabold">{t(key1)}</p>
                      </div>
                      {Object.entries(value1).map(([key2, value2]) =>
                        actions.some(
                          (a) =>
                            a.startsWith(`write/${key}/${key1}/${key2}`) ||
                            a.startsWith(`read/${key}/${key1}/${key2}`),
                        ) ? (
                          <div className=" flex space-x-2 rounded-lg bg-primary-color-10 p-2">
                            <p className=" text-xs font-extrabold">{t(key2)}</p>
                          </div>
                        ) : null,
                      )}
                    </>
                  ) : null,
                )
              )}
            </div>
          );
        },
        accessorKey: key,
        header: ({ column }) => {
          return (
            <div
              className="flex cursor-pointer items-center space-x-4"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <p>{t(key)}</p>
              <CaretSortIcon className="ml-2 h-4 w-4" />
            </div>
          );
        },
        id: `read-${key}`,
      });
    });
  }

  const table = useReactTable({
    data: data?.roles ?? [],
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <>
      <div className="p-4">
        <div className="relative mt-4">
          {(isLoading || isRefetching) && (
            <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
              <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
            </div>
          )}

          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    className=" cursor-pointer"
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center text-primary-text"
                  >
                    {t("no-results")}.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default SuperadminSitePage;
