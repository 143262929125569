import { Card } from "@primitives/card";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { Space } from "@components/space.tsx";
import { Label } from "@primitives/label.tsx";
import { DateRangePicker } from "@primitives/date-range-picker.tsx";
import { MultiSelect } from "@primitives/multi-select.tsx";
import {
  AvailableAsset,
  GetOOOAvailableAssetsResponse,
  OutOfOrderType,
} from "../../../../../api-contracts/out-of-order";
import { Button } from "@primitives/button.tsx";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { Textarea } from "@primitives/textarea.tsx";
import { AssetOverview } from "../../../../../api-contracts/assets";
import { DateRange } from "react-day-picker";
import { AlertTriangle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetAvailability } from "@api/out-of-order.ts";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { SearchBar } from "@primitives/search-bar.tsx";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label.tsx";
import { debounce } from "lodash";
import { useProfileContext } from "@context/profile-context.tsx";

interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export const CreateOutOfOrderSheet: FC<Props> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("units");
  const [selectedUnits, setSelectedUnits] = useState<AssetOverview[]>([]);
  const [assetsData, setAssetsData] = useState<AssetOverview[]>([]);

  const [includedGroups, setIncludedGroups] = useState<
    { id: string; name: string; assetIds: string[] }[]
  >([]);
  const [includedCategories, setIncludedCategories] = useState<
    { id: string; name: string; assetIds: string[] }[]
  >([]);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date(tomorrow));
  const [reason, setReason] = useState("");
  const [outOfOrderType, setOutOfOrderType] =
    useState<OutOfOrderType>("out-of-order");
  const { postOutOfOrder, assetData } = useContext(OutOfOrderContext);
  const { setModule, module } = useProfileContext();
  const { data: availableAssets } = useGetAvailability({
    variables: {
      from: fromDate.toLocaleDateString("sv"),
      to: toDate.toLocaleDateString("sv"),
    },
  });

  const [assetsGroupsData, setAssetsGroupsData] =
    useState<GetOOOAvailableAssetsResponse>();
  const [showUnavailable, setShowUnavailable] = useState<boolean>(false);
  const [search, setSearch] = useState("");

  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState<boolean>();
  const [isCatDropdownOpen, setIsCatDropdownOpen] = useState<boolean>();

  useEffect(() => {
    setAssetsGroupsData(availableAssets);
  }, [availableAssets]);

  useEffect(() => {
    setAssetsData(assetData);
  }, [assetData]);

  const handleDatesChange = (values: { range: DateRange }) => {
    if (values.range.from && values.range.to) {
      setFromDate(values.range.from);
      setToDate(values.range.to);
    }
  };

  const resetValues = () => {
    setReason("");
    setFromDate(new Date());
    setToDate(new Date());
    setAssetIds([]);
  };

  const executeSearch = useCallback(
    debounce(async () => {
      if (!availableAssets?.assets) return;
      const data = assetsGroupsData?.assets?.filter((u) =>
        u.name?.toLowerCase().includes(search.toLowerCase()),
      );
      if (!assetsGroupsData) return;
      search.length === 0 && availableAssets
        ? setAssetsGroupsData(availableAssets)
        : setAssetsGroupsData((prevData) => ({
            ...prevData,
            assets: Array.isArray(data) ? data : [],
            categories: prevData?.categories ?? [],
            groups: prevData?.groups ?? [],
            locations: prevData?.locations ?? [],
          }));
    }, 200),
    [assetsGroupsData, availableAssets, search],
  );

  useEffect(() => {
    executeSearch();
    return () => executeSearch.cancel();
  }, [search]);

  const handleOptionChange = (value: string) => {
    setSelectedType(value);
    setIsGroupDropdownOpen(value === "groups");
    setIsCatDropdownOpen(value === "category");
  };

  return (
    <DefaultSideSheet
      title={t("create-new-ooo")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className={"flex h-full flex-col justify-between overflow-auto"}>
        <div>
          <Card className={" mt-0 bg-secondary-card-backplate"}>
            <RadioGroup value={selectedType} onValueChange={handleOptionChange}>
              <div>
                <div className={"flex items-center"}>
                  <RadioGroupItem
                    value={"units"}
                    onClick={() => setAssetIds([])}
                  />
                  <Space w={4} />
                  <Label className={"text-sm font-extrabold"}>
                    {t("units")}
                  </Label>
                </div>
                <Space h={12} />
                {selectedType === "units" && assetData.length > 0 && (
                  <>
                    <MultiSelect
                      placeholder={"Enheter"}
                      options={assetsData}
                      value={assetsData.filter((a) =>
                        selectedUnits.some(
                          (selected) => selected.name === a.name,
                        ),
                      )}
                      onChange={(value: AssetOverview[]) => {
                        setSelectedUnits(value);
                        setAssetIds(value.map((a) => a.id));
                      }}
                      getName={(opt) => opt.name}
                    />
                  </>
                )}
              </div>
              {/* Wait with areas for now
                <div>
                <div className={"flex items-center"}>
                  <RadioGroupItem value={"area"} />
                  <Space w={4} />
                  <Label className={"text-sm font-extrabold"}>Område</Label>
                </div>
                <Space h={12} />
                {selectedType === "area" && (
                  <>
                    <MultiSelect
                      placeholder={"Områden"}
                      options={locationsData}
                      value={locationsData.filter((a) =>
                        selectedArea.some(
                          (selected) => selected.name === a.name,
                        ),
                      )}
                      onChange={(value: LocationDTO[]) => {
                        setSelectedArea(value);
                      }}
                      getName={(opt) => opt.name}
                    />
                    <Space h={20} />
                  </>
                )}
              </div>
                */}
              <div>
                <Space h={12} />
                <>
                  <div
                    onClick={(e) => {
                      setSelectedType("groups");
                    }}
                  >
                    <DropdownMenuRoot
                      open={isGroupDropdownOpen}
                      onOpenChange={setIsGroupDropdownOpen}
                    >
                      <div className={"flex items-center"}>
                        <RadioGroupItem
                          value={"groups"}
                          onClick={() => setAssetIds([])}
                        />
                        <Space w={4} />
                        <DropdownMenuTrigger>
                          <Label className={"text-sm font-extrabold"}>
                            {t("grouping")}
                          </Label>
                        </DropdownMenuTrigger>
                      </div>
                      <DropdownMenuContent
                        align={"start"}
                        className="rad z-[400] mr-2 flex max-h-[55vh] min-w-[300px] flex-col items-start justify-start overflow-y-auto rounded-lg px-4"
                      >
                        <Space h={10} />
                        {availableAssets?.groups.map((group) => (
                          <div key={group.id} className={"z-[220]"}>
                            <DropdownMenuSub>
                              <DropdownMenuSubTrigger>
                                <div className=" flex h-[32px] w-[390px] items-center">
                                  <CheckboxWithLabel
                                    checked={
                                      !!includedGroups.find(
                                        (c1) => group.id === c1.id,
                                      )
                                    }
                                    label={t(group.name)}
                                    onCheckedChange={(checked) => {
                                      setShowUnavailable(!showUnavailable);
                                      const updatedGroups = checked
                                        ? [...includedGroups, group]
                                        : includedGroups.filter(
                                            (c1) => c1.id !== group.id,
                                          );
                                      const newAssetIds = updatedGroups
                                        .flatMap((grp) => grp.assetIds)
                                        .filter(
                                          (value, index, self) =>
                                            self.indexOf(value) === index,
                                        );

                                      setAssetIds(newAssetIds);
                                      setIncludedGroups(updatedGroups);
                                    }}
                                  />
                                </div>
                              </DropdownMenuSubTrigger>
                              <DropdownMenuPortal>
                                <DropdownMenuSubContent
                                  sideOffset={20}
                                  className={"w-[300px]"}
                                >
                                  <SearchBar
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder={t("search")}
                                    value={search}
                                    autoFocus
                                  />
                                  <DropdownMenuSeparator />
                                  <div className="mx-2">
                                    {group.assetIds
                                      .map((assetId) =>
                                        assetsGroupsData?.assets.find(
                                          (asset) => asset.id === assetId,
                                        ),
                                      )
                                      .filter((asset) => asset)
                                      .map((asset) => (
                                        <div
                                          key={`${group.id}-${asset?.id}`}
                                          className={
                                            "my-3 flex justify-between"
                                          }
                                        >
                                          <CheckboxWithLabel
                                            checked={
                                              asset?.id
                                                ? assetIds.includes(asset.id)
                                                : false
                                            }
                                            onCheckedChange={(e) => {
                                              if (
                                                assetIds.includes(
                                                  asset?.id ?? "",
                                                )
                                              ) {
                                                setAssetIds(
                                                  assetIds.filter(
                                                    (a) => a !== asset?.id,
                                                  ),
                                                );
                                              } else {
                                                setAssetIds([
                                                  ...assetIds,
                                                  asset?.id ?? "",
                                                ]);
                                              }
                                            }}
                                            label={asset?.name}
                                          />
                                          {asset?.states?.some(
                                            (s) =>
                                              s.state === "booked" ||
                                              s.state === "booked-as-other",
                                          ) && (
                                            <div
                                              className={
                                                "flex items-center justify-between"
                                              }
                                            >
                                              <p
                                                className={
                                                  "text-xs text-status-warning"
                                                }
                                              >
                                                {t("booking")}
                                              </p>
                                              <Space w={15} />
                                              <AlertTriangle
                                                size={17}
                                                className={
                                                  " text-status-warning"
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                  <DropdownMenuSeparator />
                                  <DropdownMenuLabel
                                    onClick={() => {
                                      setAssetIds([]);
                                      setIncludedGroups([]);
                                    }}
                                    className={"cursor-pointer text-center"}
                                  >
                                    {t("remove-filter")}
                                  </DropdownMenuLabel>
                                </DropdownMenuSubContent>
                              </DropdownMenuPortal>
                            </DropdownMenuSub>
                          </div>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenuRoot>
                  </div>
                  <Space h={20} />
                </>
              </div>
              <div>
                <Space h={12} />
                <>
                  <div
                    onClick={(e) => {
                      setSelectedType("category");
                    }}
                  >
                    <DropdownMenuRoot
                      open={isCatDropdownOpen}
                      onOpenChange={setIsCatDropdownOpen}
                    >
                      <div className={"flex items-center"}>
                        <RadioGroupItem
                          value={"category"}
                          onClick={() => setAssetIds([])}
                        />
                        <Space w={4} />
                        <DropdownMenuTrigger>
                          <Label className={"text-sm font-extrabold"}>
                            {t("category")}
                          </Label>
                        </DropdownMenuTrigger>
                      </div>
                      <DropdownMenuContent
                        align={"start"}
                        className="z-[400] mr-2 flex max-h-[55vh] min-w-[300px] flex-col items-start justify-start overflow-y-auto rounded-lg px-4"
                      >
                        <Space h={10} />
                        {availableAssets?.categories.map((cat) => (
                          <div key={cat.id} className={"z-[220]"}>
                            <DropdownMenuSub>
                              <DropdownMenuSubTrigger>
                                <div className=" flex h-[32px] w-[390px] items-center">
                                  <CheckboxWithLabel
                                    checked={
                                      !!includedCategories.find(
                                        (c1) => cat.id === c1.id,
                                      )
                                    }
                                    label={t(cat.name)}
                                    onCheckedChange={(checked) => {
                                      setShowUnavailable(!showUnavailable);
                                      const updatedGroups = checked
                                        ? [...includedCategories, cat]
                                        : includedCategories.filter(
                                            (c1) => c1.id !== cat.id,
                                          );
                                      const newAssetIds = updatedGroups
                                        .flatMap((grp) => grp.assetIds)
                                        .filter(
                                          (value, index, self) =>
                                            self.indexOf(value) === index,
                                        );

                                      setAssetIds(newAssetIds);
                                      setIncludedCategories(updatedGroups);
                                    }}
                                  />
                                </div>
                              </DropdownMenuSubTrigger>
                              <DropdownMenuPortal>
                                <DropdownMenuSubContent
                                  sideOffset={20}
                                  className={"w-[300px]"}
                                >
                                  <SearchBar
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder={t("search")}
                                    value={search}
                                    autoFocus
                                  />
                                  <DropdownMenuSeparator />
                                  <div className="mx-2">
                                    {cat.assetIds
                                      .map((assetId) =>
                                        assetsGroupsData?.assets.find(
                                          (asset) => asset.id === assetId,
                                        ),
                                      )
                                      .filter((asset) => asset)
                                      .map((asset) => (
                                        <div
                                          key={`${cat.id}-${asset?.id}`}
                                          className={
                                            "my-3 flex justify-between"
                                          }
                                        >
                                          <CheckboxWithLabel
                                            checked={
                                              asset?.id
                                                ? assetIds.includes(asset.id)
                                                : false
                                            }
                                            onCheckedChange={(e) => {
                                              if (
                                                assetIds.includes(
                                                  asset?.id ?? "",
                                                )
                                              ) {
                                                setAssetIds(
                                                  assetIds.filter(
                                                    (a) => a !== asset?.id,
                                                  ),
                                                );
                                              } else {
                                                setAssetIds([
                                                  ...assetIds,
                                                  asset?.id ?? "",
                                                ]);
                                              }
                                            }}
                                            label={asset?.name}
                                          />
                                          {asset?.states?.some(
                                            (s) =>
                                              s.state === "booked" ||
                                              s.state === "booked-as-other",
                                          ) && (
                                            <div
                                              className={
                                                "flex items-center justify-between"
                                              }
                                            >
                                              <p
                                                className={
                                                  "text-xs text-status-warning"
                                                }
                                              >
                                                {t("booking")}
                                              </p>
                                              <Space w={15} />
                                              <AlertTriangle
                                                size={17}
                                                className={
                                                  " text-status-warning"
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                  <DropdownMenuSeparator />
                                  <DropdownMenuLabel
                                    onClick={() => {
                                      setAssetIds([]);
                                      setIncludedGroups([]);
                                    }}
                                    className={"cursor-pointer text-center"}
                                  >
                                    {t("remove-filter")}
                                  </DropdownMenuLabel>
                                </DropdownMenuSubContent>
                              </DropdownMenuPortal>
                            </DropdownMenuSub>
                          </div>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenuRoot>
                  </div>
                </>
              </div>
            </RadioGroup>
          </Card>
          <Card
            className={
              " mt-4 max-h-[400px] overflow-auto bg-secondary-card-backplate"
            }
          >
            <div>
              <div className={"mb-3 flex"}>
                <p className={"text-sm font-bold"}>{t("from-to")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <DateRangePicker
                min={2}
                initialDateTo={toDate}
                initialDateFrom={fromDate}
                onUpdate={handleDatesChange}
              />
            </div>
            <div className="mt-2 items-center">
              {assetIds.map((assetId) => {
                const filteredAssets: AvailableAsset[] = (
                  availableAssets?.assets ?? []
                ).filter((a) => a.id === assetId);
                return filteredAssets.map((asset) =>
                  asset.states.length > 0 ? (
                    <div key={asset.id} className="mt-1 flex items-center">
                      <AlertTriangle
                        size={17}
                        className=" text-status-warning"
                      />
                      <Button
                        variant={"link"}
                        className="tex pl-2 text-sm font-light text-status-warning"
                        onClick={() => {
                          setModule("roomr");
                          navigate(
                            `${module}/reservations/${asset.states[0].referenceId}`,
                          );
                        }}
                      >
                        {asset.name} {t("unit-is-booked")}
                      </Button>
                    </div>
                  ) : null,
                );
              })}
            </div>
          </Card>
          <Card className={" mt-4 bg-secondary-card-backplate"}>
            <div>
              <div className={"flex"}>
                <p className={"text-sm font-bold"}>{t("block")}</p>
                <p className={"t pl-1.5 text-status-error"}>*</p>
              </div>
              <Space h={22} />
              <RadioGroup
                className="mb-4"
                value={outOfOrderType}
                onValueChange={(v) => setOutOfOrderType(v as OutOfOrderType)}
              >
                <div className={"flex"}>
                  <div className={"flex items-center"}>
                    <RadioGroupItem value={"out-of-order"} />
                    <Space w={4} />
                    <Label className={"text-sm font-extrabold"}>
                      {t("oof")}
                    </Label>
                  </div>
                  <Space w={85} />
                  <div className={"flex items-center"}>
                    <RadioGroupItem value={"out-of-service"} />
                    <Space w={4} />
                    <Label className={"text-sm font-extrabold"}>
                      {t("ooo")}
                    </Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
          </Card>
          <Card className={" mt-4 bg-secondary-card-backplate"}>
            <div>
              <div className={"flex"}>
                <p className={"text-sm font-bold"}>{t("reason")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Textarea
                placeholder={t("specify-reason")}
                disabled={false}
                value={reason}
                onChange={(v) => setReason(v.target.value)}
              />
            </div>
          </Card>
        </div>
        <div className={"flex justify-end"}>
          <Button
            variant={"outline"}
            onClick={() => {
              resetValues();
              setSelectedUnits([]);
              onOpenChange(false);
            }}
          >
            Avbryt
          </Button>
          <Space w={8} />
          <Button
            disabled={assetIds.length === 0 || reason.length === 0}
            onClick={() => {
              postOutOfOrder({
                assetIds: assetIds,
                startDate: fromDate?.toLocaleDateString("sv"),
                endDate: toDate?.toLocaleDateString("sv"),
                type: outOfOrderType,
                reason: reason,
              }).then();
              resetValues();
              setSelectedUnits([]);
              onOpenChange(false);
            }}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </DefaultSideSheet>
  );
};
