import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  BedAccessory,
  BedTypeAccessory,
} from "../../../../../../api-contracts/bed-capacity";
import { CreateEdit } from "@pages/settings/bed-types/components/standard-bed-sheet.tsx";
import { useTranslation } from "react-i18next";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { useGetBedLinen } from "@api/bed-types.ts";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { MultiSelect } from "@primitives/multi-select.tsx";
import { X } from "lucide-react";
import { Button } from "@primitives/button.tsx";
import { Textarea } from "@primitives/textarea.tsx";
import { useFieldArray, useForm } from "react-hook-form";
import { GenericInputError } from "./generic-input-error";

interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  accessoryData: BedAccessory | undefined;
  type: CreateEdit;
  btnText: string;
}

export const BedAccessorySheet: FC<Props> = ({
  open,
  onOpenChange,
  accessoryData,
  type,
  btnText,
}): ReactElement => {
  const emptyAccessory: BedAccessory = {
    id: "",
    name: "",
    width: 0,
    length: 0,
    height: 0,
    description: "",
    bedlinen: [],
  };

  const { t } = useTranslation();
  const { createAccessory, updateAccessory } = useBedData();
  const { data: bedLinen } = useGetBedLinen();
  const [accessory, setAccessory] = useState<BedAccessory>(
    accessoryData ?? emptyAccessory,
  );
  const [availableLinens, setAvailableLinens] = useState<BedTypeAccessory[]>(
    accessoryData?.bedlinen ?? [],
  );

  useEffect(() => {
    if (accessoryData) {
      setAccessory(accessoryData);
    }
  }, [accessoryData]);

  useEffect(() => {
    if (!bedLinen) return;
    const list = bedLinen.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableLinens(list);
  }, [bedLinen]);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm<BedAccessory>({
    values: accessoryData ?? emptyAccessory,
    shouldUseNativeValidation: true,
  });

  const { fields, remove, replace } = useFieldArray({
    control,
    name: "bedlinen",
    keyName: "id",
    shouldUnregister: true,
  });

  const onSubmit = (data: BedAccessory) => {
    const accessoryToAdd = {
      name: data.name,
      width: data.width,
      length: data.length,
      height: data.height,
      description: data.description,
      bedlinenIds: data.bedlinen.map((acc) => ({
        id: acc.id,
        quantity: acc.quantity,
      })),
    };

    if (type === "create") {
      createAccessory(accessoryToAdd).then(() => setAccessory(emptyAccessory));
    } else {
      updateAccessory(accessoryToAdd, accessory.id).then(() =>
        setAccessory(emptyAccessory),
      );
    }
    onOpenChange(!open);
  };

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-accessory")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex h-full flex-col justify-between overflow-auto"}
      >
        <div>
          <Card className={" mt-0 bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>{t("accessory-information")}</p>
            </div>
            <div className={"mt-3"}>
              <div className={"flex"}>
                <p>{t("name")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Input
                className={
                  "font-neue text-[15px] font-normal text-secondary-text"
                }
                type={"text"}
                required
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name && errors.name.type === "required" && (
                <GenericInputError />
              )}
              <Space h={8} />
              <div className={"flex"}>
                <p>{t("width")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput placeholder={"cm"} {...register("width")} />
              <Space h={8} />
              <div className={" flex"}>
                <p>{t("length")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput placeholder={"cm"} {...register("length")} />
              <Space h={8} />
              <div className={" flex"}>
                <p>{t("height")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput placeholder={"cm"} {...register("height")} />
            </div>
            <Space h={20} />
            <div className={" flex"}>
              <p>{t("description")}</p>
              <p className={"pl-1.5 text-red-700"}>*</p>
            </div>
            <Textarea {...register("description")} />
            {errors.description && errors.description.type === "required" && (
              <GenericInputError />
            )}
          </Card>
          <Space h={18} />
          <Card className={" bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>
                {t("accessory-dropdown-title")}
              </p>
            </div>
            <div className={"mt-3"}>
              {bedLinen && (
                <MultiSelect
                  placeholder={t("accessory-dropdown-title")}
                  options={availableLinens}
                  value={availableLinens.filter((group) =>
                    getValues()?.bedlinen?.some(
                      (selected) => selected.name === group.name,
                    ),
                  )}
                  onChange={(value: BedTypeAccessory[]) => {
                    if (!getValues() || !getValues().bedlinen) {
                      replace(value);
                      setAccessory({
                        ...accessory,
                        bedlinen: value,
                      });
                    } else {
                      const newValues = value.map((v) => {
                        return {
                          ...v,
                          quantity:
                            getValues().bedlinen.find((a) => a.id === v.id)
                              ?.quantity || 1,
                        };
                      });

                      replace(newValues);
                      setAccessory({
                        ...accessory,
                        bedlinen: newValues,
                      });
                    }
                  }}
                  getName={(opt) => opt.name}
                />
              )}
            </div>
            <div>
              <Space h={12} />
              {fields?.map((accessory, index) => {
                return (
                  <div key={accessory.id}>
                    <Card
                      className={
                        "rounded-xl, flex h-[50px] items-center bg-primary-card-backplate"
                      }
                    >
                      <div className={"flex w-full justify-between"}>
                        <div className={"flex items-center"}>
                          <X
                            onClick={() => remove(index)}
                            size={14}
                            className={"cursor-pointer text-primary-icon-color"}
                          />
                          <Space w={12.5} />
                          <p
                            key={accessory.name}
                            className={
                              "h-[12px] font-neue text-xs font-extrabold"
                            }
                          >
                            {accessory.name}
                          </p>
                        </div>
                        <div>
                          <input
                            id={accessory.id}
                            key={accessory.id}
                            type="hidden"
                            {...register(`bedlinen.${index}.id`)}
                          />
                          <input
                            id={accessory.id + "-name"}
                            key={accessory.id + "-name"}
                            type="hidden"
                            {...register(`bedlinen.${index}.name`)}
                          />
                          <Input
                            id={accessory.id + "-quantity"}
                            key={accessory.id + "-quantity"}
                            min={1}
                            type={"number"}
                            {...register(`bedlinen.${index}.quantity`)}
                            className={
                              "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                            }
                          />
                        </div>
                      </div>
                    </Card>
                    <Space h={8} />
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
        <div className="flex justify-end">
          <Button disabled={!isDirty} className={"rounded-xl"} type="submit">
            {btnText}
          </Button>
        </div>
      </form>
    </DefaultSideSheet>
  );
};
