import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { Button } from "@primitives/button.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { MultiSelect } from "@primitives/multi-select.tsx";
import { X } from "lucide-react";

import { useGetBedAccessory } from "@api/bed-types.ts";
import {
  BedTypeAccessory,
  GetBedTypeResponse,
} from "../../../../../../api-contracts/bed-capacity";
import { useTranslation } from "react-i18next";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { GenericInputError } from "./generic-input-error";

export type CreateEdit = "edit" | "create";
interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  bedData: GetBedTypeResponse | undefined;
  type: CreateEdit;
  btnText: string;
}
export const StandardBedSheet: FC<Props> = ({
  open,
  onOpenChange,
  bedData,
  type,
  btnText,
}): ReactElement => {
  const emptyBed: GetBedTypeResponse = {
    bedAccessories: [],
    capacityFullSize: 0,
    capacityHalfSize: 0,
    cot: false,
    extraBed: false,
    id: "",
    internalPrice: 0,
    length: 0,
    locationInventories: [],
    name: "",
    totalExtraBeds: 0,
    width: 0,
  };

  const { t } = useTranslation();
  const { updateBed, createBed } = useBedData();
  const { data: bedAccessory } = useGetBedAccessory();
  const [bed, setBed] = useState<GetBedTypeResponse>(bedData ?? emptyBed);
  const [availableAccessories, setAvailableAccessories] = useState<
    BedTypeAccessory[]
  >([]);
  useEffect(() => {
    if (bedData) {
      setBed(bedData);
    }
  }, [bedData]);

  useMemo(() => {
    if (!bedAccessory) return;
    const list = bedAccessory.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableAccessories(list);
  }, [bedAccessory]);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm<GetBedTypeResponse>({
    values: bedData ?? emptyBed,
    shouldUseNativeValidation: true,
  });

  const { fields, remove, replace } = useFieldArray({
    control,
    name: "bedAccessories",
    keyName: "id",
    shouldUnregister: true,
  });

  const onSubmit: SubmitHandler<GetBedTypeResponse> = async (b) => {
    const bedToAdd = {
      name: b.name,
      width: b.width,
      length: b.length,
      capacityFullSize: b.capacityFullSize,
      capacityHalfSize: b.capacityHalfSize,
      extraBed: false,
      internalPrice: b.internalPrice ? b.internalPrice : 0,
      cot: false,
      bedAccessoryIds: b.bedAccessories.map((acc) => ({
        id: acc.id,
        quantity: acc.quantity,
      })),
      locationInventoryIds: [],
    };

    if (type === "create") {
      createBed(bedToAdd).then(() => setBed(emptyBed));
    } else {
      updateBed(bedToAdd, bed.id).then(() => setBed(emptyBed));
    }
    onOpenChange(!open);
  };

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-standard-bed")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex h-full flex-col justify-between overflow-auto"}
      >
        <div>
          <Card className={" mt-0 bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>{t("bed-information")}</p>
            </div>
            <div className={"mt-3"}>
              <div className={"flex"}>
                <p>{t("name")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Input
                className={
                  "font-neue text-[15px] font-normal text-secondary-text"
                }
                type={"text"}
                {...register("name", {
                  required: true,
                })}
                placeholder={t("name")}
              />
              {errors.name && errors.name.type === "required" && (
                <GenericInputError />
              )}

              <Space h={8} />
              <div className={"flex"}>
                <p>{t("width")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                placeholder={"cm"}
                min={0}
                {...register("width")}
              />
              <Space h={8} />
              <div className={" flex"}>
                <p>{t("length")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                placeholder={"cm"}
                min={0}
                {...register("length")}
              />
            </div>
          </Card>
          <Space h={18} />
          <Card className={" bg-secondary-card-backplate"}>
            <div className={"flex"}>
              <p className={"font-extrabold"}>{t("bed-capacity")}</p>
              <p>({t("number-of-beds")})</p>
            </div>
            <div className={"mt-3"}>
              <div className={"flex"}>
                <p>{t("max-number-of-full-beds")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("max-number-of-full-beds")}
                min={0}
                {...register("capacityFullSize", {
                  required: true,
                })}
              />
              {errors.capacityFullSize &&
                errors.capacityFullSize.type === "required" && (
                  <GenericInputError />
                )}
              <Space h={8} />
              <div className={"flex"}>
                <p>{t("max-number-of-shared-beds")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                defaultValue={t("max-number-of-shared-beds")}
                min={0}
                {...register("capacityHalfSize", {
                  required: true,
                })}
              />
              {errors.capacityHalfSize &&
                errors.capacityHalfSize.type === "required" && (
                  <GenericInputError />
                )}
            </div>
          </Card>
          <Space h={18} />
          <Card className={" bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>
                {t("accessory-dropdown-title")}
              </p>
            </div>
            <div className={"mt-3"}>
              {bedAccessory && (
                <MultiSelect
                  placeholder={t("accessory-dropdown-title")}
                  options={availableAccessories}
                  value={availableAccessories.filter((group) =>
                    getValues()?.bedAccessories?.some(
                      (selected) => selected.name === group.name,
                    ),
                  )}
                  onChange={(value: BedTypeAccessory[]) => {
                    if (!getValues() || !getValues().bedAccessories) {
                      replace(value);
                      setBed({
                        ...bed,
                        bedAccessories: value,
                      });
                    } else {
                      const newValues = value.map((v) => {
                        return {
                          ...v,
                          quantity:
                            getValues().bedAccessories.find(
                              (a) => a.id === v.id,
                            )?.quantity || 1,
                        };
                      });

                      replace(newValues);
                      setBed({
                        ...bed,
                        bedAccessories: newValues,
                      });
                    }
                  }}
                  getName={(opt) => opt.name}
                />
              )}
            </div>
            <div>
              <Space h={12} />
              {fields?.map((accessory, index) => {
                return (
                  <div key={accessory.id}>
                    <Card
                      className={
                        "flex h-[50px] items-center rounded-xl bg-primary-card-backplate"
                      }
                    >
                      <div className={"flex w-full justify-between"}>
                        <div className={"flex items-center"}>
                          <X
                            onClick={() => remove(index)}
                            size={14}
                            className={"cursor-pointer text-primary-icon-color"}
                          />
                          <Space w={12.5} />
                          <p
                            key={accessory.name}
                            className={
                              "h-[12px] font-neue text-xs font-extrabold"
                            }
                          >
                            {accessory.name}
                          </p>
                        </div>
                        <div>
                          <input
                            id={accessory.id}
                            key={accessory.id}
                            type="hidden"
                            {...register(`bedAccessories.${index}.id`)}
                          />
                          <input
                            id={accessory.id + "-name"}
                            key={accessory.id + "-name"}
                            type="hidden"
                            {...register(`bedAccessories.${index}.name`)}
                          />
                          <Input
                            id={accessory.id + "-quantity"}
                            key={accessory.id + "-quantity"}
                            min={1}
                            type={"number"}
                            {...register(`bedAccessories.${index}.quantity`)}
                            className={
                              "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                            }
                          />
                        </div>
                      </div>
                    </Card>
                    <Space h={8} />
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
        <div className="flex justify-end">
          <Button disabled={!isDirty} type="submit" className={"rounded-xl"}>
            {btnText}
          </Button>
        </div>
      </form>
    </DefaultSideSheet>
  );
};
