import { Input } from "@primitives/input";
import React from "react";

export const PlaceholderInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, type, ...props }, ref) => {
  return (
    <div className="relative flex flex-row">
      <Input
        className={
          "font-neue text-[15px] font-normal text-secondary-text" + className
        }
        type={type ? type : "number"} /* defaults to number */
        ref={ref}
        {...props}
      />
      <p className="absolute bottom-3 right-7 text-xs text-secondary-text">
        {props.placeholder}
      </p>
    </div>
  );
});
