import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import { Organization } from "../../../../../api-contracts/customer-list/organization";
import { Dispatch, SetStateAction } from "react";
import { useDeleteOrganization } from "@api/customer-list/organizations";
import { toast } from "@hooks/use-toast";
import { queryClient } from "query-client";

export const ActionBar = ({
  selectedItems,
  tab,
  isMerging,
  setIsMerging,
  clearSelected,
  onEdit,
}: {
  selectedItems: Organization[];
  tab: "organization" | "people";
  isMerging: boolean;
  setIsMerging: Dispatch<SetStateAction<boolean>>;
  clearSelected: () => void;
  onEdit: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: delOrganization } = useDeleteOrganization();

  const selectedItemsLength = selectedItems.length;

  const onMerge = () => {
    setIsMerging(true);
  };

  const handleOnEdit = () => {};
  const onRemove = () => {
    tab === "organization" && deleteOrganization();
  };
  const onCancel = () => {
    isMerging ? setIsMerging(false) : clearSelected();
  };
  const onForgetCustomer = () => {};

  const deleteOrganization = async () => {
    const orgToDelete = selectedItems[0];
    try {
      await delOrganization({ id: orgToDelete.id });
      queryClient.invalidateQueries({
        queryKey: useDeleteOrganization.getKey(),
      });
      clearSelected();
      toast({
        title: t("deleted-succesfully", {
          name: orgToDelete.name ?? "company",
        }),
        variant: "success",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
      });
    }
  };

  return (
    <div className="top-0 flex justify-center rounded-xl bg-solid-backplate p-2 px-2 shadow-lg">
      {selectedItemsLength > 1 ? (
        <Button
          size={"sm"}
          variant={isMerging ? "primary" : "outline"}
          disabled={isMerging}
          className={`disabled:opacity-1 mr-2`}
          onClick={onMerge}
        >
          {t("merge")}
        </Button>
      ) : (
        <>
          <Button size={"sm"} variant={"outline"} onClick={onEdit}>
            {t("edit")}
          </Button>
          <Button
            size={"sm"}
            className="mx-2"
            variant={"danger"}
            onClick={tab === "organization" ? onRemove : onForgetCustomer}
          >
            {tab === "organization" ? t("remove") : t("forget-customer")}
          </Button>
        </>
      )}
      <Button size={"sm"} variant={"outline"} onClick={onCancel}>
        {isMerging ? (
          t("cancel")
        ) : (
          <>
            <X size={12} className="mr-1" />
            {t("marked", { count: selectedItemsLength })}
          </>
        )}
      </Button>
    </div>
  );
};
