import { cloneDeep } from "lodash";
import { Filters } from "@pages/simple-search/search-types";
import {
  ReservationState,
  BookingState,
} from "../../../../api-contracts/reservations";
import { FullSearchResult } from "../../../../api-contracts/search";
import { INITIAL_FILTER } from ".";

export const reservationStateToColor = (
  state?: ReservationState | BookingState,
) => {
  switch (state) {
    case "ongoing":
      return "booking-ongoing";
    case "preliminary":
      return "booking-tentative";
    case "unconfirmed":
      return "booking-unconfirmed";
    case "confirmed":
      return "booking-confirmed";
    case "guaranteed":
      return "booking-guaranteed";
    case "inquiry":
      return "booking-inquiry";
    case "waiting-list":
      return "booking-waiting-list";
    case "lost":
      return "booking-lost";
    case "cancelled":
      return "booking-cancelled";
    case "turn-away":
      return "booking-turn-away";
    case "aborted":
      return "booking-aborted";
    case "planned":
      return "status-warning";
    case "checked-in":
      return "status-success";
    case "checked-out":
      return "status-disabled";
    case "no-show":
      return "status-error";
    default:
      return "status-disabled";
  }
};

export const filterByBookingInformation = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const { reservationId, state, tags } = filters.bookingInformation;

  if (reservationId && result?.reservationId !== reservationId) {
    return false;
  }

  if (state && result?.state !== state) {
    return false;
  }

  if (tags.length > 0) {
    const resultTags = result?.tags || [];
    const allTagsMatch = tags.every((filterTagName) => {
      if (typeof filterTagName !== "string") {
        return false;
      }
      return resultTags.some((resultTagName) => {
        if (typeof resultTagName !== "string") {
          return false;
        }
        return resultTagName.toLowerCase() === filterTagName.toLowerCase();
      });
    });

    if (!allTagsMatch) {
      return false;
    }
  }

  return true;
};

export const filterByGuest = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const guestFilters = filters.guest;

  const hasFilters = Object.values(guestFilters).some((value) =>
    Array.isArray(value) ? value.length > 0 : value !== "",
  );

  if (!hasFilters) {
    return true;
  }

  return result?.guests?.some((guest) => {
    if (
      guestFilters.name &&
      !(guest.name ?? "")
        .toLowerCase()
        .includes(guestFilters.name.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.surname &&
      !(guest?.surname ?? "")
        .toLowerCase()
        .includes(guestFilters.surname.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.phone &&
      !(guest?.phone ?? "").includes(guestFilters.phone)
    ) {
      return false;
    }

    if (
      guestFilters.email &&
      !(guest?.email ?? "")
        .toLowerCase()
        .includes(guestFilters.email.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.address &&
      !(guest?.address ?? "")
        .toLowerCase()
        .includes(guestFilters.address.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.address2 &&
      !(guest?.address2 ?? "")
        .toLowerCase()
        .includes(guestFilters.address2.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.city &&
      !(guest?.city ?? "")
        .toLowerCase()
        .includes(guestFilters.city.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.state &&
      !(guest?.state ?? "")
        .toLowerCase()
        .includes(guestFilters.state.toLowerCase())
    ) {
      return false;
    }

    if (
      guestFilters.zip &&
      !(guest?.zip?.replace(" ", "") ?? "").includes(guestFilters.zip)
    ) {
      return false;
    }

    if (
      guestFilters.phone2 &&
      !(guest?.phone2 ?? "").includes(guestFilters.phone2)
    ) {
      return false;
    }

    if (
      guestFilters.countryCode &&
      !(guest?.countryCode ?? "")
        .toLowerCase()
        .includes(guestFilters.countryCode.toLowerCase())
    ) {
      return false;
    }

    return true;
  });
};

export const filterByHolder = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const holderFilters = filters.holder;

  const hasFilters = Object.values(holderFilters).some((value) =>
    Array.isArray(value) ? value.length > 0 : value !== "",
  );

  if (!hasFilters) {
    return true;
  }

  if (
    holderFilters.name &&
    !(result?.holder?.name ?? "")
      .toLowerCase()
      .includes(holderFilters.name.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.surname &&
    !(result?.holder?.surname ?? "")
      .toLowerCase()
      .includes(holderFilters.surname.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.passportNumber &&
    !(result?.holder?.passportNumber ?? "")
      .toLowerCase()
      .includes(holderFilters.passportNumber.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.address &&
    !(result?.holder?.address ?? "")
      .toLowerCase()
      .includes(holderFilters.address.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.address2 &&
    !(result?.holder?.address2 ?? "")
      .toLowerCase()
      .includes(holderFilters.address2.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.city &&
    !(result?.holder?.city ?? "")
      .toLowerCase()
      .includes(holderFilters.city.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.state &&
    !(result?.holder?.state ?? "")
      .toLowerCase()
      .includes(holderFilters.state.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.zip &&
    !(result?.holder?.zip?.replace(" ", "") ?? "").includes(holderFilters.zip)
  ) {
    return false;
  }

  if (
    holderFilters.countryCode &&
    !(result?.holder?.countryCode ?? "")
      .toLowerCase()
      .includes(holderFilters.countryCode.toLowerCase())
  ) {
    return false;
  }

  if (
    holderFilters.phone &&
    !(result?.holder?.phone ?? "").includes(holderFilters.phone)
  ) {
    return false;
  }

  if (
    holderFilters.phone2 &&
    !(result?.holder?.phone2 ?? "").includes(holderFilters.phone2)
  ) {
    return false;
  }

  if (
    holderFilters.email &&
    !(result?.holder?.email ?? "")
      .toLowerCase()
      .includes(holderFilters.email.toLowerCase())
  ) {
    return false;
  }

  return true;
};

export const filterByOrganization = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const organizationFilters = filters.organization;

  const guestOrganization =
    result.guestOrganization || INITIAL_FILTER.organization;

  if (
    organizationFilters.id &&
    guestOrganization.id !== organizationFilters.id
  ) {
    return false;
  }

  if (
    organizationFilters.name &&
    !(guestOrganization.name ?? "")
      .toLowerCase()
      .includes(organizationFilters.name.toLowerCase())
  ) {
    return false;
  }

  if (
    organizationFilters.organizationNumber &&
    guestOrganization.organizationNumber !==
      organizationFilters.organizationNumber
  ) {
    return false;
  }

  if (
    organizationFilters.address &&
    !(guestOrganization.address ?? "")
      .toLowerCase()
      .includes(organizationFilters.address.toLowerCase())
  ) {
    return false;
  }

  if (
    organizationFilters.address2 &&
    !(guestOrganization.address2 ?? "")
      .toLowerCase()
      .includes(organizationFilters.address2.toLowerCase())
  ) {
    return false;
  }

  if (
    organizationFilters.city &&
    !(guestOrganization.city ?? "")
      .toLowerCase()
      .includes(organizationFilters.city.toLowerCase())
  ) {
    return false;
  }

  if (
    organizationFilters.state &&
    !(guestOrganization.state ?? "")
      .toLowerCase()
      .includes(organizationFilters.state.toLowerCase())
  ) {
    return false;
  }

  if (
    organizationFilters.zip &&
    !(guestOrganization.zip?.replace(" ", "") ?? "").includes(
      organizationFilters.zip,
    )
  ) {
    return false;
  }

  if (
    organizationFilters.countryCode &&
    !(guestOrganization.countryCode ?? "")
      .toLowerCase()
      .includes(organizationFilters.countryCode.toLowerCase())
  ) {
    return false;
  }

  if (
    organizationFilters.email &&
    !(guestOrganization.email ?? "")
      .toLowerCase()
      .includes(organizationFilters.email.toLowerCase())
  ) {
    return false;
  }

  return true;
};

export const filterByTravelAgency = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const travelAgencyFilters = filters.travelAgency;

  const travelAgency = result.travelAgency || INITIAL_FILTER.travelAgency;

  if (travelAgencyFilters.id && travelAgency.id !== travelAgencyFilters.id) {
    return false;
  }

  if (
    travelAgencyFilters.name &&
    !(travelAgency.name ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.name.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.organizationNumber &&
    travelAgency.organizationNumber !== travelAgencyFilters.organizationNumber
  ) {
    return false;
  }

  if (
    travelAgencyFilters.IATA &&
    travelAgency.IATA !== travelAgencyFilters.IATA
  ) {
    return false;
  }

  if (
    travelAgencyFilters.address &&
    !(travelAgency.address ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.address.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.address2 &&
    !(travelAgency.address2 ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.address2.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.city &&
    !(travelAgency.city ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.city.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.state &&
    !(travelAgency.state ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.state.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.zip &&
    !(travelAgency.zip?.replace(" ", "") ?? "").includes(
      travelAgencyFilters.zip,
    )
  ) {
    return false;
  }

  if (
    travelAgencyFilters.countryCode &&
    !(travelAgency.countryCode ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.countryCode.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.email &&
    !(travelAgency.email ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.email.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceReference &&
    !(travelAgency.invoiceReference ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceReference.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceName &&
    !(travelAgency.invoiceName ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceName.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceAddress &&
    !(travelAgency.invoiceAddress ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceAddress.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceAddress2 &&
    !(travelAgency.invoiceAddress2 ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceAddress2.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceZip &&
    !(travelAgency.invoiceZip?.replace(" ", "") ?? "").includes(
      travelAgencyFilters.invoiceZip,
    )
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceCity &&
    !(travelAgency.invoiceCity ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceCity.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceState &&
    !(travelAgency.invoiceState ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceState.toLowerCase())
  ) {
    return false;
  }

  if (
    travelAgencyFilters.invoiceCountry &&
    !(travelAgency.invoiceCountry ?? "")
      .toLowerCase()
      .includes(travelAgencyFilters.invoiceCountry.toLowerCase())
  ) {
    return false;
  }

  return true;
};

export const filterByUnitCategory = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const unitCategoryFilters = filters.unitCategory;

  if (
    unitCategoryFilters.category?.id &&
    result?.category?.id !== unitCategoryFilters.category.id
  ) {
    return false;
  }

  if (
    unitCategoryFilters.bookable?.id &&
    (!result?.bookable ||
      result.bookable.id !== unitCategoryFilters.bookable.id)
  ) {
    return false;
  }

  return true;
};

const isDateInRange = (date: string, from?: string, to?: string): boolean => {
  const targetDate = new Date(date);

  if (from && targetDate < new Date(from)) {
    return false;
  }

  if (to && targetDate > new Date(to)) {
    return false;
  }

  return true;
};

export const filterByDate = (
  result: FullSearchResult,
  filters: Filters,
): boolean => {
  const dateFilters = filters.date;

  if (dateFilters.arrivalDate.from || dateFilters.arrivalDate.to) {
    if (
      !isDateInRange(
        result?.arrivalDate,
        dateFilters.arrivalDate.from,
        dateFilters.arrivalDate.to,
      )
    ) {
      return false;
    }
  }

  if (dateFilters.departureDate.from || dateFilters.departureDate.to) {
    if (
      !isDateInRange(
        result?.departureDate,
        dateFilters.departureDate.from,
        dateFilters.departureDate.to,
      )
    ) {
      return false;
    }
  }

  if (dateFilters.updatedDate.from || dateFilters.updatedDate.to) {
    const hasMatchingUpdatedAt = result?.updatedAt?.some((date) =>
      isDateInRange(
        date,
        dateFilters.updatedDate.from,
        dateFilters.updatedDate.to,
      ),
    );

    if (!hasMatchingUpdatedAt) {
      return false;
    }
  }

  return true;
};

export const areFiltersApplied = (
  sectionFilters: Record<string, any> | null | undefined,
): boolean => {
  if (!sectionFilters || typeof sectionFilters !== "object") {
    return false;
  }

  const isValueApplied = (value: any): boolean => {
    if (value === null || value === undefined) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.some((v) => isValueApplied(v));
    }

    if (typeof value === "object") {
      return Object.values(value).some((nestedValue) =>
        isValueApplied(nestedValue),
      );
    }

    return value !== "";
  };

  return Object.values(sectionFilters).some((value) => isValueApplied(value));
};

export const buildQueryParamsFromFilters = (
  filters: Filters,
): URLSearchParams => {
  const params = new URLSearchParams();

  const addParams = (prefix: string, obj: Record<string, any>) => {
    Object.entries(obj).forEach(([key, value]) => {
      const paramKey = prefix ? `${prefix}.${key}` : key;

      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (typeof value[0] === "object" && value[0] !== null) {
            const serializedArray = value.map((item) => item.name || item.id);
            params.set(paramKey, serializedArray.join(","));
          } else {
            params.set(paramKey, value.join(","));
          }
        }
      } else if (typeof value === "object" && value !== null) {
        addParams(paramKey, value);
      } else if (value !== "" && value !== null && value !== undefined) {
        params.set(paramKey, value);
      }
    });
  };

  addParams("", filters);

  return params;
};

export const parseFiltersFromQueryParams = (
  queryParams: URLSearchParams,
): Filters => {
  const parsedFilters = cloneDeep(INITIAL_FILTER);

  const setNestedValue = (
    obj: Record<string, any>,
    path: string[],
    value: string,
  ) => {
    const [key, ...rest] = path;
    if (!rest.length) {
      obj[key] = Array.isArray(obj[key]) && value ? value.split(",") : value;
      return;
    }
    if (!obj[key]) obj[key] = {};
    setNestedValue(obj[key], rest, value);
  };

  queryParams.forEach((value, key) => {
    if (key === "searchTerm") return;
    const path = key.split(".");
    setNestedValue(parsedFilters, path, value);
  });

  return parsedFilters;
};
