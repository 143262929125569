import { useCreatePriceRule, useGetPriceRules } from "@api/price-rules";
import { TabNavbar } from "@components/tab-navbar";
import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { Collapsible } from "@primitives/collapsible";
import { Loading } from "@primitives/loading";
import { ROUTES } from "@shared/types/navigation";
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ChevronsDownUp,
  ChevronsUpDown,
  Ellipsis,
  Filter,
} from "lucide-react";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PriceRuleDrawer from "./components/price-rule-drawer";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import PriceRuleToggleActiveDialog from "./components/price-rule-toggle-active-dialog";
import {
  GetPriceRule,
  GetPriceRuleCategory,
} from "../../../../../api-contracts/price-rules";
import { queryClient } from "query-client";
import { useToast } from "@hooks/use-toast";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";
import { Popover } from "@primitives/popover";
import { Checkbox } from "@primitives/checkbox";
import { SearchBar } from "@primitives/search-bar";
import { cn } from "@shared/utils/css";
import { CategoryType } from "../../../../../api-contracts/categories";
import { ClusterTabNavBar } from "@components/cluster-tab-nav-bar.tsx";

interface Props {
  tab: CategoryType[];
}

const PriceRulesPage = ({ tab }: Props) => {
  const [t, i18n] = useTranslation();
  const { module, activeCluster } = useProfileContext();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [priceRuleOpen, setPriceRuleOpen] = useState<boolean>(false);
  const [duplicateLoading, setDuplicateLoading] = useState<boolean>(false);
  const [toggleActivePriceRule, setToggleActivePriceRule] =
    useState<GetPriceRule>();
  const [ruleToEdit, setRuleToEdit] = useState<GetPriceRule>();
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [statusFilterOpen, setStatusFilterOpen] = useState<boolean>(false);
  const [categoriesFilterOpen, setCategoriesFilterOpen] =
    useState<boolean>(false);
  const [searchCategoriesFilter, setSearchCategoriesFilter] =
    useState<string>("");
  const [visibleCategories, setVisibleCategories] = useState<
    GetPriceRuleCategory[]
  >([]);
  const [priceRulesFilterOpen, setPriceRulesFilterOpen] =
    useState<boolean>(false);
  const [searchPriceRulesFilter, setSearchPriceRulesFilter] =
    useState<string>("");
  const [visibleRules, setVisibleRules] = useState<GetPriceRule[]>([]);
  const [activeFilter, setActiveFilter] = useState<boolean[]>([true]);

  const { toast } = useToast();

  const createPriceRule = useCreatePriceRule();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetPriceRules.getKey(),
    });
  }, []);

  const {
    data: priceRules,
    isLoading: priceRulesLoading,
    isRefetching: priceRulesRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: tab,
    },
  });

  useEffect(() => {
    setVisibleCategories(priceRules?.categories ?? []);
    setVisibleRules(priceRules?.rules ?? []);
  }, [priceRules]);

  const filterCategoriesOptions = useMemo(() => {
    const search = searchCategoriesFilter.trim().toLowerCase();
    if (search) {
      return (
        priceRules?.categories
          .filter(
            (c) =>
              c.name.toLowerCase().includes(search) ||
              c.short.toLowerCase().includes(search),
          )
          .sort((c1, c2) => c1.name.localeCompare(c2.short)) ?? []
      );
    } else {
      return (
        priceRules?.categories.sort((c1, c2) =>
          c1.name.localeCompare(c2.short),
        ) ?? []
      );
    }
  }, [priceRules, searchCategoriesFilter]);

  const filterPriceRulesOptions = useMemo(() => {
    const search = searchPriceRulesFilter.trim().toLowerCase();
    if (search) {
      return (
        priceRules?.rules
          .filter(
            (r) =>
              r.name.toLowerCase().includes(search) &&
              activeFilter.includes(r.active),
          )
          .sort((r1, r2) => r1.name.localeCompare(r2.name)) ?? []
      );
    } else {
      return (
        priceRules?.rules
          .filter((r) => activeFilter.includes(r.active))
          .sort((r1, r2) => r1.name.localeCompare(r2.name)) ?? []
      );
    }
  }, [priceRules, searchPriceRulesFilter, activeFilter]);

  const rows = useMemo(() => {
    return (
      priceRules?.categories
        .sort((c1, c2) => c1.short.localeCompare(c2.short))
        .filter((c) => visibleCategories.some((f) => f.id === c.id))
        .map((c) => {
          const rules = priceRules.rules
            .filter(
              (r) =>
                visibleRules.some((f) => f.id === r.id) &&
                activeFilter.includes(r.active),
            )
            .map((r) => ({
              ...r,
              entry: r.entries.find((e) => e.categoryId === c.id) ?? {
                categoryId: c.id,
                price: null,
                overrides: null,
              },
            }));
          const overrideKeys = [
            ...new Set(
              priceRules.rules
                .filter(
                  (r) =>
                    visibleRules.some((f) => f.id === r.id) &&
                    activeFilter.includes(r.active),
                )
                .map((r) => [
                  ...(r.entries
                    .filter((e) =>
                      visibleCategories.some((f) => f.id === e.categoryId),
                    )
                    .find((e) => e.categoryId === c.id)
                    ?.overrides.map((o) => o.overrideKey) ?? []),
                ])
                .flat(),
            ),
          ];
          overrideKeys.sort(
            (a, b) =>
              priceRuleOverrideKeyToGuestCounts(a).total -
              priceRuleOverrideKeyToGuestCounts(b).total,
          );

          const overrides = overrideKeys.map((overrideKey) => ({
            overrideKey,
            rules: priceRules.rules
              .filter(
                (r) =>
                  visibleRules.some((f) => f.id === r.id) &&
                  activeFilter.includes(r.active),
              )
              .map((r) => ({
                ...r,
                entry: r.entries
                  .filter((e) =>
                    visibleCategories.some((f) => f.id === e.categoryId),
                  )
                  .find((e) => e.categoryId === c.id) ?? {
                  categoryId: c.id,
                  price: null,
                  overrides: null,
                },
                overridePrice: r.entries
                  .find((e) => e.categoryId === c.id)
                  ?.overrides.find((o) => o.overrideKey === overrideKey)?.price,
              })),
          }));

          return { ...c, rules, overrides };
        }) ?? []
    );
  }, [priceRules, visibleCategories, visibleRules, activeFilter]);

  const handleDuplicatePriceRule = async (rule: GetPriceRule) => {
    setDuplicateLoading(true);
    try {
      await createPriceRule.mutateAsync({
        name: rule.name,
        entries: rule.entries,
        categoryTypes: rule.categoryTypes,
        inactivationDate: rule.inactivationDate,
        reactivationDate: rule.reactivationDate,
      });
      queryClient.invalidateQueries({
        queryKey: useGetPriceRules.getKey(),
      });
      toast({
        variant: "success",
        title: t("saved-succesfully", { name: rule.name }),
        className: "text-status-success",
      });
    } catch (err) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
      });
    }
    setDuplicateLoading(false);
  };

  return (
    <>
      <div className="flex h-full flex-col p-4 text-primary-text">
        <ClusterTabNavBar path={ROUTES.PRICE_RULES} tabsAsLinks={true} />

        <div className=" mt-4 flex-grow rounded-lg border border-main-border-color bg-primary-card-backplate">
          <div className="flex items-center justify-between border-highlighted-backplate p-4">
            <h1 className=" text-2xl font-bold">
              {t("price-rules-bar-title")}
            </h1>
            <Button
              variant="primary"
              onClick={() => {
                setRuleToEdit(undefined);
                setPriceRuleOpen(true);
              }}
            >
              {t("create-new")}
            </Button>
          </div>

          <div className="flex justify-end px-4">
            <Popover.Root open={filterOpen} onOpenChange={setFilterOpen}>
              <Popover.Trigger asChild>
                <Button variant="secondary">
                  <Filter size={18} className="mr-2" />
                  {t("show")}
                </Button>
              </Popover.Trigger>
              <Popover.Content
                className="w-auto min-w-[250px] p-0 shadow-xl"
                align="end"
              >
                <Popover.Root
                  open={statusFilterOpen}
                  onOpenChange={setStatusFilterOpen}
                >
                  <Popover.Trigger asChild>
                    <div
                      className={cn(
                        " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                        {
                          "bg-status-disabled-100": statusFilterOpen,
                        },
                      )}
                    >
                      <p className=" text-sm font-extrabold">{t("status")}</p>
                      <ChevronRight size={16} />
                    </div>
                  </Popover.Trigger>
                  <Popover.Content
                    className="w-auto min-w-[250px] p-0 shadow-xl"
                    align="start"
                    side="left"
                  >
                    <div className=" flex max-h-[200px] flex-col overflow-y-auto pt-2">
                      <>
                        <div className=" flex items-center space-x-2 px-2 py-1">
                          <Checkbox
                            checked={activeFilter.includes(true)}
                            onCheckedChange={(v) => {
                              if (v) {
                                setActiveFilter((filter) => [...filter, true]);
                              } else {
                                setActiveFilter((filter) =>
                                  filter.filter((f) => !f),
                                );
                              }
                            }}
                            id="active-checkbox"
                          />
                          <label
                            className=" cursor-pointer text-sm font-medium"
                            htmlFor="active-checkbox"
                          >
                            {t("active")}
                          </label>
                        </div>
                        <div className=" flex items-center space-x-2 px-2 py-1">
                          <Checkbox
                            checked={activeFilter.includes(false)}
                            onCheckedChange={(v) => {
                              if (v) {
                                setActiveFilter((filter) => [...filter, false]);
                              } else {
                                setActiveFilter((filter) =>
                                  filter.filter((f) => f),
                                );
                              }
                            }}
                            id="active-checkbox"
                          />
                          <label
                            className=" cursor-pointer text-sm font-medium"
                            htmlFor="active-checkbox"
                          >
                            {t("inactive")}
                          </label>
                        </div>
                      </>
                    </div>
                    <div
                      className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                      onClick={() => setActiveFilter([true, false])}
                    >
                      <p className="text-sm font-extrabold">
                        {t("select-all")}
                      </p>
                    </div>
                  </Popover.Content>
                </Popover.Root>
                <Popover.Root
                  open={categoriesFilterOpen}
                  onOpenChange={setCategoriesFilterOpen}
                >
                  <Popover.Trigger asChild>
                    <div
                      className={cn(
                        " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                        {
                          "bg-status-disabled-100": categoriesFilterOpen,
                        },
                      )}
                    >
                      <p className=" text-sm font-extrabold">
                        {t("categories")}
                      </p>
                      <ChevronRight size={16} />
                    </div>
                  </Popover.Trigger>
                  <Popover.Content
                    className="w-auto min-w-[250px] p-0 shadow-xl"
                    align="start"
                    side="left"
                  >
                    <SearchBar
                      className=" mb-1"
                      placeholder={t("search-categories")}
                      value={searchCategoriesFilter}
                      onChange={(e) =>
                        setSearchCategoriesFilter(e.target.value)
                      }
                    />
                    <div className=" flex max-h-[200px] flex-col overflow-y-auto">
                      <>
                        {filterCategoriesOptions?.length === 0 && (
                          <div className="flex items-center justify-center p-3 text-sm text-secondary-text">
                            <p>{t("no-categories-found")}</p>
                          </div>
                        )}
                        {filterCategoriesOptions?.map((g) => (
                          <div
                            key={g.id}
                            className=" flex items-center space-x-2 px-2 py-1"
                          >
                            <Checkbox
                              checked={
                                !!visibleCategories.find((g1) => g.id === g1.id)
                              }
                              onCheckedChange={(v) => {
                                if (v) {
                                  setVisibleCategories([
                                    ...visibleCategories,
                                    g,
                                  ]);
                                } else {
                                  setVisibleCategories(
                                    visibleCategories.filter(
                                      (g1) => g1.id !== g.id,
                                    ),
                                  );
                                }
                              }}
                              id={g.id}
                            />
                            <label
                              className=" cursor-pointer text-sm font-medium"
                              htmlFor={g.id}
                            >
                              {g.name}
                            </label>
                          </div>
                        ))}
                      </>
                    </div>
                    <div
                      className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                      onClick={() =>
                        filterCategoriesOptions &&
                        setVisibleCategories([...filterCategoriesOptions])
                      }
                    >
                      <p className="text-sm font-extrabold">
                        {t("select-all")}
                      </p>
                    </div>
                  </Popover.Content>
                </Popover.Root>
                <Popover.Root
                  open={priceRulesFilterOpen}
                  onOpenChange={setPriceRulesFilterOpen}
                >
                  <Popover.Trigger asChild>
                    <div
                      className={cn(
                        " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                        {
                          "bg-status-disabled-100": priceRulesFilterOpen,
                        },
                      )}
                    >
                      <p className=" text-sm font-extrabold">
                        {t("price-rules-title")}
                      </p>
                      <ChevronRight size={16} />
                    </div>
                  </Popover.Trigger>
                  <Popover.Content
                    className="w-auto min-w-[250px] p-0 shadow-xl"
                    align="start"
                    side="left"
                  >
                    <SearchBar
                      className=" mb-1"
                      placeholder={t("search-price-rules")}
                      value={searchPriceRulesFilter}
                      onChange={(e) =>
                        setSearchPriceRulesFilter(e.target.value)
                      }
                    />
                    <div className=" flex max-h-[200px] flex-col overflow-y-auto">
                      <>
                        {filterPriceRulesOptions?.length === 0 && (
                          <div className="flex items-center justify-center p-3 text-sm text-secondary-text">
                            <p>{t("no-price-rules-found")}</p>
                          </div>
                        )}
                        {filterPriceRulesOptions?.map((g) => (
                          <div
                            key={g.id}
                            className=" flex items-center space-x-2 px-2 py-1"
                          >
                            <Checkbox
                              checked={
                                !!visibleRules.find((g1) => g.id === g1.id)
                              }
                              onCheckedChange={(v) => {
                                if (v) {
                                  setVisibleRules([...visibleRules, g]);
                                } else {
                                  setVisibleRules(
                                    visibleRules.filter((g1) => g1.id !== g.id),
                                  );
                                }
                              }}
                              id={g.id}
                            />
                            <label
                              className=" cursor-pointer text-sm font-medium"
                              htmlFor={g.id}
                            >
                              {g.name}
                            </label>
                          </div>
                        ))}
                      </>
                    </div>
                    <div
                      className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                      onClick={() =>
                        filterPriceRulesOptions &&
                        setVisibleRules([...filterPriceRulesOptions])
                      }
                    >
                      <p className="text-sm font-extrabold">
                        {t("select-all")}
                      </p>
                    </div>
                  </Popover.Content>
                </Popover.Root>
                <div
                  className="flex cursor-pointer items-center justify-center space-x-2 p-3"
                  onClick={() => {
                    setVisibleRules(priceRules?.rules ?? []);
                    setVisibleCategories(priceRules?.categories ?? []);
                    setActiveFilter([true]);
                  }}
                >
                  <p className="text-sm font-extrabold">{t("reset")}</p>
                </div>
              </Popover.Content>
            </Popover.Root>
          </div>

          {priceRulesLoading ||
            duplicateLoading ||
            (priceRulesRefetching && (
              <div className=" relative h-[600px] w-full">
                <Loading />
              </div>
            ))}
          {priceRules && priceRules.categories.length > 0 ? (
            <div className=" flex flex-nowrap">
              <div className=" relative flex-grow-0 pt-4 ">
                <div>
                  <div className=" flex h-[50px] w-full flex-nowrap border-b-[0.5px] border-highlighted-backplate">
                    <div
                      className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-r-[0.5px] border-t-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-secondary-text"
                      onClick={() => {
                        if (expandedIds.length === rows.length) {
                          setExpandedIds([]);
                        } else {
                          setExpandedIds(rows.map((r) => r.id) ?? []);
                        }
                      }}
                    >
                      {t("category")}
                      {expandedIds.length === rows.length ? (
                        <ChevronsDownUp size={16} />
                      ) : (
                        <ChevronsUpDown size={16} />
                      )}
                    </div>
                    <div className=" flex h-full min-w-[170px] max-w-[170px] items-center border-r-[0.5px] border-t-[0.5px] border-highlighted-backplate bg-screen-background px-2 text-sm font-normal text-secondary-text">
                      {t("minimum-rate")}
                    </div>
                  </div>
                  {rows.map((row) => (
                    <React.Fragment key={row.id}>
                      <div className=" flex h-[50px] w-full flex-nowrap  border-highlighted-backplate">
                        {row.overrides.length ? (
                          <div
                            className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text"
                            onClick={() => {
                              if (expandedIds.includes(row.id)) {
                                setExpandedIds((expandedIds) =>
                                  expandedIds.filter((id) => id !== row.id),
                                );
                              } else {
                                setExpandedIds((expandedIds) => [
                                  ...expandedIds,
                                  row.id,
                                ]);
                              }
                            }}
                          >
                            {row.short}
                            {expandedIds.includes(row.id) ? (
                              <ChevronUp size={18} />
                            ) : (
                              <ChevronDown size={18} />
                            )}
                          </div>
                        ) : (
                          <div className=" flex h-full min-w-[170px] max-w-[170px] items-center justify-between border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text">
                            {row.short}
                          </div>
                        )}
                        <div className=" flex h-full min-w-[170px] max-w-[170px] items-center justify-end space-x-2 border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate bg-screen-background px-2 text-sm font-normal text-primary-text">
                          <p>
                            {new Intl.NumberFormat(
                              i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                              {
                                maximumFractionDigits: 2,
                              },
                            ).format(row.minimumRate)}{" "}
                          </p>
                          <span className="text-xs text-secondary-text">
                            SEK
                          </span>
                        </div>
                      </div>
                      <Collapsible.Root open={expandedIds.includes(row.id)}>
                        <Collapsible.Content>
                          {row.overrides.map((override) => {
                            const guestCounts =
                              priceRuleOverrideKeyToGuestCounts(
                                override.overrideKey,
                              );
                            return (
                              <div
                                key={override.overrideKey}
                                className=" flex h-[50px] w-full flex-nowrap border-highlighted-backplate"
                              >
                                <div className=" flex h-full min-w-[170px] max-w-[170px] items-center space-x-2  border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate  px-2 text-sm font-normal text-primary-text">
                                  {`${guestCounts.adults ? `${guestCounts.adults}(${t("adults-short")}) ` : ""}${guestCounts.teenagers ? `${guestCounts.teenagers}(${t("teenagers-short")}) ` : ""}${guestCounts.children ? `${guestCounts.children}(${t("children-short")}) ` : ""}${guestCounts.infants ? `${guestCounts.infants}(${t("infants-short")})` : ""}`}
                                </div>
                                <div className=" flex h-full min-w-[170px] max-w-[170px] items-center space-x-2 border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate bg-screen-background px-2 text-sm font-normal text-primary-text" />
                              </div>
                            );
                          })}
                        </Collapsible.Content>
                      </Collapsible.Root>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className=" relative w-full flex-grow overflow-x-auto pt-4">
                <div>
                  <div className=" flex h-[50px] w-full flex-nowrap border-highlighted-backplate">
                    {priceRules?.rules
                      .filter(
                        (r) =>
                          visibleRules.some((f) => r.id === f.id) &&
                          activeFilter.includes(r.active),
                      )
                      .map((rule) => (
                        <div
                          key={rule.id}
                          className=" flex h-full min-w-[170px] max-w-[170px]  cursor-pointer  items-center  justify-between border-b-[0.5px] border-r-[0.5px] border-t-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-secondary-text"
                          onClick={() => {}}
                        >
                          <Tooltip>
                            <TooltipTrigger>
                              <p className=" flex-grow truncate">{rule.name}</p>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{rule.name}</p>
                            </TooltipContent>
                          </Tooltip>
                          <DropdownMenuRoot>
                            <DropdownMenuTrigger>
                              <div className=" flex justify-end">
                                <Ellipsis size={16} />
                              </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRuleToEdit(rule);
                                  setPriceRuleOpen(true);
                                }}
                              >
                                {t("edit")}
                              </DropdownMenuItem>

                              <DropdownMenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDuplicatePriceRule(rule);
                                }}
                              >
                                {t("duplicate")}
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setToggleActivePriceRule(rule);
                                }}
                              >
                                {rule.active ? t("inactivate") : t("activate")}
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenuRoot>
                        </div>
                      ))}
                  </div>
                  {rows.map((row) => (
                    <React.Fragment key={row.id}>
                      <div className=" flex h-[50px] w-full flex-nowrap  border-highlighted-backplate">
                        {row.rules.map((rule) => (
                          <div
                            key={rule.id}
                            className=" flex h-full min-w-[170px] max-w-[170px] items-center justify-end space-x-2 border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text"
                          >
                            {rule.entries.find((e) => e.categoryId === row.id)
                              ?.overrides.length ? (
                              <div className="w-[10px]">
                                <div className=" mb-1 h-2 w-2 rounded-full bg-primary-color" />
                              </div>
                            ) : null}
                            <p>
                              {new Intl.NumberFormat(
                                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                                {
                                  maximumFractionDigits: 2,
                                },
                              ).format(rule.entry.price ?? 0)}
                            </p>
                            <span className="text-xs text-secondary-text">
                              SEK
                            </span>
                          </div>
                        ))}
                      </div>
                      <Collapsible.Root open={expandedIds.includes(row.id)}>
                        <Collapsible.Content className=" overflow-visible">
                          {row.overrides.map((override) => {
                            const guestCounts =
                              priceRuleOverrideKeyToGuestCounts(
                                override.overrideKey,
                              );
                            return (
                              <div
                                key={override.overrideKey}
                                className=" flex h-[50px] w-full flex-nowrap border-highlighted-backplate"
                              >
                                {override.rules.map((rule) => (
                                  <div
                                    key={rule.id}
                                    className=" flex h-full min-w-[170px] max-w-[170px] items-center justify-end space-x-2 border-b-[0.5px] border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text"
                                  >
                                    {rule.overridePrice !== undefined ? (
                                      <>
                                        <p>
                                          {new Intl.NumberFormat(
                                            i18n.language === "sv-se"
                                              ? "sv-SE"
                                              : "en-GB",
                                            {
                                              maximumFractionDigits: 2,
                                            },
                                          ).format(rule.overridePrice ?? 0)}
                                        </p>
                                        <span className="text-xs text-secondary-text">
                                          SEK
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            );
                          })}
                        </Collapsible.Content>
                      </Collapsible.Root>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={cn(
                "flex h-[600px] w-full items-center justify-center p-2",
              )}
            >
              <p className={cn(" text-sm font-normal text-secondary-text")}>
                {t("no-categories-found")}
              </p>
            </div>
          )}
        </div>

        <div className=" mt-4 flex justify-end">
          <div>
            <p className=" text-end text-sm font-normal">
              {t("all-prices-including-vat")}
            </p>
            <div className=" flex flex-nowrap items-center space-x-2">
              <div className=" mb-1 h-2 w-2 rounded-full bg-primary-color" />
              <p className=" text-sm font-normal">
                {t("price-per-guest-combination")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <PriceRuleDrawer
        tab={tab}
        open={priceRuleOpen}
        ruleToEdit={ruleToEdit}
        onOpenChange={(open) => {
          setPriceRuleOpen(open);
          setRuleToEdit(undefined);
        }}
      />

      {toggleActivePriceRule && (
        <PriceRuleToggleActiveDialog
          tab={tab}
          open={!!toggleActivePriceRule}
          onOpenChange={(open) => {
            setToggleActivePriceRule(open ? toggleActivePriceRule : undefined);
          }}
          rule={toggleActivePriceRule}
        />
      )}
    </>
  );
};

export default PriceRulesPage;
