import { createMutation, createQuery } from "react-query-kit";
import {
  CheckOrganizationResponse,
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  GetOrganizationRequestParams,
  GetOrganizationResponse,
  MergeOrganizationRequest,
  MergeOrganizationResponse,
  PatchOrganizationRequest,
  PatchOrganizationResponse,
  SearchOrganizationQueryParams,
  SearchOrganizationResponse,
} from "../../../../../api-contracts/customer-list/organization";
import { api } from "@api/api";
import { currentSite } from "@context/site-context";

export const CUSTOMER_ORGANIZATIONS_QUERY_KEY = "customer-list/organization";

/** GET /:siteId/customer-list/organization/:id */
export const useGetOrganizationById = createQuery<
  GetOrganizationResponse,
  GetOrganizationRequestParams
>({
  queryFn: async ({ queryKey: [, { id, siteId }] }) =>
    api.get(
      `/api/${siteId || currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}/${id}`,
    ),
  primaryKey: CUSTOMER_ORGANIZATIONS_QUERY_KEY,
});

/** GET /:siteId/customer-list/organization?pattern=:string&limit=:number&page=:string */
export const useSearchOrganizations = createQuery<
  SearchOrganizationResponse,
  SearchOrganizationQueryParams
>({
  queryFn: async ({ queryKey: [, params] }) => {
    const { pattern, limit, page } = params;

    const queryParams = new URLSearchParams({
      ...(pattern && { pattern }), // Add pattern only if it's defined
      limit: limit.toString(),
      page: page.toString(),
    });

    return api.get(
      `/api/${currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}?${queryParams.toString()}`,
    );
  },
  primaryKey: `${CUSTOMER_ORGANIZATIONS_QUERY_KEY}`,
});

/** POST /:siteId/customer-list/organization */
export const useCreateOrganization = createMutation<
  CreateOrganizationResponse,
  CreateOrganizationRequest
>({
  mutationFn: async (newOrganization) => {
    return api.post(
      `/api/${currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}`,
      newOrganization,
    );
  },
});

/** GET /:siteId/customer-list/organization/check/:orgNumber */
export const useCheckOrganization = createQuery<
  CheckOrganizationResponse,
  { orgNumber: string }
>({
  queryFn: async ({ queryKey: [, { orgNumber }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}/check/${orgNumber}`,
    );
  },
  primaryKey: `${CUSTOMER_ORGANIZATIONS_QUERY_KEY}/check`,
});

/** PATCH /:siteId/customer-list/organization/:id */
export const useUpdateOrganization = createMutation<
  PatchOrganizationResponse,
  { id: string; updates: PatchOrganizationRequest }
>({
  mutationFn: async ({ id, updates }) => {
    return api.patch(
      `/api/${currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}/${id}`,
      updates,
    );
  },
});

/** POST /:siteId/customer-list/organization/:id/merge */
export const useMergeOrganizations = createMutation<
  MergeOrganizationResponse,
  { id: string; mergeData: MergeOrganizationRequest }
>({
  mutationFn: async ({ id, mergeData }) => {
    return api.post(
      `/api/${currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}/${id}/merge`,
      mergeData,
    );
  },
});

/** DELETE /:siteId/customer-list/organization/:id */
export const useDeleteOrganization = createMutation<{}, { id: string }>({
  mutationFn: async ({ id }) =>
    api.delete(
      `/api/${currentSite?.id}/${CUSTOMER_ORGANIZATIONS_QUERY_KEY}/${id}`,
    ),
});
