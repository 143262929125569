import { useTranslation } from "react-i18next";
import { StepIndicator } from "./step-indicator";
import { useState } from "react";
import { CompanyDataStep } from "./company-data-step";
import { Button } from "@primitives/button";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { AddressDataStep } from "./address-data-step";
import { ReviewStep } from "./review-step";
import {
  MergeOrganizationRequest,
  Organization,
} from "../../../../../../api-contracts/customer-list/organization";
import { useMergeOrganizations } from "@api/customer-list/organizations";
import { toast } from "@hooks/use-toast";
import { queryClient } from "query-client";

export type columnHeaderDef = {
  key: keyof Organization;
  label: string;
};

export const MergeSteps = ({
  customers,
  clearSelected,
  cancelMerge,
  typeOfCustomer,
}: {
  customers: Organization[];
  clearSelected: () => void;
  cancelMerge: () => void;
  typeOfCustomer: "organization" | "people";
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [companyData, setCompanyData] = useState<
    Partial<
      Record<
        keyof Organization,
        { value: string; rowId: string; source: "table" | "input" }
      >
    >
  >({
    id: { value: "", rowId: "", source: "table" },
    name: { value: "", rowId: "", source: "table" },
    organizationNumber: { value: "", rowId: "", source: "table" },
    IATA: { value: "", rowId: "", source: "table" },
    phone: { value: "", rowId: "", source: "table" },
    phone2: { value: "", rowId: "", source: "table" },
    email: { value: "", rowId: "", source: "table" },
    type: { value: "organization", rowId: "", source: "table" },
  });
  const [addressData, setAddressData] = useState<Partial<Organization>>({
    address: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    countryCode: "",
  });

  const [invoiceAddressData, setInvoiceAddressData] = useState<
    Partial<Organization>
  >({
    invoiceAddress: "",
    invoiceAddress2: "",
    invoiceCity: "",
    invoiceZip: "",
    invoiceState: "",
    invoiceCountry: "",
  });

  const [selectedAddressIndex, setSelectedAddressIndex] = useState<
    number | null
  >(null);
  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState<
    number | null
  >(null);

  const { mutateAsync: mergeOrganization } = useMergeOrganizations();

  const columnHeaders: columnHeaderDef[] = [
    { key: "id", label: t("customer-number") },
    { key: "name", label: t("company-name") },
    { key: "organizationNumber", label: t("organization-number") },
    { key: "IATA", label: t("iata-number") },
    { key: "phone", label: t("phone") },
    { key: "phone2", label: `${t("phone")} 2` },
    { key: "email", label: t("email") },
  ];

  const steps = [
    { label: t("company-data") },
    { label: t("address-data") },
    { label: t("review") },
  ];

  const isLastStep = steps.length - 1 === currentStep;

  const nextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };
  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const getValueOrNull = (value: any) => {
    return value === undefined || value === "" ? null : value;
  };

  const handleMerge = async () => {
    if (!companyData.id?.value) return;
    const oldIds = customers
      .map((cust) => cust.id)
      .filter((id) => id !== companyData.id?.value);

    const mergePayload: MergeOrganizationRequest = {
      oldIds: oldIds,
      name: getValueOrNull(companyData.name?.value),
      organizationNumber: getValueOrNull(companyData.organizationNumber?.value),
      IATA: getValueOrNull(companyData.IATA?.value),
      phone: getValueOrNull(companyData.phone?.value),
      phone2: getValueOrNull(companyData.phone2?.value),
      email: getValueOrNull(companyData.email?.value),
      address: getValueOrNull(addressData.address),
      address2: getValueOrNull(addressData.address2),
      city: getValueOrNull(addressData.city),
      countryCode: getValueOrNull(addressData.countryCode),
      state: getValueOrNull(addressData.state),
      zip: getValueOrNull(addressData.zip),
      invoiceAddress: getValueOrNull(invoiceAddressData.invoiceAddress),
      invoiceAddress2: getValueOrNull(invoiceAddressData.invoiceAddress2),
      invoiceCity: getValueOrNull(invoiceAddressData.invoiceCity),
      invoiceCountry: getValueOrNull(invoiceAddressData.invoiceCountry),
      invoiceName: getValueOrNull(invoiceAddressData.invoiceName),
      invoiceState: getValueOrNull(invoiceAddressData.invoiceState),
      invoiceZip: getValueOrNull(invoiceAddressData.invoiceZip),
      invoiceReference: getValueOrNull(invoiceAddressData.invoiceReference),
    };

    try {
      await mergeOrganization({
        mergeData: mergePayload,
        id: companyData.id.value,
      });
      queryClient.invalidateQueries({
        queryKey: useMergeOrganizations.getKey(),
      });
      cancelMerge();
      clearSelected();
      toast({
        title: t("new-customer-succesfully-after-merge"),
        variant: "success",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
      });
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="px-4">
        <StepIndicator steps={steps} currentStep={currentStep} />
      </div>
      <div className="w-full border-b border-highlighted-backplate pt-4" />
      <div className="flex-grow p-4">
        {currentStep === 0 && (
          <CompanyDataStep
            columnHeaders={columnHeaders}
            customers={customers}
            companyData={companyData}
            setCompanyData={setCompanyData}
          />
        )}
        {currentStep === 1 && (
          <AddressDataStep
            customers={customers}
            addressData={addressData}
            invoiceData={invoiceAddressData}
            setAddressData={setAddressData}
            setInvoiceAddressData={setInvoiceAddressData}
            selectedAddressIndex={selectedAddressIndex}
            setSelectedAddressIndex={setSelectedAddressIndex}
            selectedInvoiceIndex={selectedInvoiceIndex}
            setSelectedInvoiceIndex={setSelectedInvoiceIndex}
          />
        )}
        {currentStep === 2 && (
          <div className="flex flex-grow items-stretch justify-center">
            <ReviewStep
              typeOfCustomer={typeOfCustomer}
              companyData={companyData}
              addressData={addressData}
              invoiceData={invoiceAddressData}
            />
          </div>
        )}
      </div>

      <div className="w-full border-b border-highlighted-backplate pt-4" />

      <div className="flex justify-end p-4">
        <Button onClick={cancelMerge} variant={"outline"} className="mr-4">
          {t("cancel")}
        </Button>
        {currentStep > 0 && (
          <Button onClick={prevStep} variant={"outline"} className="mr-4">
            <ArrowLeft className="mr-2" width={"13px"} />
            {t("previous")}
          </Button>
        )}
        <Button onClick={isLastStep ? handleMerge : nextStep}>
          {t(isLastStep ? "merge" : "next")}
          {!isLastStep && <ArrowRight className="ml-2" width={"13px"} />}
        </Button>
      </div>
    </div>
  );
};
