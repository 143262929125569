import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { queryClient } from "../../query-client";
import {
  CreateRoleRequest,
  CreateRoleResponse,
  GetAllGrantsResponse,
  GetRolesResponse,
  UpdateRoleRequest,
} from "../../../../api-contracts/roles";
import { currentSite } from "@context/site-context";

export const ORG_ADMIN_ROLES = "/api/organizations/roles";
const BASE_URL = "/api/organizations";
type OrgId = { orgId: string };

export const useGetAllAvailableGrants = createQuery<
  GetAllGrantsResponse,
  OrgId
>({
  primaryKey: ORG_ADMIN_ROLES,
  queryFn: async ({ queryKey: [, variables] }) =>
    api.get(`${BASE_URL}/${variables.orgId}/grants`),
});

export const useOrganizationRolesAdmin = createQuery<GetRolesResponse, OrgId>({
  primaryKey: ORG_ADMIN_ROLES,
  queryFn: async ({ queryKey: [, variables] }) =>
    api.get<GetRolesResponse>(`${BASE_URL}/${variables.orgId}/roles`),
});

export const useCreateOrganizationRole = createMutation<
  CreateRoleResponse,
  OrgId & {
    body: CreateRoleRequest;
  }
>({
  mutationFn: async (variables) =>
    api.post(`${BASE_URL}/${variables.orgId}/roles`, variables.body),
  onSuccess: (_data, variables) =>
    queryClient.invalidateQueries({
      queryKey: [ORG_ADMIN_ROLES, { orgId: variables.orgId }],
    }),
});

export const useUpdateOrganizationRole = createMutation<
  CreateRoleResponse,
  OrgId & {
    body: UpdateRoleRequest;
  }
>({
  mutationFn: async (variables) =>
    api.put(`${BASE_URL}/${variables.orgId}/roles`, variables.body),
  onSuccess: (_data, variables) =>
    queryClient.invalidateQueries({
      queryKey: [ORG_ADMIN_ROLES, { orgId: variables.orgId }],
    }),
});

export const SITES_BASE_URL = "/api";

export const useGetSiteRoles = createQuery<
  GetRolesResponse,
  { siteId?: string }
>({
  primaryKey: SITES_BASE_URL,
  queryFn: async ({ queryKey: [, { siteId = currentSite?.id }] }) =>
    api.get(`${SITES_BASE_URL}/${siteId}/roles`),
});
