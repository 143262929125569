import { InputWithLabel } from "@pages/simple-search/components/filter-side-sheet/input-with-label";
import { Button } from "@primitives/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@primitives/dialog";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dot } from "lucide-react";
import { Organization } from "../../../../../../api-contracts/customer-list/organization";

export const AddAddressDialog = ({
  addressType,
  addressData,
  regularAddressData,
  isSelected,
  onSaveDialog,
  openDialog,
  setOpenDialog,
}: {
  addressType: "address" | "invoice";
  addressData: Partial<Organization>;
  regularAddressData?: Partial<Organization>;
  isSelected: boolean;
  onSaveDialog: (updatedAddress: Partial<Organization>) => void;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [sameAsAddress, setSameAsAddress] = useState<boolean>(false);

  const [tempAddress, setTempAddress] = useState<Partial<Organization>>({
    ...addressData,
  });

  const handleInputChange = (
    field: keyof typeof tempAddress,
    value: string,
  ) => {
    setTempAddress((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    onSaveDialog(tempAddress);
  };

  const getFullAddress = () => {
    const fields =
      addressType === "address"
        ? [
            tempAddress.address,
            tempAddress.address2,
            tempAddress.zip
              ? `${tempAddress.zip} ${tempAddress.city}`
              : tempAddress.city,
            tempAddress.state,
            tempAddress.countryCode,
          ]
        : [
            tempAddress.invoiceAddress,
            tempAddress.invoiceAddress2,
            tempAddress.invoiceZip
              ? `${tempAddress.invoiceZip} ${tempAddress.invoiceCity}`
              : tempAddress.invoiceCity,
            tempAddress.invoiceState,
            tempAddress.invoiceCountry,
          ];

    const formattedFields = fields.filter(Boolean).join(", ").trim();

    return formattedFields || "";
  };

  useEffect(() => {
    setTempAddress(
      isSelected
        ? {
            address: addressData.address || "",
            address2: addressData.address2 || "",
            city: addressData.city || "",
            zip: addressData.zip || "",
            state: addressData.state || "",
            countryCode: addressData.countryCode || "",
            invoiceAddress: addressData.invoiceAddress || "",
            invoiceAddress2: addressData.invoiceAddress2 || "",
            invoiceCity: addressData.invoiceCity || "",
            invoiceZip: addressData.invoiceZip || "",
            invoiceState: addressData.invoiceState || "",
            invoiceCountry: addressData.invoiceCountry || "",
          }
        : {
            address: "",
            address2: "",
            city: "",
            zip: "",
            state: "",
            countryCode: "",
            invoiceAddress: "",
            invoiceAddress2: "",
            invoiceCity: "",
            invoiceZip: "",
            invoiceState: "",
            invoiceCountry: "",
          },
    );
  }, [openDialog, addressData]);

  useEffect(() => {
    if (sameAsAddress) {
      setTempAddress((prev) => ({
        ...prev,
        invoiceAddress: regularAddressData?.address || "",
        invoiceAddress2: regularAddressData?.address2 || "",
        invoiceCity: regularAddressData?.city || "",
        invoiceZip: regularAddressData?.zip || "",
        invoiceState: regularAddressData?.state || "",
        invoiceCountry: regularAddressData?.countryCode || "",
      }));
    }
  }, [sameAsAddress, regularAddressData]);

  return (
    <div
      className={`my-4 flex min-h-[130px] w-full justify-between rounded-xl p-1 pl-2 text-left ${
        isSelected
          ? "bg-amber-500 text-quaternary-text"
          : "bg-secondary-card-backplate"
      } `}
    >
      <div className="flex  h-[120px] w-full flex-col justify-between py-1 pr-2">
        <div className="mb-2 h-full w-full rounded-lg border border-border-color bg-primary-button-backplate p-1 text-primary-text">
          {getFullAddress()}
        </div>
        <div>
          <Dialog open={openDialog} onOpenChange={setOpenDialog}>
            <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
              <Button size={"sm"} variant={"secondary"}>
                {t(isSelected ? "edit" : "add")}
              </Button>
            </DialogTrigger>
            <DialogContent
              className="w-[425px] text-primary-text"
              onClick={(e) => e.stopPropagation()}
            >
              <DialogTitle className="font-itc text-sm">
                {t(addressType === "address" ? "address" : "invoice-address")}
              </DialogTitle>

              <div>
                {addressType === "invoice" && (
                  <>
                    <InputWithLabel label={t("reference")} />
                    <div className="flex py-2">
                      <SwitchWithLabel
                        label={t("same-as-address")}
                        checked={sameAsAddress}
                        onCheckedChange={setSameAsAddress}
                      />
                    </div>
                  </>
                )}

                <InputWithLabel
                  label={`${t("address-line")} 1`}
                  onChange={(e) =>
                    handleInputChange(
                      addressType === "address" ? "address" : "invoiceAddress",
                      e.target.value,
                    )
                  }
                  value={
                    addressType === "address"
                      ? (tempAddress.address ?? "")
                      : (tempAddress.invoiceAddress ?? "")
                  }
                />

                <InputWithLabel
                  label={`${t("address-line")} 2`}
                  onChange={(e) =>
                    handleInputChange(
                      addressType === "address"
                        ? "address2"
                        : "invoiceAddress2",
                      e.target.value,
                    )
                  }
                  value={
                    addressType === "address"
                      ? (tempAddress.address2 ?? "")
                      : (tempAddress.invoiceAddress2 ?? "")
                  }
                />

                <InputWithLabel
                  label={t("zip-code")}
                  onChange={(e) =>
                    handleInputChange(
                      addressType === "address" ? "zip" : "invoiceZip",
                      e.target.value,
                    )
                  }
                  value={
                    addressType === "address"
                      ? (tempAddress.zip ?? "")
                      : (tempAddress.invoiceZip ?? "")
                  }
                />

                <InputWithLabel
                  label={t("city")}
                  onChange={(e) =>
                    handleInputChange(
                      addressType === "address" ? "city" : "invoiceCity",
                      e.target.value,
                    )
                  }
                  value={
                    addressType === "address"
                      ? (tempAddress.city ?? "")
                      : (tempAddress.invoiceCity ?? "")
                  }
                />

                <InputWithLabel
                  label={t("state-province-region")}
                  onChange={(e) =>
                    handleInputChange(
                      addressType === "address" ? "state" : "invoiceState",
                      e.target.value,
                    )
                  }
                  value={
                    addressType === "address"
                      ? (tempAddress.state ?? "")
                      : (tempAddress.invoiceState ?? "")
                  }
                />

                <InputWithLabel
                  label={t("country")}
                  onChange={(e) =>
                    handleInputChange(
                      addressType === "address"
                        ? "countryCode"
                        : "invoiceCountry",
                      e.target.value,
                    )
                  }
                  value={
                    addressType === "address"
                      ? (tempAddress.countryCode ?? "")
                      : (tempAddress.invoiceCountry ?? "")
                  }
                />
              </div>
              <DialogFooter>
                <Button
                  onClick={() => setOpenDialog(false)}
                  variant={"outline"}
                >
                  {t("cancel")}
                </Button>
                <Button onClick={handleSave}>{t("save")}</Button>
              </DialogFooter>
            </DialogContent>
            <DialogDescription />
          </Dialog>
        </div>
      </div>
      <div className="flex h-[32px] w-[32px] items-center justify-center rounded-lg bg-highlighted-backplate">
        {isSelected && <Dot className="text-quaternary-text" />}
      </div>
    </div>
  );
};
