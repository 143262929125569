import { useState } from "react";
import { TableContainer } from "./components/table-container";
import { CreateOrganizationSheet } from "./components/create-organization-sheet";
import { CreateCustomerSheet } from "./components/create-customer-sheet";
import { GuestBase } from "../../../../api-contracts/guests";
import {
  CreateOrganizationRequest,
  Organization,
} from "../../../../api-contracts/customer-list/organization";

export type MarketingConsent = {
  newsletter: boolean;
  sms: boolean;
  email: boolean;
};
const CustomerListPage = ({ tab }: { tab: "organization" | "people" }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);
  const [openOrganizationSheet, setOpenOrganizationSheet] =
    useState<boolean>(false);
  const [openCustomerSheet, setOpenCustomerSheet] = useState<boolean>(false);

  const [marketingConsents, setMarketingConsents] = useState<MarketingConsent>({
    newsletter: false,
    sms: false,
    email: false,
  });
  const [newPeople, setNewPeople] = useState<GuestBase>({
    id: "",
    name: "",
    surname: "",
    passportNumber: null,
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    countryCode: "",
    phone: "",
    phone2: "",
    email: "",
    invoiceReference: null,
    invoiceName: null,
    invoiceAddress: null,
    invoiceAddress2: null,
    invoiceZip: null,
    invoiceCity: null,
    invoiceState: null,
    invoiceCountry: null,
    types: ["guest", "booker"],
  });
  const [tempOrganization, setTempOrganization] =
    useState<CreateOrganizationRequest>({
      type: "organization",
      organizationNumber: "",
      IATA: null,
      name: "",
      email: "",
      phone: "",
      phone2: "",
      address: "",
      address2: "",
      zip: "",
      city: "",
      state: "",
      countryCode: "",
      invoiceReference: null,
      invoiceName: null,
      invoiceAddress: null,
      invoiceAddress2: null,
      invoiceZip: null,
      invoiceCity: null,
      invoiceState: null,
      invoiceCountry: null,
    });

  const isEditMode = selectedOrganizations.length === 1;

  const handleOpenOrganizationSheet = (organization?: Organization) => {
    if (organization) {
      setTempOrganization({ ...organization });
    } else {
      setTempOrganization({
        type: "organization",
        organizationNumber: "",
        IATA: null,
        name: "",
        email: "",
        phone: "",
        phone2: "",
        address: "",
        address2: "",
        zip: "",
        city: "",
        state: "",
        countryCode: "",
        invoiceReference: null,
        invoiceName: null,
        invoiceAddress: null,
        invoiceAddress2: null,
        invoiceZip: null,
        invoiceCity: null,
        invoiceState: null,
        invoiceCountry: null,
      });
    }
    setOpenOrganizationSheet(true);
  };

  const handleOrganizationInputChange = (
    field: keyof CreateOrganizationRequest,
    value: string | null,
  ) => {
    setTempOrganization((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePeopleInputChange = (field: keyof GuestBase, value: string) => {
    setNewPeople((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-full flex-grow">
        <TableContainer
          tab={tab}
          openOrganizationSheet={handleOpenOrganizationSheet}
          openCustomerSheet={setOpenCustomerSheet}
          selectedOrganizations={selectedOrganizations}
          setSelectedOrganizations={setSelectedOrganizations}
        />
      </div>
      <CreateOrganizationSheet
        open={openOrganizationSheet}
        onOpenChange={setOpenOrganizationSheet}
        handleInputChange={handleOrganizationInputChange}
        organization={tempOrganization}
        isEditMode={isEditMode}
        clearSelected={() => setSelectedOrganizations([])}
      />
      <CreateCustomerSheet
        marketingConsents={marketingConsents}
        setMarketingConsents={setMarketingConsents}
        open={openCustomerSheet}
        onOpenChange={setOpenCustomerSheet}
        handleInputChange={handlePeopleInputChange}
        people={newPeople}
      />
    </div>
  );
};

export default CustomerListPage;
