import { Button } from "@primitives/button";
import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { MessageOfDay } from "../../components/message-of-day";
import { useReceptionContext } from "../../reception-context";
import { DatePicker } from "../../components/date-picker";
import { useAllocateAllReservations } from "@api/reservations";
import { AllocateBookingsRequest } from "../../../../../../api-contracts/reservations/allocation";
import { format, isToday } from "date-fns";
import { ConfirmDialog } from "@components/confirm-dialog";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "@hooks/use-toast";
import { enGB, sv } from "date-fns/locale";
import { X } from "lucide-react";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { WalkInSideSheet } from "@pages/reception/components/walk-in-side-sheet";
import {
  useGetArrivalsByDate,
  usePostMultipleCheckInByBookingId,
} from "@api/reception";
import { queryClient } from "query-client";

export const ArrivalsCardHeader = () => {
  const { t, i18n } = useTranslation();
  const { arrivals, setDate, date, selectedBookings, setSelectedBookings } =
    useReceptionContext();
  const [allocateDialogOpen, setAllocateOpen] = useState<boolean>(false);
  const [allHaveBookableDialogOpen, setAllHaveBookableDialogOpen] =
    useState<boolean>(false);
  const [walkInOpen, setWalkInOpen] = useState<boolean>(false);

  const arrivalsCount = arrivals.length;
  const locale = i18n.language === "sv-se" ? sv : enGB;

  const { mutateAsync: allocateAll } = useAllocateAllReservations();

  const hasArrivalsToday = arrivals.some((arrival) =>
    isToday(new Date(arrival.arrivalDate)),
  );
  const assignAllReservations = async () => {
    try {
      let initialArrival = new Date(arrivals[0].arrivalDate);
      let initialDeparture = new Date(arrivals[0].departureDate);

      const { start, end, canAllocate, allHaveBookable } = arrivals.reduce(
        (acc, curr) => {
          const arrivalDate = new Date(curr.arrivalDate);
          const departureDate = new Date(curr.departureDate);

          if (arrivalDate < acc.start) {
            acc.start = arrivalDate;
          }

          if (departureDate > acc.end) {
            acc.end = departureDate;
          }

          if (curr.lockedBookable) {
            acc.canAllocate = false;
          }

          if (!curr.bookable?.id) {
            acc.allHaveBookable = false;
          }

          return acc;
        },
        {
          start: initialArrival,
          end: initialDeparture,
          canAllocate: true,
          allHaveBookable: true,
        },
      );

      if (allHaveBookable) {
        setAllHaveBookableDialogOpen(true);
        return;
      }

      const requestData: AllocateBookingsRequest = {
        start: format(start, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      };

      await allocateAll(requestData);
      toast({
        title: t("units-assigned"),
        description: t(
          "all-reservations-with-arrival-today-is-now-assigned-units",
        ),
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
        className: "text-status-error",
      });
    }
  };

  return (
    <>
      <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
        <div className="flex flex-col">
          <CardTitle>{t("arrivals")}</CardTitle>
          <p className="text-xs font-normal text-secondary-text">
            {arrivalsCount === 1
              ? t("{{count}}-bookable-unit", { count: arrivalsCount })
              : t("{{count}}-bookable-units", { count: arrivalsCount })}
          </p>
        </div>
        <div className="flex h-16 flex-grow items-center justify-center text-center">
          {selectedBookings.length > 0 ? (
            <SelectedActionBar
              selectedBookings={selectedBookings}
              setSelectedBookings={setSelectedBookings}
            />
          ) : (
            <>
              <div>
                <DatePicker date={date} setDate={setDate} />
              </div>
              <div className="mx-2">
                <MessageOfDay />
              </div>
              <div>
                <Button
                  disabled={!hasArrivalsToday}
                  onClick={() => setAllocateOpen(true)}
                  variant={"secondary"}
                >
                  {t("assign-all")}
                </Button>
              </div>
            </>
          )}
        </div>
        <div>
          <Button
            onClick={() => setWalkInOpen((prev) => !prev)}
            variant={"secondary"}
          >
            {t("walk-in")}
          </Button>
        </div>
      </div>
      <WalkInSideSheet open={walkInOpen} onOpenChange={setWalkInOpen} />
      <ConfirmDialog
        isOpen={allocateDialogOpen}
        onOpenChange={setAllocateOpen}
        title={t("assign-units")}
        description={t(
          "are-you-sure-that-you-would-like-to-assign-rooms-for-all-reservations-with-arrival-today-{{date}}",
          { date: format(new Date(), "d MMMM", { locale }) },
        )}
        onProceed={assignAllReservations}
        proceedBtnText={t("assign-all")}
      />

      <ConfirmDialog
        isOpen={allHaveBookableDialogOpen}
        onOpenChange={setAllHaveBookableDialogOpen}
        title={t("all-units-assigned")}
        description={t(
          "all-reservations-with-arrival-today-is-already-assigned",
        )}
        onProceed={() => setAllHaveBookableDialogOpen(false)}
        proceedBtnText={t("ok")}
      />
    </>
  );
};

const SelectedActionBar = ({
  selectedBookings,
  setSelectedBookings,
}: {
  selectedBookings: ReceptionBooking[];
  setSelectedBookings: Dispatch<SetStateAction<ReceptionBooking[]>>;
}) => {
  const { t } = useTranslation();
  const selectedBookingsLength = selectedBookings.length;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { mutateAsync: checkInMultiple } = usePostMultipleCheckInByBookingId();

  const handleCheckIn = () => {
    setIsOpen((prev) => !prev);
  };

  const onCheckIn = async () => {
    try {
      const bookingIds = selectedBookings.map((b) => b.bookingId);
      await checkInMultiple({ bookingIds });
      setSelectedBookings([]);
      queryClient.invalidateQueries({
        queryKey: useGetArrivalsByDate.getKey(),
      });
      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  return (
    <div className="top-0 flex justify-center rounded-xl bg-solid-backplate p-2 px-4 shadow-lg">
      <Button size={"sm"} variant={"outline"} onClick={handleCheckIn}>
        {t("check-in")}
      </Button>
      <Button
        size={"sm"}
        onClick={() => setSelectedBookings([])}
        className="ml-4"
      >
        <X size={12} className="mr-1" />
        {t("marked", { count: selectedBookingsLength })}
      </Button>
      <ConfirmDialog
        isOpen={isOpen}
        onProceed={onCheckIn}
        onOpenChange={setIsOpen}
        proceedBtnText={t("check-in")}
        title={t("check-in")}
        description={t("are-you-sure-you-want-to-check-in-selected")}
      />
    </div>
  );
};
