import { createContext, FC, ReactNode, useEffect, useState } from "react";
import {
  useCheckAvailability,
  useDeletetOutOfOrder,
  useGetOutOfOrders,
  useEndOOO,
  usePostOOO,
  usePatchOOO,
} from "@api/out-of-order.ts";
import {
  CheckOOOAvailabilityRequest,
  CheckOOOAvailabilityResponse,
  CreateOutOfOrderRequest,
  GetOutOfOrdersResponse,
  OutOfOrder,
  PatchOutOfOrderRequest,
} from "../../../../api-contracts/out-of-order";
import { queryClient } from "../../query-client.ts";
import { AssetOverview } from "../../../../api-contracts/assets";
import { useGetAssets } from "@api/assets.ts";
import { useGetLocations } from "@api/locations.ts";
import { LocationDTO } from "../../../../api-contracts/locations";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import { useGetCategories } from "@api/categories.ts";
import { useGetAssetGroups } from "@api/groups.ts";
import { AssetGroup } from "../../../../api-contracts/groups";

type OutOfOrderContextValue = {
  outOfOrdersData: GetOutOfOrdersResponse;
  deleteOOO: (id: string) => Promise<void>;
  postOutOfOrder: (id: CreateOutOfOrderRequest) => Promise<void>;
  endOutOfOrder: (ooo: OutOfOrder) => Promise<void>;
  patchOutOfOrder: (ooo: PatchOutOfOrderRequest) => Promise<void>;
  assetData: AssetOverview[];
  assetGroupsData: AssetGroup[];
  locationData: LocationDTO[];
  checkAvailability: (
    ooo: CheckOOOAvailabilityRequest,
  ) => Promise<CheckOOOAvailabilityResponse>;
  setOutOfOrders: (outOfOrdersData: GetOutOfOrdersResponse) => void;
  searchTerm: string;
  setSearch: (value: string) => void;
  filteredData: GetOutOfOrdersResponse;
  setFilter: (outOfOrdersData: GetOutOfOrdersResponse) => void;
};

interface OutOfOrderContextProps {
  children: ReactNode;
}

export const OutOfOrderContext = createContext<OutOfOrderContextValue>(
  {} as OutOfOrderContextValue,
);

export const OutOfOrderContextProvider: FC<OutOfOrderContextProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { data: outOfOrders } = useGetOutOfOrders({});
  const { mutateAsync: deleteOutOfOrder } = useDeletetOutOfOrder();
  const { mutateAsync: postOOO } = usePostOOO();
  const { mutateAsync: checkAvail } = useCheckAvailability();
  const { mutateAsync: endOOO } = useEndOOO();
  const { mutateAsync: patchOOO } = usePatchOOO();
  const [outOfOrdersData, setOutOfOrdersData] =
    useState<GetOutOfOrdersResponse>({ outOfOrders: [] });
  const { data: assets } = useGetAssets({
    variables: {},
  });
  const { data: locations } = useGetLocations({});
  const { data: assetGroups } = useGetAssetGroups({});

  const [assetData, setAssetData] = useState<AssetOverview[]>([]);
  const [assetGroupsData, setAssetGroupsData] = useState<AssetGroup[]>([]);
  const [locationData, setLocationData] = useState<LocationDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] =
    useState<GetOutOfOrdersResponse>(outOfOrdersData);

  const setOutOfOrders = (data: GetOutOfOrdersResponse) => {
    setOutOfOrdersData(data);
  };

  const setFilter = (data: GetOutOfOrdersResponse) => {
    setOutOfOrdersData(data);
    setFilteredData(data);
  };

  const setSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (assetGroups) setAssetGroupsData(assetGroups);
  }, [assetGroups]);

  useEffect(() => {
    if (assets) setAssetData(assets);
  }, [assets]);

  useEffect(() => {
    if (locations) setLocationData(locations);
  }, [locations]);

  useEffect(() => {
    setOutOfOrders(filteredData);
  }, [outOfOrders]);

  const deleteOOO = async (id: string) => {
    try {
      await deleteOutOfOrder({ id: id });
      await queryClient.invalidateQueries({
        queryKey: useGetOutOfOrders.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const postOutOfOrder = async (ooo: CreateOutOfOrderRequest) => {
    try {
      await postOOO(ooo);
      await queryClient.invalidateQueries({
        queryKey: useGetOutOfOrders.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: t(ooo.type) }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const checkAvailability = async (
    ooo: CheckOOOAvailabilityRequest,
  ): Promise<any> => {
    try {
      const response = await checkAvail(ooo);
      await queryClient.invalidateQueries({
        queryKey: useGetOutOfOrders.getKey(),
      });
      return response;
    } catch (error) {}
  };

  const patchOutOfOrder = async (ooo: Partial<PatchOutOfOrderRequest>) => {
    if (!ooo.newAssetId) return;
    try {
      await patchOOO({ id: ooo.newAssetId, patch: ooo });
      await queryClient.invalidateQueries({
        queryKey: useGetOutOfOrders.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: t(ooo.type ?? "") }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const endOutOfOrder = async (ooo: OutOfOrder) => {
    try {
      await endOOO(ooo);
      await queryClient.invalidateQueries({
        queryKey: useGetOutOfOrders.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: ooo.asset.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };
  return (
    <OutOfOrderContext.Provider
      value={{
        outOfOrdersData,
        deleteOOO,
        postOutOfOrder,
        assetData,
        locationData,
        checkAvailability,
        endOutOfOrder,
        setOutOfOrders,
        assetGroupsData,
        searchTerm,
        setSearch,
        setFilter,
        filteredData,
        patchOutOfOrder,
      }}
    >
      {children}
    </OutOfOrderContext.Provider>
  );
};
