import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { Button } from "@primitives/button.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { MultiSelect } from "@primitives/multi-select.tsx";
import { X } from "lucide-react";
import { useGetBedAccessory } from "@api/bed-types.ts";
import {
  BedTypeAccessory,
  GetBedTypeResponse,
} from "../../../../../../api-contracts/bed-capacity";
import { useTranslation } from "react-i18next";
import { useGetLocations } from "@api/locations.ts";
import { Switch } from "@primitives/switch.tsx";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { GenericInputError } from "./generic-input-error";

export type CreateEdit = "edit" | "create";
interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  bedData: GetBedTypeResponse | undefined;
  type: CreateEdit;
  btnText: string;
}
export const ExtraBedSheet: FC<Props> = ({
  open,
  onOpenChange,
  bedData,
  type,
  btnText,
}): ReactElement => {
  const emptyBed: GetBedTypeResponse = {
    bedAccessories: [],
    capacityFullSize: 0,
    capacityHalfSize: 0,
    cot: false,
    extraBed: false,
    id: "",
    internalPrice: 0,
    length: 0,
    locationInventories: [],
    name: "",
    totalExtraBeds: 0,
    width: 0,
  };

  const { t } = useTranslation();
  const { updateBed, createBed } = useBedData();
  const { data: bedAccessory } = useGetBedAccessory();
  const { data: locations } = useGetLocations();
  const [bed, setBed] = useState<GetBedTypeResponse>(bedData ?? emptyBed);

  useEffect(() => {
    if (bedData) {
      setBed(bedData);
    }
  }, [bedData]);

  const [availableAccessories, setAvailableAccessories] = useState<
    BedTypeAccessory[]
  >(bedData?.bedAccessories ?? []);
  const [availableLocations, setAvailableLocations] = useState<
    { id: string; name: string; quantity: number }[]
  >(bedData?.locationInventories ?? []);

  useMemo(() => {
    if (!bedAccessory) return;
    const list = bedAccessory.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableAccessories(list);
  }, [bedAccessory]);

  useMemo(() => {
    if (!locations) return;
    const list = locations.map((elem) => ({
      id: elem.id,
      name: elem.name,
      quantity: 1,
    }));
    setAvailableLocations(list);
  }, [locations]);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm<GetBedTypeResponse>({
    values: bedData ?? emptyBed,
    shouldUseNativeValidation: true,
  });

  const {
    fields: accessoriesFields,
    remove: accessoriesRemove,
    replace: accessoriesReplace,
  } = useFieldArray({
    control,
    name: "bedAccessories",
    keyName: "id",
    shouldUnregister: true,
  });

  const {
    fields: locationFields,
    remove: locationRemove,
    replace: locationReplace,
  } = useFieldArray({
    control,
    name: "locationInventories",
    keyName: "id",
    shouldUnregister: true,
  });

  const onSubmit: SubmitHandler<GetBedTypeResponse> = async (b) => {
    const newBedAccessories = b.bedAccessories
      ? b.bedAccessories.map((acc) => ({
          id: acc.id,
          quantity: acc.quantity,
        }))
      : [];

    const newLocations = b.locationInventories
      ? b.locationInventories.map((acc) => ({
          id: acc.id,
          quantity: acc.quantity,
        }))
      : [];

    const bedToAdd = {
      name: b.name,
      width: b.width,
      length: b.length,
      capacityFullSize: b.capacityFullSize,
      capacityHalfSize: b.capacityHalfSize,
      extraBed: true,
      internalPrice: b.internalPrice ? b.internalPrice : 0,
      cot: b.cot,
      bedAccessoryIds: newBedAccessories,
      locationInventoryIds: newLocations,
    };

    if (type === "create") {
      createBed(bedToAdd).then(() => setBed(emptyBed));
    } else {
      updateBed(bedToAdd, bed.id).then(() => setBed(emptyBed));
    }
    onOpenChange(!open);
  };

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-extra-bed")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex h-full flex-col justify-between overflow-auto"}
      >
        <div className={"flex flex-col justify-between"}>
          <div className={"flex flex-grow flex-col"}>
            <Card className={" mt-0 bg-secondary-card-backplate"}>
              <div>
                <p className={"font-extrabold"}>{t("bed-information")}</p>
              </div>
              <div className={"mt-3"}>
                <div className={"flex"}>
                  <p>{t("name")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <Input
                  id="name"
                  className={
                    "font-neue text-[15px] font-normal text-secondary-text"
                  }
                  type={"text"}
                  {...register("name", {
                    required: true,
                  })}
                  placeholder={t("name")}
                />
                {errors.name && errors.name.type === "required" && (
                  <GenericInputError />
                )}
                <Space h={8} />
                <div className={"flex"}>
                  <p>{t("width")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <PlaceholderInput
                  id="width"
                  placeholder={"cm"}
                  min={0}
                  {...register("width")}
                />
                <Space h={8} />
                <div className={"flex"}>
                  <p>{t("length")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <PlaceholderInput
                  id={"length"}
                  placeholder={"cm"}
                  min={0}
                  {...register("length")}
                />
                <Space h={8} />
                <div className={"flex"}>
                  <p>{t("internal-cost")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <PlaceholderInput
                  id={"internalPrice"}
                  placeholder={"SEK"}
                  min={0}
                  {...register("internalPrice")}
                />
              </div>
            </Card>
            <Space h={18} />
            <Card className={" bg-secondary-card-backplate"}>
              <div>
                <p className={"font-extrabold"}>{t("number-per-area")}</p>
              </div>
              <div className={"mt-3"}>
                <div className={"mt-3"}>
                  {locations && (
                    <MultiSelect
                      placeholder={t("add-area")}
                      options={availableLocations}
                      value={availableLocations?.filter((group) =>
                        getValues()?.locationInventories?.some(
                          (selected) => selected.name === group.name,
                        ),
                      )}
                      onChange={(value: BedTypeAccessory[]) => {
                        if (!getValues() || !getValues().locationInventories) {
                          locationReplace(value);
                          setBed({
                            ...bed,
                            locationInventories: value,
                          });
                        } else {
                          const newValues = value.map((v) => {
                            return {
                              ...v,
                              quantity:
                                getValues().locationInventories.find(
                                  (a) => a.id === v.id,
                                )?.quantity || 1,
                            };
                          });

                          locationReplace(newValues);
                          setBed({
                            ...bed,
                            locationInventories: newValues,
                          });
                        }
                      }}
                      getName={(opt) => opt.name}
                    />
                  )}
                </div>
                <Space h={12} />
                {locationFields?.map((location, index) => {
                  return (
                    <div key={location.id}>
                      <Card
                        className={
                          "rounded-xl, flex h-[50px] items-center bg-primary-card-backplate"
                        }
                      >
                        <div className={"flex w-full justify-between"}>
                          <div className={"flex items-center"}>
                            <X
                              onClick={() => locationRemove(index)}
                              size={14}
                              className={
                                "cursor-pointer text-primary-icon-color"
                              }
                            />
                            <Space w={12.5} />
                            <p
                              key={location.name}
                              className={
                                "h-[12px] font-neue text-xs font-extrabold"
                              }
                            >
                              {location.name}
                            </p>
                          </div>
                          <div>
                            <input
                              id={location.id}
                              key={location.id}
                              type="hidden"
                              {...register(`locationInventories.${index}.id`)}
                            />
                            <input
                              id={location.id + "-name"}
                              key={location.id + "-name"}
                              type="hidden"
                              {...register(`locationInventories.${index}.name`)}
                            />
                            <Input
                              id={location.id + "-quantity"}
                              key={location.id + "-quantity"}
                              min={1}
                              type={"number"}
                              {...register(
                                `locationInventories.${index}.quantity`,
                              )}
                              className={
                                "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                              }
                            />
                          </div>
                        </div>
                      </Card>
                      <Space h={8} />
                    </div>
                  );
                })}
              </div>
            </Card>
            <Space h={18} />
            <Card className={" bg-secondary-card-backplate"}>
              <div className={"flex"}>
                <p className={"font-extrabold"}>{t("bed-capacity")} </p>
                <Space w={3} />
                <p>({t("number-of-beds")})</p>
              </div>
              <Card className={"flex p-4"}>
                <Controller
                  name="cot"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Switch
                        defaultChecked={value}
                        checked={value}
                        onChange={onChange}
                        onCheckedChange={() =>
                          setBed({
                            ...bed,
                            cot: !bed.cot,
                          })
                        }
                      />
                    );
                  }}
                />
                <p className={"f text- ml-2 font-extrabold"}>{t("cot")}</p>
              </Card>
              {!bed.cot && (
                <div className={"mt-3"}>
                  <div className={"flex"}>
                    <p>{t("max-number-of-full-beds")}</p>
                    <p className={"pl-1.5 text-red-700"}>*</p>
                  </div>
                  <PlaceholderInput
                    id="capacityFullSize"
                    min={0}
                    {...register("capacityFullSize")}
                  />
                  <Space h={8} />
                  <div className={"flex"}>
                    <p>{t("max-number-of-shared-beds")}</p>
                    <p className={"pl-1.5 text-red-700"}>*</p>
                  </div>
                  <PlaceholderInput
                    id="capacityHalfSize"
                    min={0}
                    {...register("capacityHalfSize")}
                  />
                </div>
              )}
            </Card>
            <Space h={18} />
            <Card className={" bg-secondary-card-backplate"}>
              <div>
                <p className={"font-extrabold"}>
                  {t("accessory-dropdown-title")}
                </p>
              </div>
              <div className={"mt-3"}>
                {bedAccessory && (
                  <MultiSelect
                    placeholder={t("accessory-dropdown-title")}
                    options={availableAccessories}
                    value={availableAccessories.filter((group) =>
                      getValues().bedAccessories?.some(
                        (selected) => selected.name === group.name,
                      ),
                    )}
                    onChange={(value: BedTypeAccessory[]) => {
                      if (!getValues() || !getValues().bedAccessories) {
                        accessoriesReplace(value);
                        setBed({
                          ...bed,
                          bedAccessories: value,
                        });
                      } else {
                        const newValues = value.map((v) => {
                          return {
                            ...v,
                            quantity:
                              getValues().bedAccessories.find(
                                (a) => a.id === v.id,
                              )?.quantity || 1,
                          };
                        });

                        accessoriesReplace(newValues);
                        setBed({
                          ...bed,
                          bedAccessories: newValues,
                        });
                      }
                    }}
                    getName={(opt) => opt.name}
                  />
                )}
              </div>
              <div>
                <Space h={12} />
                {accessoriesFields?.map((accessory, index) => {
                  return (
                    <div key={accessory.id}>
                      <Card
                        className={
                          "rounded-xl, flex h-[50px] items-center bg-primary-card-backplate"
                        }
                      >
                        <div className={"flex w-full justify-between"}>
                          <div className={"flex items-center"}>
                            <X
                              onClick={() => accessoriesRemove(index)}
                              size={14}
                              className={
                                "cursor-pointer text-primary-icon-color"
                              }
                            />
                            <Space w={12.5} />
                            <p
                              key={accessory.name}
                              className={
                                "h-[12px] font-neue text-xs font-extrabold"
                              }
                            >
                              {accessory.name}
                            </p>
                          </div>
                          <div>
                            <input
                              id={accessory.id}
                              key={accessory.id}
                              type="hidden"
                              {...register(`bedAccessories.${index}.id`)}
                            />
                            <input
                              id={accessory.id + "-name"}
                              key={accessory.id + "-name"}
                              type="hidden"
                              {...register(`bedAccessories.${index}.name`)}
                            />
                            <Input
                              id={accessory.id + "-quantity"}
                              key={accessory.id + "-quantity"}
                              min={1}
                              type={"number"}
                              {...register(`bedAccessories.${index}.quantity`)}
                              className={
                                "h-[35px] w-[35px] rounded-lg bg-primary-button-backplate p-0 text-center"
                              }
                            />
                          </div>
                        </div>
                      </Card>
                      <Space h={8} />
                    </div>
                  );
                })}
              </div>
            </Card>
          </div>
        </div>
        <Space h={20} />
        <div className="flex justify-end">
          <Button disabled={!isDirty} className={"rounded-xl"} type="submit">
            {btnText}
          </Button>
        </div>
      </form>
    </DefaultSideSheet>
  );
};
