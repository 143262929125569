import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  BlockBookableRequest,
  BlockBookableResponse,
  GetBookablesByCategoryResponse,
  GetBookablesResponse,
  GetCategoryBookabelsResponse,
  PatchBookableRequest,
  PatchBookableResponse,
} from "../../../../api-contracts/bookables";
import {
  AssetScheduleRequest,
  AssetScheduleResponse,
  CalculateAllocableBookablesWithCategoriesRequest,
  CalculateAllocableBookablesWithCategoriesResponse,
  GetAllocableBookablesResponse,
  GetAllocableQuery,
} from "../../../../api-contracts/bookables/allocable";

export const BOOKABLES_QUERY_KEY = "bookables";

export const useUpdateBookable = createMutation<
  PatchBookableResponse,
  { patch: PatchBookableRequest; id: string }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}/${data.id}`,
      data.patch,
    ),
});

export const useGetBookablesByCategory = createQuery<
  GetBookablesResponse | GetBookablesByCategoryResponse,
  { showByCategory: boolean }
>({
  primaryKey: BOOKABLES_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { showByCategory }] }) => {
    const qs = [];
    if (showByCategory) {
      qs.push("by=category");
    }
    return api.get(
      `/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}?${qs.join("&")}`,
    );
  },
});

export const useGetBookables = createQuery<GetBookablesResponse>({
  primaryKey: BOOKABLES_QUERY_KEY,
  queryFn: async () => {
    return api.get(`/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}`);
  },
});

export const useBlockBookable = createMutation<
  BlockBookableResponse,
  BlockBookableRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}/block`, data),
});

export const useGetCategoryBookables = createQuery<
  GetCategoryBookabelsResponse,
  { id: string }
>({
  primaryKey: BOOKABLES_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { id }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}/category/${id}`,
    );
  },
});

export const useGetAllocableBookables = createQuery<
  GetAllocableBookablesResponse,
  GetAllocableQuery
>({
  primaryKey: BOOKABLES_QUERY_KEY,
  queryFn: async ({
    queryKey: [_, { categoryId, startDate, endDate, bookingId }],
  }) => {
    return api.get(`/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}/allocable`, {
      startDate,
      endDate,
      categoryId,
      bookingId,
    });
  },
});

export const useGetAllocableBookablesWithCategories = createQuery<
  CalculateAllocableBookablesWithCategoriesResponse,
  CalculateAllocableBookablesWithCategoriesRequest
>({
  primaryKey: BOOKABLES_QUERY_KEY,
  queryFn: async ({
    queryKey: [_, { startDate, endDate, adults, children, types }],
  }) => {
    return api.post(
      `/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}/allocable/categories`,
      {
        startDate,
        endDate,
        adults,
        children,
        types,
      },
    );
  },
});

export const useGetAssetSchedule = createQuery<
  AssetScheduleResponse,
  AssetScheduleRequest
>({
  primaryKey: BOOKABLES_QUERY_KEY,
  queryFn: async ({ queryKey: [_, data] }) => {
    return api.post(
      `/api/${currentSite?.id}/${BOOKABLES_QUERY_KEY}/allocable/schedule`,
      data,
    );
  },
});
