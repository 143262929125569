import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table.tsx";
import {
  Column,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  AlertTriangle,
  ChevronDownIcon,
  ChevronsUpDownIcon,
  ChevronUpIcon,
  Info,
} from "lucide-react";
import {
  OutOfOrder,
  OutOfOrderStatus,
} from "../../../../../api-contracts/out-of-order";
import { Button } from "@primitives/button.tsx";
import { Space } from "@components/space.tsx";
import { hasDatePassed, isDateToday } from "@utils/helpers.ts";
import { useContext, useState } from "react";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { useTranslation } from "react-i18next";
import { DropDown } from "@components/default-list.tsx";
import { DeleteOutOfOrderDialog } from "@pages/out-of-order/components/delete-out-of-order-dialog.tsx";
import { QuitOutOfOrderDialog } from "@pages/out-of-order/components/quit-out-of-order-dialog.tsx";
import { HighlightText } from "@pages/settings/categories/components/highlight-text.tsx";
import { EditOutOfOrderSheet } from "@pages/out-of-order/components/edit-out-of-order-sheet.tsx";

export const OutOfOrderTable = () => {
  const { t } = useTranslation();
  const outOforderColumnsHelper = createColumnHelper<OutOfOrder>();
  const { outOfOrdersData, deleteOOO, searchTerm, filteredData } =
    useContext(OutOfOrderContext);
  const [deleteOOODialogOpen, setDeleteOOODialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [quitDialogOpen, setQuitDialogOpen] = useState(false);
  const [selectedOutOfOrder, setSelectedOutOfOrder] = useState<OutOfOrder>({
    asset: { id: "", name: "" },
    categoryTypes: ["room"],
    createdAt: "",
    createdBy: { id: "", name: "" },
    endDate: "",
    endedAt: "",
    endedBy: { id: "", name: "" },
    id: "",
    reason: "",
    startDate: "",
    status: "active",
    type: "out-of-order",
  });
  const outOfOrderColumnsDefinitions = [
    outOforderColumnsHelper.accessor("asset", {
      header: t("unit"),
      id: "asset",
      size: 150,
      cell: (data) => {
        return (
          <HighlightText
            text={data.row.original.asset.name}
            textToHighlight={searchTerm}
          />
        );
      },
    }),
    outOforderColumnsHelper.accessor("type", {
      header: t("block"),
      id: "type",
      size: 170,
      cell: (data) => {
        return (
          <div className={"flex items-center"}>
            <div className={"rounded-xl bg-primary-card-backplate px-2 py-1.5"}>
              {t(data.row.original.type)}
            </div>
          </div>
        );
      },
    }),
    outOforderColumnsHelper.accessor("startDate", {
      header: t("from"),
      id: "startDate",
      size: 120,
      cell: (data) => {
        const date = new Date(data.row.original.startDate).toLocaleDateString(
          "sv",
        );
        return <div className={"text-primary-text"}>{date}</div>;
      },
    }),
    outOforderColumnsHelper.accessor("endDate", {
      header: t("to"),
      id: "endDate",
      size: 280,
      cell: (data) => {
        const date = new Date(data.row.original.endDate).toLocaleDateString(
          "sv",
        );
        return (
          <div className={"flex items-center "}>
            <div
              className={getDateColor(
                data.row.original.endDate,
                data.row.original.status,
              )}
            >
              {date}
            </div>
            {isDateToday(date) && (
              <>
                <Space w={10} />
                <div
                  className={
                    "flex items-center rounded-xl bg-status-warning-100 px-2 py-1.5"
                  }
                >
                  <AlertTriangle
                    size={17}
                    className={" mr-2 text-status-warning"}
                  />
                  <p
                    className={"text-[12px] font-extrabold text-status-warning"}
                  >
                    Idag
                  </p>
                </div>
              </>
            )}
            {hasDatePassed(date) && (
              <>
                <Space w={10} />
                <div
                  className={
                    "flex items-center rounded-xl bg-status-error-100 px-2 py-1.5"
                  }
                >
                  <Info size={17} className={"mr-2 text-status-error"} />
                  <p className={"text-[12px] font-extrabold text-status-error"}>
                    Passerad
                  </p>
                </div>
              </>
            )}
          </div>
        );
      },
    }),
    outOforderColumnsHelper.accessor("reason", {
      header: t("reason"),
      id: "reason",
      size: 330,
      cell: (data) => {
        return (
          <HighlightText
            text={data.row.original.reason}
            textToHighlight={searchTerm}
          />
        );
      },
    }),
    outOforderColumnsHelper.accessor("createdBy", {
      header: t("added-by"),
      id: "createdBy",
      cell: (data) => {
        return <div>{data.row.original.createdBy.name}</div>;
      },
    }),
    outOforderColumnsHelper.accessor("createdAt", {
      header: t("created"),
      id: "createdAt",
      cell: (data) => {
        const date = new Date(data.row.original.createdAt);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        const time = `${hours}:${minutes}`;
        return (
          <div>
            <p>
              {new Date(data.row.original.createdAt ?? "").toLocaleDateString(
                "sv",
              )}{" "}
            </p>
            <p>{time}</p>
          </div>
        );
      },
    }),
    outOforderColumnsHelper.accessor("status", {
      header: t("status"),
      id: "status",
      cell: (data) => {
        return (
          <div className={"flex items-center "}>
            {getStatus(data.row.original.status)}
          </div>
        );
      },
    }),
    outOforderColumnsHelper.display({
      header: t("action"),
      id: "action",
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <div>
            {row.original.status === "active" && (
              <Button
                onClick={() => {
                  setSelectedOutOfOrder(row.original);
                  setQuitDialogOpen(true);
                }}
                size={"sm"}
              >
                Avsluta
              </Button>
            )}
          </div>
        );
      },
    }),
    outOforderColumnsHelper.accessor("endedBy.id", {
      header: t("action-performed-by"),
      id: "Name",
      cell: (data) => {
        return <div>{data.row.original.endedBy.name}</div>;
      },
    }),
    outOforderColumnsHelper.accessor("endedBy.name", {
      header: t("action-done"),
      id: "endedBy",
      cell: (data) => {
        const date = new Date(data.row.original.endedAt ?? "");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        const time = `${hours}:${minutes}`;

        return (
          <div>
            {data.row.original.endedAt && (
              <>
                <p>
                  {new Date(data.row.original.endedAt).toLocaleDateString("sv")}{" "}
                </p>
                <p>{time}</p>
              </>
            )}
          </div>
        );
      },
    }),
    outOforderColumnsHelper.display({
      id: "action",
      enableHiding: false,
      size: 30,
      cell: ({ row }) => {
        return (
          <div className={"flex w-full justify-end"}>
            <DropDown
              onHistory={() => {
                setSelectedOutOfOrder(row.original);
                setDeleteOOODialogOpen(true);
              }}
              isVisible={true}
              onEdit={() => {
                if (row.original.status === "ended") return;
                setSelectedOutOfOrder(row.original);
                setEditDialogOpen(true);
              }}
              onDelete={() => {
                if (row.original.status !== "removed") {
                  setSelectedOutOfOrder(row.original);
                  setDeleteOOODialogOpen(true);
                }
              }}
            />
          </div>
        );
      },
    }),
  ];

  const getStatus = (status: OutOfOrderStatus) => {
    switch (status) {
      case "removed":
        return (
          <div className={"rounded-xl bg-status-error-100 px-2 py-1.5"}>
            <p className={"text-[12px] font-extrabold text-status-error"}>
              {t(status)}
            </p>
          </div>
        );
      case "active":
        return (
          <div
            className={
              "flex items-center rounded-xl bg-status-warning-100 px-2 py-1.5"
            }
          >
            <p className={"text-[12px] font-extrabold text-status-warning"}>
              {t(status)}
            </p>
          </div>
        );
      case "ended":
        return (
          <div
            className={
              "flex items-center rounded-xl bg-status-success-100 px-2 py-1.5"
            }
          >
            <p className={"text-[12px] font-extrabold text-status-success"}>
              {t(status)}
            </p>
          </div>
        );
      case "planned":
        return (
          <div
            className={
              "flex items-center rounded-xl bg-status-disabled-100 px-2 py-1.5"
            }
          >
            <p className={"text-[12px] font-extrabold text-status-disabled"}>
              {t(status)}
            </p>
          </div>
        );
    }
  };
  const getCellColor = (date: string, status: OutOfOrderStatus) => {
    if (isDateToday(date)) return "#EAB3081A";
    if (hasDatePassed(date) && status !== "removed") return "#DC26261A";
    else return "";
  };

  const getDateColor = (dateToCheck: string, status: OutOfOrderStatus) => {
    const date = new Date(dateToCheck).toLocaleDateString("sv");
    if (isDateToday(date)) return "text-status-warning";
    if (hasDatePassed(date) && status !== "removed") return "text-status-error";
    return "text-primary-text";
  };

  const table = useReactTable({
    data: outOfOrdersData.outOfOrders,
    columns: outOfOrderColumnsDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getSortIcon = (
    column: Column<OutOfOrder>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };
  return (
    <>
      <EditOutOfOrderSheet
        ooo={selectedOutOfOrder}
        open={editDialogOpen}
        onOpenChange={setEditDialogOpen}
      />
      <DeleteOutOfOrderDialog
        open={deleteOOODialogOpen}
        onOpenChange={setDeleteOOODialogOpen}
        outOfOrder={selectedOutOfOrder}
      />
      <QuitOutOfOrderDialog
        open={quitDialogOpen}
        onOpenChange={setQuitDialogOpen}
        outOfOrder={selectedOutOfOrder}
      />
      <div className={" mt-6 text-primary-text"}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <TableRow key={index}>
                {headerGroup.headers.map((header, index) => (
                  <TableHead className={"select-none"} key={index}>
                    {header.isPlaceholder ? null : (
                      <div
                        className="flex cursor-pointer items-center"
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanSort() && (
                          <div className="ml-1">
                            {getSortIcon(
                              header.column,
                              table.getState().sorting,
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  className={"hover:bg-secondary-card-backplate"}
                  key={index}
                >
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <TableCell
                        key={index}
                        style={{
                          width: `${cell.column.getSize()}px`,
                          height: "74px",
                          backgroundColor: getCellColor(
                            cell.row.original.endDate,
                            cell.row.original.status,
                          ),
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={table.getAllLeafColumns().length}
                  className="h-24 text-center"
                >
                  {t("no-results")}.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
