import { InputWithLabel } from "@pages/simple-search/components/filter-side-sheet/input-with-label";
import { Button } from "@primitives/button";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { Switch } from "@primitives/switch";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { GuestBase } from "../../../../../api-contracts/guests";
import { MarketingConsent } from "..";

export const CreateCustomerSheet = ({
  open,
  onOpenChange,
  people,
  handleInputChange,
  marketingConsents,
  setMarketingConsents,
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  people: Partial<GuestBase>;
  handleInputChange: (field: keyof GuestBase, value: string) => void;
  marketingConsents: MarketingConsent;
  setMarketingConsents: Dispatch<SetStateAction<MarketingConsent>>;
}) => {
  const [isBillingSameAsAddress, setIsBillingSameAsAddress] =
    useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <DefaultSideSheet
      title={t("create-new-people")}
      open={open}
      onOpenChange={onOpenChange}
      className="flex h-full flex-col"
    >
      <div className="w-full flex-grow justify-start overflow-auto text-primary-text">
        <PersonalInformation
          people={people}
          handleInputChange={handleInputChange}
        />
        <div className="py-5">
          <AddressInformation
            people={people}
            handleInputChange={handleInputChange}
            isBillingSameAsAddress={isBillingSameAsAddress}
            setIsBillingSameAsAddress={setIsBillingSameAsAddress}
          />
        </div>

        <CustomerConsent
          marketingConsents={marketingConsents}
          setMarketingConsents={setMarketingConsents}
        />
      </div>
      <div className="flex justify-end">
        <Button>{t("create")}</Button>
      </div>
    </DefaultSideSheet>
  );
};

const PersonalInformation = ({
  people,
  handleInputChange,
}: {
  people: Partial<GuestBase>;
  handleInputChange: (field: keyof GuestBase, value: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col rounded-lg bg-secondary-card-backplate p-4 text-primary-text">
      <p className="text-sm font-bold">{t("personal-data")}</p>
      <div className="flex flex-col">
        <InputWithLabel
          label={t("email")}
          onChange={(e) => handleInputChange("email", e.target.value)}
          value={people.email ?? ""}
        />

        <InputWithLabel
          label={t("phone")}
          onChange={(e) => handleInputChange("phone", e.target.value)}
          value={people.phone ?? ""}
        />

        <InputWithLabel
          label={t("first-name")}
          onChange={(e) => handleInputChange("name", e.target.value)}
          value={people.name ?? ""}
        />

        <InputWithLabel
          label={t("surname")}
          onChange={(e) => handleInputChange("surname", e.target.value)}
          value={people.surname ?? ""}
        />
        {/* TODO - Check if date of birth should be passport number */}
        <InputWithLabel
          label={t("date-of-birth")}
          onChange={(e) => handleInputChange("passportNumber", e.target.value)}
          value={people.passportNumber ?? ""}
        />
      </div>
    </div>
  );
};

const AddressInformation = ({
  isBillingSameAsAddress,
  setIsBillingSameAsAddress,
  people,
  handleInputChange,
}: {
  isBillingSameAsAddress: boolean;
  setIsBillingSameAsAddress: Dispatch<SetStateAction<boolean>>;
  people: Partial<GuestBase>;
  handleInputChange: (field: keyof GuestBase, value: string) => void;
}) => {
  // TODO - Handle invoiceReference when the correct Customer/People type exists
  // useEffect(() => {
  //   if (isBillingSameAsAddress) {
  //     handleInputChange("invoiceReference", organization.address ?? "");
  //   }
  // }, [isBillingSameAsAddress]);

  const { t } = useTranslation();
  return (
    <div className="flex flex-col rounded-lg bg-secondary-card-backplate p-4 text-primary-text">
      <p className="text-sm font-bold">{t("address")}</p>
      <div className="flex flex-col">
        <InputWithLabel
          label={`${t("address-line")} 1`}
          onChange={(e) => handleInputChange("address", e.target.value)}
          value={people.address ?? ""}
        />

        <InputWithLabel
          label={`${t("address-line")} 2`}
          onChange={(e) => handleInputChange("address2", e.target.value)}
          value={people.address2 ?? ""}
        />

        <InputWithLabel
          label={t("zip-code")}
          onChange={(e) => handleInputChange("zip", e.target.value)}
          value={people.zip ?? ""}
        />

        <InputWithLabel
          label={t("city")}
          onChange={(e) => handleInputChange("city", e.target.value)}
          value={people.city ?? ""}
        />

        <InputWithLabel
          label={t("state-province-region")}
          onChange={(e) => handleInputChange("state", e.target.value)}
          value={people.state ?? ""}
        />

        <InputWithLabel
          label={t("country")}
          onChange={(e) => handleInputChange("countryCode", e.target.value)}
          value={people.countryCode ?? ""}
        />
      </div>
      <div className="pt-2">
        <p className="text-sm font-bold">{t("billing-address")}</p>
        <div className="flex flex-col">
          <InputWithLabel label={t("reference")} />
          <div className="flex pt-2">
            <SwitchWithLabel
              disabled
              label={t("same-as-address")}
              checked={isBillingSameAsAddress}
              onCheckedChange={setIsBillingSameAsAddress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerConsent = ({
  marketingConsents,
  setMarketingConsents,
}: {
  marketingConsents: MarketingConsent;
  setMarketingConsents: Dispatch<SetStateAction<MarketingConsent>>;
}) => {
  const { t } = useTranslation();

  const handleToggle =
    (field: keyof MarketingConsent) => (checked: boolean) => {
      setMarketingConsents((prev) => ({
        ...prev,
        [field]: checked,
      }));
    };

  return (
    <div className="flex flex-col rounded-lg bg-secondary-card-backplate p-4">
      <p className="text-sm font-bold">{t("customer-consent")}</p>
      <div className="pt-4">
        <div className="flex items-center justify-between ">
          <p>{t("newsletter")}</p>
          <Switch
            checked={marketingConsents.newsletter}
            onCheckedChange={handleToggle("newsletter")}
          />
        </div>
        <div className="flex items-center justify-between py-4 ">
          <p>{t("sms-marketing")}</p>
          <Switch
            checked={marketingConsents.sms}
            onCheckedChange={handleToggle("sms")}
          />
        </div>
        <div className="flex items-center justify-between ">
          <p>{t("email-marketing")}</p>
          <Switch
            checked={marketingConsents.email}
            onCheckedChange={handleToggle("email")}
          />
        </div>
      </div>
    </div>
  );
};
