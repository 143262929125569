import { Button } from "@primitives/button";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { Ellipsis, Filter, Loader2 } from "lucide-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table.tsx";
import { useGetAssetsByCategory, useUpdateAsset } from "@api/assets.ts";
import {
  AssetOverview,
  GroupedAssets,
  VisualAssetType,
} from "../../../../../api-contracts/assets";
import { Link, useNavigate } from "react-router-dom";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { queryClient } from "../../../query-client.ts";
import { Checkbox } from "@primitives/checkbox.tsx";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { useUpdateBookable } from "@api/bookables.ts";
import { PatchBookableRequest } from "../../../../../api-contracts/bookables";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import { SearchBarWithIcon } from "@primitives/search-bar-with-icon";
import { Card, CardTitle } from "@primitives/card.tsx";
import { NavigationArrows } from "@components/navigation-arrows.tsx";
import { CreateCombinationDialog } from "./components/create-combination-dialog.tsx";
import {
  useGetCombinationsByCategory,
  useUpdateCombination,
} from "@api/combinations.ts";
import { useProfileContext } from "@context/profile-context.tsx";
import { SearchBar } from "@primitives/search-bar.tsx";
import { useGetAssetGroups } from "@api/groups.ts";
import { AssetGroup } from "../../../../../api-contracts/groups";
import { useGetCategories } from "@api/categories.ts";
import { Category } from "../../../../../api-contracts/categories";
import { useGetLocations } from "@api/locations.ts";
import { LocationDTO } from "../../../../../api-contracts/locations";
import { cn } from "@shared/utils/css.ts";
import { combine, intersects } from "@shared/utils/helpers.ts";
import { ROUTES } from "@shared/types/navigation.ts";
import { useToast } from "@hooks/use-toast.ts";
import { CamprTabNavigation } from "@pages/settings/bookables/campr/components/campr-tab-navigation.tsx";
import { CamprNewButton } from "@pages/settings/bookables/campr/components/campr-new-button.tsx";
import { getAssetTypes } from "@utils/module-helper.ts";
import { ConfirmDialog } from "@components/confirm-dialog.tsx";

type ColumnsShown = {
  status: boolean;
  name: boolean;
  size: boolean;
  categories: boolean;
  internalCost: boolean;
  bedCapacity: boolean;
  guestCapacity: boolean;
  internal: boolean;
  online: boolean;
  external: boolean;
  location: boolean;
  combinations: boolean;
  dormitory: boolean;
};

interface Props {
  tab: VisualAssetType;
}

export const assetTypeToRoute = (type: VisualAssetType) => {
  // Locking it to fetch from first found type for now
  switch (type) {
    case "area":
    case "campr-area":
      return ROUTES.AREAS;
    case "bed":
      return ROUTES.BEDS;
    case "dormitory":
      return ROUTES.DORMITORIES;
    case "room":
      return ROUTES.ROOMS;
    case "plot":
    case "tent":
    case "caravan":
    case "motorhome":
      return ROUTES.PLOTS;
    case "misc":
      return ROUTES.OTHER_ACCOMMODATIONS;
    case "cabin":
      return ROUTES.CABINS;
    case "all":
      return ROUTES.ASSETS;
    case "combination":
      return ROUTES.COMBINATIONS;
  }
};

const AssetsPage: FC<Props> = ({ tab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const [search, setSearch] = useState<string>("");
  const [groupSearchTerm, setGroupSearchTerm] = useState<string>("");
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>("");
  const [locationSearchTerm, setLocationSearchTerm] = useState<string>("");
  const [columnsShown, setColumnsShown] = useState<ColumnsShown>({
    status: false,
    name: true,
    size: true,
    categories: true,
    internalCost: true,
    bedCapacity: true,
    guestCapacity: true,
    internal: true,
    online: true,
    external: true,
    location: true,
    combinations: true,
    dormitory: true,
  });
  const [includedActiveStatus, setIncludedActiveStatus] = useState<
    { key: string; value: boolean }[]
  >([{ key: "active", value: true }]);
  const [includedGroups, setIncludedGroups] = useState<AssetGroup[]>([]);
  const [includedCategories, setIncludedCategories] = useState<Category[]>([]);
  const [includedLocations, setIncludedLocations] = useState<LocationDTO[]>([]);
  const [activeAsset, setActiveAsset] = useState<AssetOverview>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showCreateCombinationDialog, setShowCreateCombinationDialog] =
    useState<boolean>(false);
  const [showCreateDormitoryDialog, setShowCreateDormitoryDialog] =
    useState<boolean>(false);
  const [isOpenDeactivateDialog, setIsOpenDeactivateDialog] =
    useState<boolean>(false);
  const { toast } = useToast();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetAssetsByCategory.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetCombinationsByCategory.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetAssetGroups.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetCategories.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetLocations.getKey(),
    });
  }, []);

  const showByCategory = tab === "all" && !includedGroups.length;

  const { data, isLoading, isRefetching } = useGetAssetsByCategory({
    variables: {
      showByCategory,
      types: getAssetTypes(module, tab),
    },
    enabled: [
      "all",
      "room",
      "bed",
      "area",
      "cabin",
      "plot",
      "cabin",
      "misc",
      "categories",
      "campr-area",
    ].includes(tab),
  });
  const camprTabs = ["plot", "cabin", "misc", "campr-combination"];
  const {
    data: combinations,
    isLoading: combinationsLoading,
    isRefetching: isRefetchingCombinations,
  } = useGetCombinationsByCategory({
    variables: {
      showByCategory,
      types: [tab as any],
    },
    enabled: ["combination", "dormitory", "campr-combination"].includes(tab),
  });

  const {
    data: assetGroups,
    isLoading: assetGroupsLoading,
    isRefetching: isRefetchingAssetGroups,
  } = useGetAssetGroups({});

  const {
    data: categories,
    isLoading: categoriesLoading,
    isRefetching: isRefetchingCategories,
  } = useGetCategories({
    variables: { categoryTypes: module === "roomr" ? ["room"] : ["tent"] },
  });

  const {
    data: locations,
    isLoading: locationsLoading,
    isRefetching: isRefetchingLocations,
  } = useGetLocations({});

  const updateAsset = useUpdateAsset();
  const updateCombination = useUpdateCombination();
  const updateBookable = useUpdateBookable();

  const currentData = [
    "combination",
    "dormitory",
    "campr-combination",
  ].includes(tab)
    ? combinations
    : data;

  const groups: GroupedAssets[] = useMemo(() => {
    if (showByCategory || includedGroups.length) {
      let groups = (currentData as GroupedAssets[]) || [];
      if (!showByCategory) {
        const c = combine(includedGroups, 1).sort(
          (g1, g2) => g2.length - g1.length,
        );
        groups = c.map((grouping, i) => ({
          name: grouping.map((g) => g.name).join(", "),
          id: String(i),
          items: (currentData as AssetOverview[])?.filter((a) =>
            grouping.every((g) => !!a.groups.find((g1) => g1.id == g.id)),
          ) as any,
        }));
      }
      if (search) {
        groups = groups.map((group) => ({
          ...group,
          items: group.items?.filter(
            (a) =>
              a.name?.toLowerCase().includes(search.toLowerCase()) ||
              a.location?.name?.toLowerCase().includes(search.toLowerCase()) ||
              a.combinations
                ?.map((c) => c.name)
                ?.join(" ")
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              String(a.internalCost)
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              String(a.size)?.toLowerCase().includes(search.toLowerCase()),
          ),
        }));
      }

      groups = groups.map((group) => ({
        ...group,
        items: group.items?.filter(
          (a) => !!includedActiveStatus.find((s) => a.active === s.value),
        ),
      }));
      if (includedLocations.length) {
        groups = groups.map((group) => ({
          ...group,
          items: group.items?.filter(
            (a) => !!includedLocations.find((l) => a.location?.id === l.id),
          ),
        }));
      }
      if (includedCategories.length && !showByCategory) {
        groups = groups.map((group) => ({
          ...group,
          items: group.items?.filter((a: any) =>
            a.categories?.some(
              (c: any) => !!includedCategories.find((c1) => c.id === c1.id),
            ),
          ),
        }));
      }
      return groups;
    } else {
      return [];
    }
  }, [
    search,
    currentData,
    showByCategory,
    includedActiveStatus,
    includedGroups,
    includedLocations,
    includedCategories,
  ]);

  const assets: AssetOverview[] = useMemo(() => {
    let assets = (currentData as AssetOverview[]) || [];

    if (search) {
      assets = assets?.filter(
        (a: AssetOverview) =>
          a.name?.toLowerCase().includes(search.toLowerCase()) ||
          a.location?.name?.toLowerCase().includes(search.toLowerCase()) ||
          a.combinations
            ?.map((c) => c.name)
            ?.join(" ")
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          String(a.size)?.toLowerCase().includes(search.toLowerCase()) ||
          a.categories
            ?.map((c) => c.name)
            .join("|")
            .toLowerCase()
            .includes(search.toLowerCase()),
      );
    }

    assets = assets.filter(
      (b: AssetOverview) =>
        !!includedActiveStatus.find((s) => b.active === s.value),
    );
    if (includedLocations.length) {
      if (tab === "combination") {
        assets = assets.filter((b: any) =>
          b.locations.some(
            (l: any) => !!includedLocations.find((l1) => l.id === l1.id),
          ),
        );
      } else {
        assets = assets.filter(
          (b: AssetOverview) =>
            !!includedLocations.find((l) => b.location.id === l.id),
        );
      }
    }
    if (includedCategories.length) {
      assets = assets.filter((b: AssetOverview) =>
        b.categories.some(
          (c) => !!includedCategories.find((c1) => c.id === c1.id),
        ),
      );
    }
    return assets;
  }, [
    currentData,
    search,
    includedLocations,
    includedCategories,
    includedActiveStatus,
    tab,
  ]);

  const columns: ColumnDef<AssetOverview>[] = [];
  if (includedActiveStatus.find((s) => s.key === "inactive")) {
    columns.push({
      cell: (row) =>
        !row.row.original.active && (
          <p className="inline-block rounded bg-status-error-100 p-2 text-xs font-extrabold text-status-error">
            {t("inactive")}
          </p>
        ),
      accessorKey: "active",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("status")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "active",
    });
  }
  if (columnsShown.name) {
    columns.push({
      cell: (row) => <p className="text-xs">{row.row.original.name}</p>,
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("name")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },

      size: 5,
      id: "name",
    });
  }
  if (columnsShown.size && tab !== "bed" && tab !== "plot") {
    columns.push({
      cell: (row) => (
        <p className="text-xs">
          {row.row.original.size}{" "}
          <span className=" text-xs text-secondary-text">{t("sq-m")}</span>
        </p>
      ),
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("size")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "size",
      accessorKey: "size",
    });
  }
  if (tab === "plot") {
    columns.push({
      cell: (data) => {
        return data.row.original.length !== null ? (
          <div>{data.row.original.length} m</div>
        ) : (
          ""
        );
      },
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("length")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "length",
    });
  }
  if (tab === "plot") {
    columns.push({
      cell: (data) => {
        return data.row.original.width !== null ? (
          <div>{data.row.original.width} m</div>
        ) : (
          ""
        );
      },
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("width")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
      id: "width",
    });
  }
  if (columnsShown.categories && !showByCategory) {
    columns.push({
      cell: (row: any) => (
        <>
          <div className={"flex"}>
            {(row.row.original as any).categories?.map((l: any) => (
              <div
                key={l.id}
                className={
                  "mr-2 w-max rounded-md bg-secondary-card-backplate p-2"
                }
              >
                <span className="text-xs font-extrabold">{l.short}</span>
              </div>
            ))}
          </div>
        </>
      ),
      accessorFn: (row: AssetOverview) =>
        row.categories?.map((c) => c.name).join(" "),
      id: "categories",
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("category")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      size: 1,
    });
  }
  if (columnsShown.internalCost && showByCategory) {
    columns.push({
      cell: (row: any) => (
        <p className=" text-xs">
          {row.row.original.internalCost}{" "}
          <span className=" text-xs text-secondary-text">SEK</span>
        </p>
      ),
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("internal-cost")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      id: "internalCost",
      size: 1,
      accessorKey: "internalCost",
    });
  }
  if (columnsShown.bedCapacity && tab !== "area" && !camprTabs.includes(tab)) {
    columns.push({
      accessorFn: (row: AssetOverview) =>
        row.bedCapacity?.standardBeds
          .map((b) => `${b.quantity}${t("piece-short")} ${b.name}`)
          .join(", "),

      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("bed-capacity")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: (row: any) =>
        row.row.original.bedCapacity ? (
          <div>
            {row.row.original.bedCapacity.standardBeds.length > 0 && (
              <div className=" text-xs ">
                {row.row.original.bedCapacity.standardBeds
                  .map((b: any) => `${b.quantity}${t("piece-short")} ${b.name}`)
                  .join(", ")}
              </div>
            )}
            {row.row.original.bedCapacity.extraBedLimitFullSize > 0 && (
              <div className=" text-xs text-secondary-text">
                {t("max-{{max}}-extra-beds", {
                  max: row.row.original.bedCapacity.extraBedLimitFullSize,
                })}
              </div>
            )}
          </div>
        ) : null,
      id: "bedCapacity",
      size: 1,
    });
  }
  if (columnsShown.guestCapacity && tab === "area" && module !== "campr") {
    columns.push({
      accessorFn: (row: AssetOverview) =>
        row.guestCapacity
          ? `${row.guestCapacity.min} -  ${row.guestCapacity.max} ${t("persons")}`
          : undefined,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("guest-capacity")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      id: "guestCapacity",
      size: 1,
    });
  }
  if (columnsShown.internal && showByCategory) {
    columns.push({
      accessorFn: (row: any) => row.available.internal,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("internal")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      id: "internal",
      cell: (row: any) => (
        <Checkbox
          disabled
          onClick={(e) => e.stopPropagation()}
          checked={row.row.original.available.internal}
          onCheckedChange={(value) =>
            patchBookable(row.row.original.bookableId, {
              available: {
                ...row.row.original.available,
                internal: value as boolean,
              },
            })
          }
        />
      ),
      size: 1,
    });
  }
  if (columnsShown.online && showByCategory) {
    columns.push({
      accessorFn: (row: any) => row.available.public,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("roomr-online")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      id: "online",
      cell: (row: any) => (
        <Checkbox
          disabled
          onClick={(e) => e.stopPropagation()}
          checked={row.row.original.available.public}
          onCheckedChange={(value) =>
            patchBookable(row.row.original.bookableId, {
              available: {
                ...row.row.original.available,
                public: value as boolean,
              },
            })
          }
        />
      ),
      size: 1,
    });
  }
  if (columnsShown.external && showByCategory) {
    columns.push({
      accessorFn: (row: any) => row.available.external,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("external")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      id: "external",
      cell: (row: any) => (
        <Checkbox
          disabled
          onClick={(e) => e.stopPropagation()}
          checked={row.row.original.available.external}
          onCheckedChange={(value) =>
            patchBookable(row.row.original.bookableId, {
              available: {
                ...row.row.original.available,
                external: value as boolean,
              },
            })
          }
        />
      ),
      size: 1,
    });
  }
  if (columnsShown.location) {
    columns.push({
      cell: (row: any) => (
        <>
          {row.row.original.locations ? (
            (row.row.original as any).locations
              ?.filter((l: any) => l.name)
              .map((l: any) => (
                <span className=" mr-4 text-xs" key={l.id}>
                  {l.name}
                </span>
              ))
          ) : (
            <p className="text-xs">{row.row.original.location?.name}</p>
          )}
        </>
      ),
      accessorFn: (row: AssetOverview) =>
        tab === "combination" || tab === "dormitory"
          ? (row as any).locations?.map((l: any) => l.name).join(" ")
          : row.location?.name,
      header: ({ column }) => {
        return (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {t("location")}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </div>
        );
      },
      id: "location",
      size: 1,
    });
  }
  if (columnsShown.combinations && !["combination"].includes(tab)) {
    columns.push({
      cell: (row: any) => (
        <>
          {(row.row.original as any).combinations?.map((l: any) => (
            <span className=" mr-4 text-xs font-extrabold" key={l.id}>
              {l.name}
            </span>
          ))}
        </>
      ),
      accessorFn: (row: AssetOverview) =>
        row.combinations?.map((c) => c.name).join(" "),
      header: tab === "bed" ? t("dormitory") : t("combination"),
      id: "combinations",
      size: 1,
    });
  }
  columns.push({
    cell: (row) => {
      return (
        <DropdownMenuRoot>
          <DropdownMenuTrigger className="w-full">
            <div className=" flex justify-end">
              <Ellipsis size={18} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/${module}/${ROUTES.BOOKABLES}/${assetTypeToRoute(row.row.original.types[0])}/${row.row.original.id}?edit`,
                );
              }}
            >
              {t("edit")}
            </DropdownMenuItem>
            {intersects(
              ["room", "area", "bed", "misc", "cabin"],
              row.row.original.types,
            ) ? (
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/${module}/${ROUTES.BOOKABLES}/${assetTypeToRoute(row.row.original.types[0])}/${row.row.original.id}/duplicate`,
                  );
                }}
              >
                {t("duplicate")}
              </DropdownMenuItem>
            ) : undefined}
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                setActiveAsset(row.row.original);

                if (row.row.original) {
                  setIsOpenDeactivateDialog(true);
                }
              }}
            >
              {row.row.original.active ? t("inactivate") : t("activate")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenuRoot>
      );
    },
    header: "",
    id: "actions",
    size: 1,
  });

  const toggleCombination = () => {
    setShowCreateCombinationDialog(true);
  };

  const toggleActive = async (asset?: AssetOverview) => {
    if (!asset) {
      return;
    }

    try {
      if (
        intersects(asset.types, [
          "dormitory",
          "combination",
          "campr-combination",
        ])
      ) {
        await updateCombination.mutateAsync({
          patch: {
            active: !asset.active,
          },
          id: asset.id,
        });
        queryClient.invalidateQueries({
          queryKey: useGetCombinationsByCategory.getKey(),
        });
      } else {
        await updateAsset.mutateAsync({
          patch: {
            active: !asset.active,
          },
          id: asset.id,
        });
        queryClient.invalidateQueries({
          queryKey: useGetAssetsByCategory.getKey(),
        });
      }
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
  };

  const patchBookable = async (id: string, patch: PatchBookableRequest) => {
    try {
      await updateBookable.mutateAsync({
        patch,
        id,
      });

      queryClient.invalidateQueries({
        queryKey: useGetAssetsByCategory.getKey(),
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="p-4">
        <NavigationArrows
          rootPath={
            module === "roomr"
              ? `/${module}/${ROUTES.BOOKABLES}/${ROUTES.ROOMS}`
              : `/${module}/${ROUTES.BOOKABLES}/${ROUTES.PLOTS}`
          }
        />
        {module === "roomr" ? (
          <Tabs className=" mb-4 mt-4" value={tab}>
            <TabsList>
              <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.ROOMS}`}>
                <TabsTrigger value="room">{t("room")}</TabsTrigger>
              </Link>
              <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.AREAS}`}>
                <TabsTrigger value="area">{t("area")}</TabsTrigger>
              </Link>
              <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.BEDS}`}>
                <TabsTrigger value="bed">{t("bed")}</TabsTrigger>
              </Link>
              <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}`}>
                <TabsTrigger value="dormitory">{t("dormitory")}</TabsTrigger>
              </Link>
              <Link
                to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`}
              >
                <TabsTrigger value="combination">
                  {t("combination")}
                </TabsTrigger>
              </Link>
              <Link to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.ASSETS}`}>
                <TabsTrigger value="all">{t("all")}</TabsTrigger>
              </Link>
            </TabsList>
          </Tabs>
        ) : (
          <CamprTabNavigation tab={tab} />
        )}
        <Card className="p-0">
          <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
            <div>
              {tab === "campr-combination" ? (
                <CardTitle>
                  {t(tab === "campr-combination" ? "combination" : tab)}
                </CardTitle>
              ) : (
                <CardTitle>{t(tab === "campr-area" ? "area" : tab)}</CardTitle>
              )}
            </div>
            {module === "roomr" ? (
              <>
                {tab === "room" && (
                  <Link
                    to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.ROOMS}/new`}
                  >
                    <Button variant="primary">{t("create-new")}</Button>
                  </Link>
                )}
                {tab === "area" && (
                  <Link
                    to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.AREAS}/new`}
                  >
                    <Button variant="primary">{t("create-new")}</Button>
                  </Link>
                )}
                {tab === "bed" && (
                  <Link
                    to={`/${module}/${ROUTES.BOOKABLES}/${ROUTES.BEDS}/new`}
                  >
                    <Button variant="primary">{t("create-new")}</Button>
                  </Link>
                )}
                {tab === "combination" && (
                  <Button
                    variant="primary"
                    onClick={() => setShowCreateCombinationDialog(true)}
                  >
                    {t("create-new")}
                  </Button>
                )}
                {tab === "dormitory" && (
                  <Button
                    variant="primary"
                    onClick={() => setShowCreateCombinationDialog(true)}
                  >
                    {t("create-new")}
                  </Button>
                )}{" "}
              </>
            ) : (
              <CamprNewButton tab={tab} onClick={toggleCombination} />
            )}
          </div>
          <div className="p-4">
            <div className="flex items-center justify-end">
              <div className=" flex space-x-2">
                <SearchBarWithIcon
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                  setSearchTerm={setSearch}
                  searchTerm={search}
                />
                <DropdownMenuRoot>
                  <DropdownMenuTrigger asChild>
                    <Button variant="secondary">
                      <Filter className="mr-2" size={17} />
                      {t("show")}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className=" w-[220px]">
                    <DropdownMenuLabel className="ml-5">
                      {t("status")}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <div className=" flex h-[32px] items-center px-2">
                      <CheckboxWithLabel
                        checked={
                          !!includedActiveStatus.find((s) => s.key === "active")
                        }
                        onCheckedChange={(v) => {
                          const newStatus = includedActiveStatus.filter(
                            (s) => s.key !== "active",
                          );
                          if (v) {
                            newStatus.push({ key: "active", value: true });
                          }
                          setIncludedActiveStatus(newStatus);
                        }}
                        label={t("active")}
                      />
                    </div>
                    <div className=" flex h-[32px] items-center px-2">
                      <CheckboxWithLabel
                        checked={
                          !!includedActiveStatus.find(
                            (s) => s.key === "inactive",
                          )
                        }
                        onCheckedChange={(v) => {
                          const newStatus = includedActiveStatus.filter(
                            (s) => s.key !== "inactive",
                          );
                          if (v) {
                            newStatus.push({ key: "inactive", value: false });
                          }
                          setIncludedActiveStatus(newStatus);
                        }}
                        label={t("inactive")}
                      />
                    </div>
                    <DropdownMenuSeparator />
                    <DropdownMenuSub>
                      <DropdownMenuSubTrigger>
                        <DropdownMenuLabel className="ml-3">
                          {t("grouping")}
                        </DropdownMenuLabel>
                      </DropdownMenuSubTrigger>
                      <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                          <SearchBar
                            placeholder={t("search-grouping")}
                            value={groupSearchTerm}
                            onChange={(e) => setGroupSearchTerm(e.target.value)}
                            autoFocus
                          />
                          <DropdownMenuSeparator />
                          <p className=" mb-1 ml-1 text-xs font-bold text-secondary-text">
                            {t("group-on")}
                          </p>
                          <div className=" mx-2">
                            {assetGroups?.map((g) => (
                              <div
                                key={g.id}
                                className=" flex h-[32px] items-center"
                              >
                                <CheckboxWithLabel
                                  label={g.name}
                                  checked={
                                    !!includedGroups.find(
                                      (g1) => g.id === g1.id,
                                    )
                                  }
                                  onCheckedChange={(v) => {
                                    const newGroups = includedGroups.filter(
                                      (g1) => g1.id !== g.id,
                                    );
                                    if (v) {
                                      newGroups.push(g);
                                    }
                                    setIncludedGroups([...newGroups]);
                                  }}
                                />
                              </div>
                            ))}
                          </div>

                          <DropdownMenuSeparator />
                          <DropdownMenuLabel
                            className={cn("cursor-pointer text-center", {
                              "cursor-auto text-status-disabled":
                                includedGroups.length === 0,
                            })}
                            onClick={() => setIncludedGroups([])}
                          >
                            {t("remove-grouping")}
                          </DropdownMenuLabel>
                        </DropdownMenuSubContent>
                      </DropdownMenuPortal>
                    </DropdownMenuSub>
                    <DropdownMenuSeparator />
                    <DropdownMenuLabel className="ml-5">
                      {t("columns")}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <div>
                      {tab !== "bed" ? (
                        <div className=" flex h-[32px] items-center px-2">
                          <CheckboxWithLabel
                            checked={columnsShown.size}
                            onCheckedChange={(v) =>
                              setColumnsShown({
                                ...columnsShown,
                                size: v as boolean,
                              })
                            }
                            label={t("size")}
                          />
                        </div>
                      ) : null}
                      {!showByCategory ? (
                        <DropdownMenuSub>
                          <DropdownMenuSubTrigger>
                            <div className=" flex h-[32px] items-center">
                              <CheckboxWithLabel
                                checked={columnsShown.categories}
                                onCheckedChange={(v) =>
                                  setColumnsShown({
                                    ...columnsShown,
                                    categories: v as boolean,
                                  })
                                }
                                label={t("category")}
                              />
                            </div>
                          </DropdownMenuSubTrigger>
                          <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                              <SearchBar
                                placeholder={t("search-category")}
                                value={categorySearchTerm}
                                onChange={(e) =>
                                  setCategorySearchTerm(e.target.value)
                                }
                                autoFocus
                              />
                              <DropdownMenuSeparator />
                              <p className=" mb-1 ml-1 text-xs font-bold text-secondary-text">
                                {t("filter-on")}
                              </p>
                              <div className=" mx-2">
                                {categories?.map((c) => (
                                  <div
                                    key={c.id}
                                    className=" flex h-[32px] items-center px-2"
                                  >
                                    <CheckboxWithLabel
                                      label={c.name}
                                      checked={
                                        !!includedCategories.find(
                                          (c1) => c.id === c1.id,
                                        )
                                      }
                                      onCheckedChange={(v) => {
                                        const newCategories =
                                          includedCategories.filter(
                                            (c1) => c1.id !== c.id,
                                          );
                                        if (v) {
                                          newCategories.push(c);
                                        }
                                        setIncludedCategories([
                                          ...newCategories,
                                        ]);
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                              <DropdownMenuSeparator />
                              <DropdownMenuLabel
                                className={cn("cursor-pointer text-center", {
                                  "cursor-auto text-status-disabled":
                                    includedCategories.length === 0,
                                })}
                                onClick={() => setIncludedCategories([])}
                              >
                                {t("remove-filter")}
                              </DropdownMenuLabel>
                            </DropdownMenuSubContent>
                          </DropdownMenuPortal>
                        </DropdownMenuSub>
                      ) : null}
                      {showByCategory ? (
                        <div className=" flex h-[32px] items-center px-2">
                          <CheckboxWithLabel
                            checked={columnsShown.internalCost}
                            onCheckedChange={(v) =>
                              setColumnsShown({
                                ...columnsShown,
                                internalCost: v as boolean,
                              })
                            }
                            label={t("internal-cost")}
                          />
                        </div>
                      ) : null}

                      {tab !== "area" ? (
                        <div className=" flex h-[32px] items-center px-2">
                          <CheckboxWithLabel
                            checked={columnsShown.bedCapacity}
                            onCheckedChange={(v) =>
                              setColumnsShown({
                                ...columnsShown,
                                bedCapacity: v as boolean,
                              })
                            }
                            label={t("bed-capacity")}
                          />
                        </div>
                      ) : null}

                      {tab === "area" ? (
                        <div className=" flex h-[32px] items-center px-2">
                          <CheckboxWithLabel
                            checked={columnsShown.guestCapacity}
                            onCheckedChange={(v) =>
                              setColumnsShown({
                                ...columnsShown,
                                guestCapacity: v as boolean,
                              })
                            }
                            label={t("guest-capacity")}
                          />
                        </div>
                      ) : null}

                      {showByCategory ? (
                        <>
                          <div className=" flex h-[32px] items-center px-2">
                            <CheckboxWithLabel
                              checked={columnsShown.internal}
                              onCheckedChange={(v) =>
                                setColumnsShown({
                                  ...columnsShown,
                                  internal: v as boolean,
                                })
                              }
                              label={t("internal")}
                            />
                          </div>

                          <div className=" flex h-[32px] items-center px-2">
                            <CheckboxWithLabel
                              checked={columnsShown.online}
                              onCheckedChange={(v) =>
                                setColumnsShown({
                                  ...columnsShown,
                                  online: v as boolean,
                                })
                              }
                              label={t("roomr-online")}
                            />
                          </div>

                          <div className=" flex h-[32px] items-center px-2">
                            <CheckboxWithLabel
                              checked={columnsShown.external}
                              onCheckedChange={(v) =>
                                setColumnsShown({
                                  ...columnsShown,
                                  external: v as boolean,
                                })
                              }
                              label={t("external")}
                            />
                          </div>
                        </>
                      ) : null}

                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                          <div className=" flex h-[32px] items-center ">
                            <CheckboxWithLabel
                              checked={columnsShown.location}
                              onCheckedChange={(v) =>
                                setColumnsShown({
                                  ...columnsShown,
                                  location: v as boolean,
                                })
                              }
                              label={t("location")}
                            />
                          </div>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent>
                            <SearchBar
                              placeholder={t("search-location")}
                              value={locationSearchTerm}
                              onChange={(e) =>
                                setLocationSearchTerm(e.target.value)
                              }
                              autoFocus
                            />
                            <DropdownMenuSeparator />
                            <p className=" mb-1 ml-1 text-xs font-bold text-secondary-text">
                              {t("filter-on")}
                            </p>
                            <div className=" mx-2">
                              {locations?.map((l) => (
                                <div
                                  key={l.id}
                                  className=" flex h-[32px] items-center px-2"
                                >
                                  <CheckboxWithLabel
                                    label={l.name}
                                    checked={
                                      !!includedLocations.find(
                                        (l1) => l.id === l1.id,
                                      )
                                    }
                                    onCheckedChange={(v) => {
                                      const newLocations =
                                        includedLocations.filter(
                                          (l1) => l1.id !== l.id,
                                        );
                                      if (v) {
                                        newLocations.push(l);
                                      }
                                      setIncludedLocations([...newLocations]);
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <DropdownMenuSeparator />
                            <DropdownMenuLabel
                              className={cn("cursor-pointer text-center", {
                                "cursor-auto text-status-disabled":
                                  includedLocations.length === 0,
                              })}
                              onClick={() => setIncludedLocations([])}
                            >
                              {t("remove-filter")}
                            </DropdownMenuLabel>
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>

                      {tab !== "combination" && (
                        <div className=" flex h-[32px] items-center px-2">
                          <CheckboxWithLabel
                            checked={columnsShown.combinations}
                            onCheckedChange={(v) =>
                              setColumnsShown({
                                ...columnsShown,
                                combinations: v as boolean,
                              })
                            }
                            label={t("combination")}
                          />
                        </div>
                      )}

                      {tab === "bed" && (
                        <div className=" flex h-[32px] items-center px-2">
                          <CheckboxWithLabel
                            checked={columnsShown.dormitory}
                            onCheckedChange={(v) =>
                              setColumnsShown({
                                ...columnsShown,
                                dormitory: v as boolean,
                              })
                            }
                            label={t("dormitory")}
                          />
                        </div>
                      )}
                      <DropdownMenuSeparator />
                      <DropdownMenuLabel
                        className=" cursor-pointer text-center"
                        onClick={() => {
                          setIncludedActiveStatus([
                            { key: "active", value: true },
                          ]);
                          setIncludedGroups([]);
                          setIncludedLocations([]);
                          setIncludedCategories([]);
                          setColumnsShown({
                            status: false,
                            name: true,
                            size: true,
                            categories: true,
                            internalCost: true,
                            bedCapacity: true,
                            guestCapacity: true,
                            internal: true,
                            online: true,
                            external: true,
                            location: true,
                            combinations: true,
                            dormitory: true,
                          });
                        }}
                      >
                        {t("reset")}
                      </DropdownMenuLabel>
                    </div>
                  </DropdownMenuContent>
                </DropdownMenuRoot>
              </div>
            </div>
            <div className="relative mt-4">
              {tab === "combination" &&
              (combinationsLoading || isRefetchingCombinations) ? (
                <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                  <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
                </div>
              ) : null}
              {tab !== "combination" && (isLoading || isRefetching) ? (
                <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                  <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
                </div>
              ) : null}
              {showByCategory || includedGroups.length ? (
                groups.map((g) => (
                  <BookableGroupTable
                    key={g.id}
                    columns={columns}
                    data={(g.items || []) as any}
                    groupName={g.name}
                    groupLabel={showByCategory ? t("category") : t("grouping")}
                  />
                ))
              ) : (
                <BookableTable columns={columns} data={assets} tab={tab} />
              )}
            </div>
          </div>
        </Card>
      </div>
      <CreateCombinationDialog
        type={tab}
        open={showCreateCombinationDialog}
        onOpenChange={setShowCreateCombinationDialog}
      />
      <ConfirmDialog
        proceedBtnText={t("yes")}
        title={t("do-you-really-want-to-deactivate?")}
        description={t(
          "if-you-deactivate-the-asset-will-no-longer-be-bookable-are-you-sure-you-want-to-deactivate",
        )}
        onOpenChange={setIsOpenDeactivateDialog}
        isOpen={isOpenDeactivateDialog}
        onProceed={() => toggleActive(activeAsset)}
      />
    </>
  );
};

interface BookableGroupTableProps {
  columns: ColumnDef<AssetOverview>[];
  data: AssetOverview[];
  groupName: string;
  groupLabel: string;
}

const BookableGroupTable: React.FC<BookableGroupTableProps> = ({
  columns,
  data,
  groupName,
  groupLabel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });
  return (
    <div className=" mt-4 border border-highlighted-backplate bg-primary-card-backplate">
      <div className=" flex h-[40px] w-full items-center bg-secondary-card-backplate pl-4">
        <p className=" text-sm font-extrabold">{`${groupLabel}: ${groupName}`}</p>
      </div>

      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className=" cursor-pointer text-primary-text"
                data-state={row.getIsSelected() && "selected"}
                onClick={() =>
                  navigate(
                    `/${module}/${ROUTES.BOOKABLES}/${assetTypeToRoute(row.original.types[0])}/${row.original.id}`,
                  )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 text-center text-primary-text"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

interface BookableTableProps {
  columns: ColumnDef<AssetOverview>[];
  data: AssetOverview[];
  tab: VisualAssetType;
}

const BookableTable: React.FC<BookableTableProps> = ({ columns, data }) => {
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });
  const { t } = useTranslation();

  return (
    <div className=" rounded-md border border-main-border-color bg-primary-card-backplate">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className=" cursor-pointer text-primary-text"
                data-state={row.getIsSelected() && "selected"}
                onClick={() => {
                  navigate(
                    `/${module}/${ROUTES.BOOKABLES}/${assetTypeToRoute(row.original.types[0])}/${row.original.id}`,
                  );
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 text-center text-primary-text"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AssetsPage;
