import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@shared/primitives/hover-card";
import { cn } from "@shared/utils/css";
import { SiteWithImage } from "../../../../api-contracts/sites";

interface Props {
  activeSite?: SiteWithImage;
  availableSites?: SiteWithImage[];
  onSiteSelected: (site: SiteWithImage) => void;
}

const shortString = (str: string) => {
  return str
    .split(" ")
    .map((s) => s[0].toUpperCase())
    .join("");
};

function SiteSelector({ activeSite, availableSites, onSiteSelected }: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-nowrap items-center space-x-2">
      <div
        className={cn(
          "invisible flex w-0 flex-nowrap items-center space-x-2 opacity-0 transition duration-700 ease-in-out",
          { "w-100 visible opacity-100": expanded },
        )}
      >
        {availableSites?.map((s) => (
          <div
            key={s.id}
            className="ml-2 flex flex-nowrap items-center space-x-2 transition duration-700 ease-in-out hover:-translate-y-1 hover:scale-110"
            onClick={() => onSiteSelected(s)}
          >
            <div
              className={cn(
                " flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-lg bg-cover bg-center	bg-no-repeat",
                {
                  "border-2 border-highlighted-backplate":
                    s.id === activeSite?.id,
                  "bg-primary-card-backplate": !s?.thumbnail?.url,
                },
              )}
              style={{
                backgroundImage: s?.thumbnail?.url
                  ? `url(${s?.thumbnail?.url})`
                  : "none",
              }}
            >
              {s?.thumbnail?.url ? null : (
                <p className=" text-sm font-semibold	text-primary-text">
                  {shortString(s.name)}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      {expanded ? null : (
        <HoverCard openDelay={0} onOpenChange={setOpen} open={open}>
          <HoverCardTrigger onClick={() => setOpen(true)}>
            <div className="flex flex-col items-center">
              <div
                className={cn(
                  " z-[1] flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-lg bg-cover bg-center	bg-no-repeat",
                  {
                    "bg-primary-card-backplate": !activeSite?.thumbnail?.url,
                  },
                )}
                style={{
                  backgroundImage: activeSite?.thumbnail?.url
                    ? `url(${activeSite?.thumbnail.url})`
                    : "none",
                }}
              >
                {activeSite?.thumbnail?.url ? null : (
                  <p className=" text-sm font-semibold text-primary-text">
                    {activeSite && shortString(activeSite?.name)}
                  </p>
                )}
              </div>
            </div>
          </HoverCardTrigger>
          <HoverCardContent
            className="bg-solid-backplate p-3"
            align="start"
            alignOffset={-10}
          >
            <div className="flex flex-col space-y-3">
              {availableSites?.map((s) => (
                <div
                  key={s.id}
                  className=" flex cursor-pointer flex-nowrap items-center space-x-2"
                  onClick={() => {
                    onSiteSelected(s);
                    setOpen(false);
                  }}
                >
                  <div className="flex flex-col items-center">
                    <div
                      className={cn(
                        " flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-lg bg-cover bg-center	bg-no-repeat transition duration-700 ease-in-out hover:-translate-y-1 hover:scale-110",
                        {
                          "border-2 border-solid border-highlighted-backplate":
                            s.id === activeSite?.id,
                          "bg-secondary-card-backplate": !s?.thumbnail?.url,
                        },
                      )}
                      style={{
                        backgroundImage: s?.thumbnail?.url
                          ? `url(${s?.thumbnail?.url})`
                          : "none",
                      }}
                    >
                      {s?.thumbnail?.url ? null : (
                        <p className=" text-sm font-semibold text-primary-text">
                          {shortString(s.name)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" flex-grow">
                    <p className=" font-itc text-sm font-bold text-primary-text">
                      {s.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </HoverCardContent>
        </HoverCard>
      )}

      {availableSites?.length && availableSites?.length > 1 ? (
        <div
          onClick={() => setExpanded(!expanded)}
          className="flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full bg-primary-button-backplate transition duration-700 ease-in-out hover:-translate-y-1 hover:scale-110 "
        >
          {expanded ? (
            <ChevronLeft
              fill={`var(--primary-button-backplate)`}
              size="16px"
              color={`var(--primary-icon-color)`}
            />
          ) : (
            <ChevronRight
              fill={`var(--primary-button-backplate)`}
              size="16px"
              color={`var(--primary-icon-color)`}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

export { SiteSelector };
