import { AlertDialog } from "@primitives/alert-dialog.tsx";
import { FC, useContext } from "react";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { useTranslation } from "react-i18next";
import { OutOfOrder } from "../../../../../api-contracts/out-of-order";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  outOfOrder: OutOfOrder;
}

export const DeleteOutOfOrderDialog: FC<Props> = ({
  open,
  onOpenChange,
  outOfOrder,
}) => {
  const { t } = useTranslation();
  const { deleteOOO } = useContext(OutOfOrderContext);
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>
            Är du säker på att du vill ta bort {outOfOrder.type} för{" "}
            {outOfOrder.asset.name} ?
          </AlertDialog.Title>
          <AlertDialog.Description>
            {outOfOrder.startDate} - {outOfOrder.endDate}
          </AlertDialog.Description>
        </AlertDialog.Header>

        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action onClick={() => deleteOOO(outOfOrder.id)}>
            {t("delete")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
