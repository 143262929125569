import { currentSite } from "@context/site-context.tsx";
import { createMutation, createQuery } from "react-query-kit";
import { api } from "@api/api.ts";
import {
  CheckOOOAvailabilityRequest,
  CheckOOOAvailabilityResponse,
  CreateOutOfOrderRequest,
  DeleteOutOfOrderResponse,
  EndOOOResponse,
  GetOOOAvailableAssetsResponse,
  GetOutOfOrdersResponse,
  OutOfOrder,
  PatchOutOfOrderRequest,
  PatchOutOfOrderResponse,
} from "../../../../api-contracts/out-of-order";
import {
  PatchBedTypeRequest,
  PatchBedTypeResponse,
} from "../../../../api-contracts/bed-capacity";
import { BED_CAPACITY, BED_TYPES_QUERY_KEY } from "@api/bed-types.ts";

export const OUT_OF_ORDER_QUERY_KEY = "out-of-orders";
export const OUT_OF_ORDER_AVAILABLE_ASSETS = "available-assets";

export const useGetOutOfOrders = createQuery<GetOutOfOrdersResponse>({
  queryFn: async () =>
    api.get(`/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}`),
  primaryKey: OUT_OF_ORDER_QUERY_KEY,
});

export const useGetAvailability = createQuery<
  GetOOOAvailableAssetsResponse,
  { from: string; to: string }
>({
  queryFn: async ({ queryKey: [_, { from, to }] }) =>
    api.get(
      `/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}/${OUT_OF_ORDER_AVAILABLE_ASSETS}?from=${from}&to=${to}`,
    ),
  primaryKey: OUT_OF_ORDER_QUERY_KEY,
});

export const useDeletetOutOfOrder = createMutation<
  DeleteOutOfOrderResponse,
  { id: string }
>({
  mutationFn: async ({ id }) =>
    api.delete(`/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}/${id}`),
});

export const usePostOOO = createMutation<
  GetOutOfOrdersResponse,
  CreateOutOfOrderRequest
>({
  mutationFn: async (ooo) =>
    api.post(`/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}`, ooo),
});

export const useCheckAvailability = createMutation<
  CheckOOOAvailabilityResponse,
  CheckOOOAvailabilityRequest
>({
  mutationFn: async (ooo) =>
    api.post(
      `/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}/availability`,
      ooo,
    ),
});

export const usePatchOOO = createMutation<
  PatchOutOfOrderResponse,
  { patch: PatchOutOfOrderRequest; id: string }
>({
  mutationFn: async (ooo) =>
    api.patch(
      `/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}/${ooo.id}`,
      ooo.patch,
    ),
});

export const useEndOOO = createMutation<EndOOOResponse, OutOfOrder>({
  mutationFn: async (ooo) =>
    api.post(
      `/api/${currentSite?.id}/${OUT_OF_ORDER_QUERY_KEY}/${ooo.id}/end`,
      ooo,
    ),
});
