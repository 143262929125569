import { createContext, useEffect, useState } from "react";
import { Button } from "@shared/primitives/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProfileContext } from "@api/profile-site-module-context";
import { Loading } from "@primitives/loading";
import {
  ProfileContextProvider,
  useProfileContext,
} from "@context/profile-context.tsx";
import { useAuth } from "@hooks/use-auth";
import { SiteWithImage } from "../../../../api-contracts/sites";

interface SiteContextProps {
  sites?: SiteWithImage[];
  activeSite?: SiteWithImage;
  setActiveSite: (site: SiteWithImage) => void;
}

export const SiteContext = createContext<SiteContextProps>(
  undefined as unknown as SiteContextProps,
);

export const ACTIVE_SITE_STORAGE_KEY = "activeSiteId";

// TODO: Replace in favor of activeSite
export let currentSite: SiteWithImage | undefined;

export const SiteProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sites, activeSite, setActiveSite } = useProfileContext();
  const { user } = useAuth();

  useEffect(() => {
    currentSite = activeSite;
  }, [activeSite]);

  // TODO: Make sure initial requests still work when currentSite is gone
  if (user?.type !== "admin" && (!sites || currentSite === undefined)) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (user?.type !== "admin" && !activeSite) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl">{t("no-site-configured-for-the-user")}</h1>
          <Button className="mt-4" onClick={() => navigate("/logout")}>
            Log out
          </Button>
        </div>
      </div>
    );
  }

  return (
    <SiteContext.Provider
      value={{
        sites,
        activeSite,
        setActiveSite,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
