import { Dot } from "lucide-react";
import React, { useRef, useState } from "react";

interface CustomFloatingLabelInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isSelected?: boolean;
  className?: string;
}

const CustomFloatingLabelInput: React.FC<CustomFloatingLabelInputProps> = ({
  label,
  value,
  onChange,
  disabled = false,
  isSelected = false,
  className = "",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div
      className={`relative flex h-[52px] items-center rounded-xl px-1 ${
        isSelected
          ? "bg-amber-500 font-medium text-quaternary-text"
          : "bg-secondary-card-backplate font-normal text-secondary-text"
      } ${disabled ? "cursor-not-allowed" : ""} ${className}`}
      onClick={() => inputRef.current?.focus()}
    >
      <label
        className={`pointer-events-none absolute start-2 text-sm transition-all ${
          value || isFocused
            ? "top-2 text-xs text-black"
            : "top-3.5 text-gray-400"
        }`}
      >
        {label}
      </label>
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`ml-1 mt-3 w-full bg-transparent text-sm outline-none`}
      />

      <div className=" flex h-[44px]  w-[52px]   items-center justify-center rounded-lg bg-highlighted-backplate">
        {isSelected && <Dot className="text-quaternary-text" />}
      </div>
    </div>
  );
};

export default CustomFloatingLabelInput;
