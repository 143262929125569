import { Dispatch, FC, ReactElement, SetStateAction } from "react";
import { Bedlinen } from "../../../../../../api-contracts/bed-capacity";
import { CreateEdit } from "@pages/settings/bed-types/components/standard-bed-sheet.tsx";
import { useTranslation } from "react-i18next";
import { useBedData } from "@pages/settings/bed-types/useBedData.tsx";
import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { Card } from "@primitives/card.tsx";
import { Input } from "@primitives/input.tsx";
import { Space } from "@components/space.tsx";
import { PlaceholderInput } from "@pages/settings/categories/components/placeholder-input.tsx";
import { Textarea } from "@primitives/textarea.tsx";
import { Button } from "@primitives/button.tsx";
import { SubmitHandler, useForm } from "react-hook-form";
import { GenericInputError } from "./generic-input-error";

interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  linenData: Bedlinen | undefined;
  type: CreateEdit;
  btnText: string;
}

export const BedLinenSheet: FC<Props> = ({
  open,
  onOpenChange,
  linenData,
  type,
  btnText,
}): ReactElement => {
  const emptyLinen: Bedlinen = {
    id: "",
    name: "",
    width: 0,
    length: 0,
    description: "",
  };
  const { t } = useTranslation();
  const { createLinen, updateLinen } = useBedData();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Bedlinen>({
    values: linenData ?? emptyLinen,
    shouldUseNativeValidation: true,
  });

  const onSubmit: SubmitHandler<Bedlinen> = async (linen) => {
    const linenToAdd = {
      name: linen.name,
      width: linen.width,
      length: linen.length,
      description: linen.description,
    };

    if (type === "create") {
      createLinen(linenToAdd).then(() => reset(emptyLinen));
    } else {
      updateLinen(linenToAdd, linen.id).then(() => reset(emptyLinen));
    }
    onOpenChange(!open);
  };

  return (
    <DefaultSideSheet
      title={type === "edit" ? t("edit") : t("create-linen")}
      open={open}
      onOpenChange={onOpenChange}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex h-full flex-col justify-between overflow-auto"}
      >
        <div>
          <Card className={"mt-0 bg-secondary-card-backplate"}>
            <div>
              <p className={"font-extrabold"}>{t("linen-information")}</p>
            </div>
            <div className={"mt-3"}>
              <div className={"flex"}>
                <p>{t("name")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Input
                id="name"
                {...register("name", {
                  required: true,
                })}
                className={
                  "font-neue text-[15px] font-normal text-secondary-text"
                }
                placeholder={t("name")}
              />
              {errors.name && errors.name.type === "required" && (
                <GenericInputError />
              )}
              <Space h={8} />
              <div className={"flex"}>
                <p>{t("width")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                id="width"
                defaultValue={"0"}
                placeholder={"cm"}
                type="number"
                min={"0"}
                {...register("width")}
              />
              <Space h={8} />
              <div className={" flex"}>
                <p>{t("length")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <PlaceholderInput
                id="length"
                defaultValue={"0"}
                placeholder={"cm"}
                type="number"
                min={"0"}
                {...register("length")}
              />
            </div>
            <Space h={20} />
            <div className={" flex"}>
              <p>{t("description")}</p>
              <p className={"pl-1.5 text-red-700"}>*</p>
            </div>
            <Textarea
              id="description"
              {...register("description", {
                required: true,
              })}
              placeholder={t("description")}
            />
            {errors.description && errors.description.type === "required" && (
              <GenericInputError />
            )}
          </Card>
        </div>
        <div className="flex justify-end">
          <Button disabled={!isDirty} className={"rounded-xl"} type="submit">
            {btnText}
          </Button>
        </div>
      </form>
    </DefaultSideSheet>
  );
};
