import { Search } from "@pages/out-of-order/components/search.tsx";
import { Button } from "@primitives/button.tsx";
import { Space } from "@components/space.tsx";
import React, { useState } from "react";
import { CreateOutOfOrderSheet } from "@pages/out-of-order/components/create-out-of-order-sheet.tsx";
import { Filtering } from "@pages/out-of-order/components/filtering.tsx";
import { useTranslation } from "react-i18next";

export const TopBar = () => {
  const [openSheet, setOpenSheet] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <CreateOutOfOrderSheet open={openSheet} onOpenChange={setOpenSheet} />
      <div className={"ml-4 mr-4 mt-6 flex items-center justify-between"}>
        <div>
          <p className={"mb-4 text-[30px] text-primary-text"}>
            {t("out-of-order")} & {t("out-of-service")}
          </p>
          <Search />
        </div>
        <div className={"flex flex-col"}>
          <Button
            onClick={() => setOpenSheet((prevState) => !prevState)}
            size={"md"}
          >
            {t("create-new")}
          </Button>
          <Space h={16} />
          <Filtering />
        </div>
      </div>
    </>
  );
};
