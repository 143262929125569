import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { FilterModel, SortModel } from "@shared/utils/query-string";
import { PaginatedResponse } from "@shared/types/common";
import {
  CreateUserRequest,
  CreateUserResponse,
  GetUserResponse,
  GetUsersResponse,
  PutUserRolesRequest,
  PutUserRolesResponse,
} from "../../../../api-contracts/users";
import { currentSite } from "@context/site-context.tsx";
import { queryClient } from "../../query-client.ts";

export const USER_QUERY_KEY = "/users";
export const SITE_USERS_QUERY_KEY = "sites/users";

interface User {
  id: string;
}

interface FindUsersQueryParams {
  offset?: number;
  limit?: number;
  sort?: SortModel[];
  filter?: FilterModel;
}

type FindUsersResponse = PaginatedResponse<"users", User>;

export const useGetUsers1 = createQuery<
  FindUsersResponse,
  FindUsersQueryParams
>({
  primaryKey: USER_QUERY_KEY,
  queryFn: ({ queryKey: [path, params] }) =>
    api.get<FindUsersResponse>(path, params as Record<string, string>),
  staleTime: 1000 * 60 * 5,
});

export const useGetUser = createQuery<
  GetUserResponse,
  { id: string; siteId?: string }
>({
  primaryKey: `${USER_QUERY_KEY}/id`,
  queryFn: async ({ queryKey: [, { id, siteId }] }) =>
    api.get(`/api/${siteId ?? currentSite?.id}${USER_QUERY_KEY}/${id}`),
});

export const useGetUsers = createQuery<GetUsersResponse, { siteId?: string }>({
  primaryKey: USER_QUERY_KEY,
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    api.get(`/api/${siteId}${USER_QUERY_KEY}`),
});

export const useCreateUser = createMutation<
  CreateUserResponse,
  CreateUserRequest & { siteId?: string }
>({
  mutationFn: async (req) => {
    const { siteId, ...rest } = req;
    return api.post(`/api/${siteId}/users`, rest);
  },
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] }),
});

export const useUpdateUserSiteRole = createMutation<
  PutUserRolesResponse,
  { body: PutUserRolesRequest; siteId: string; userId: string }
>({
  mutationFn: async (req) =>
    api.put(`/api/sites/${req.siteId}/users/${req.userId}/roles`, req.body),
  onSuccess: () =>
    queryClient.invalidateQueries({ queryKey: [SITE_USERS_QUERY_KEY] }),
});

/*export const useDeleteUser = createMutation<User, User>({
  mutationFn: async (user) => api.delete(`${USER_QUERY_KEY}/${user.id}`),
});*/
