import { NavigationArrows } from "@components/navigation-arrows";
import { useProfileContext } from "@context/profile-context";
import { Button } from "@primitives/button";
import { Card } from "@primitives/card";
import { Checkbox } from "@primitives/checkbox";
import { Input } from "@primitives/input";
import { ROUTES } from "@shared/types/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CommunicationChannel,
  CommunicationSphereVariable,
  GetDetailedCommunicationSphere,
  GroupedCommunicationVariable,
  TriggerType,
} from "../../../../../../api-contracts/communication-spheres";
import { useParams } from "react-router-dom";
import {
  useCopyDefaultCommunicationSphereContent,
  useDownloadAttachment,
  useGetCommunicationSphere,
  useGetCommunicationSpherePreview,
  useGetCommunicationSphereVariables,
  usePatchCommunicationSphere,
  useUploadAttachment,
} from "@api/communication-spheres";
import { queryClient } from "query-client";
import { useGetLanguageSettings } from "@api/language-settings";
import {
  AlertTriangle,
  ChevronDown,
  ChevronUp,
  Copy,
  Eye,
  FileText,
  Loader2,
  Mail,
  MessageSquare,
  Paperclip,
  Trash,
  X,
} from "lucide-react";
import { cn } from "@shared/utils/css";
import { Switch } from "@primitives/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import { ConfirmNavigationDialog } from "@components/confirm-navigation-dialog";
import { isEqual, range } from "lodash";
import { ConfirmDialog } from "@components/confirm-dialog";
import ReactQuill from "react-quill-new";
import "./quill.css"; // separate import of quill styles to supress Vite warning.
import { Collapsible } from "@primitives/collapsible";
import { useToast } from "@hooks/use-toast";
import { Dialog, DialogContent } from "@primitives/dialog";

type Unpacked<T> = T extends (infer U)[] ? U : T;

const initialSphere: GetDetailedCommunicationSphere = {
  id: "new",
  languages: [],
  triggers: [],
  name: "",
  status: {
    active: false,
    isDefault: false,
  },
};

const supportedCommunicationChannels: CommunicationChannel[] = ["email", "sms"];

const CommunicationSphereDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { module } = useProfileContext();
  const [sphere, setSphere] =
    useState<GetDetailedCommunicationSphere>(initialSphere);
  const [selectedTrigger, setSelectedTrigger] =
    useState<Unpacked<GetDetailedCommunicationSphere["triggers"]>>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState<boolean>(false);
  const [variableGroups, setVariableGroups] = useState<
    (GroupedCommunicationVariable & { open: boolean })[]
  >([]);
  const [currentSelection, setCurrectSelection] = useState<{
    range: ReactQuill.Range;
    type: "body" | "title";
    insertVariable: (
      range: ReactQuill.Range,
      type: "body" | "title",
      variable: Omit<CommunicationSphereVariable, "variableCategory">,
    ) => void;
  }>();
  const { toast } = useToast();

  const patchSphere = usePatchCommunicationSphere();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetCommunicationSphere.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetCommunicationSphereVariables.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetLanguageSettings.getKey(),
    });
  }, []);

  const {
    data: sphereData,
    isLoading: sphereLoading,
    isRefetching: sphereRefetching,
  } = useGetCommunicationSphere({
    variables: {
      id: id as string,
    },
    enabled: !!id,
  });

  const {
    data: languagesData,
    isLoading: languagesLoading,
    isRefetching: languagesRefetching,
  } = useGetLanguageSettings({});

  const {
    data: variablesData,
    isLoading: variablesLoading,
    isRefetching: variablesRefetching,
  } = useGetCommunicationSphereVariables({});

  useEffect(() => {
    setVariableGroups(variablesData?.map((g) => ({ ...g, open: false })) ?? []);
  }, [variablesData]);

  useEffect(() => {
    if (sphereData) {
      setSphere({ ...sphereData });
      setSelectedTrigger({ ...sphereData.triggers?.[0] });
    }
  }, [sphereData]);

  useEffect(() => {
    setSaveIsEnabled(!isEqual(sphere, sphereData));
  }, [sphere]);

  const onSave = () => {};

  const handleVariableClick = (
    variable: Omit<CommunicationSphereVariable, "variableCategory">,
  ) => {
    if (currentSelection && currentSelection.range) {
      currentSelection.insertVariable(
        currentSelection.range,
        currentSelection.type,
        variable,
      );
    }
  };

  const handleSave = async () => {
    try {
      setSaveLoading(true);
      await patchSphere.mutateAsync({
        id: sphere.id,
        name: sphere.name,
        languageCodes: sphere.languages.map((s) => s.code),
        triggers: sphere.triggers.map((t) => ({
          id: !isNaN(Number(t.id || "")) ? (t.id ?? undefined) : undefined,
          active: t.active,
          triggerTypeId: t.triggerTypeId,
          entries: t.entries.map((e) => ({
            type: e.type,
            active: e.active,
            content: e.content.map((c) => ({
              id: c.id ?? undefined,
              title: c.title?.trim() || null,
              body: c.body ? (c.body === "<p><br></p>" ? null : c.body) : null,
              languageCode: c.language.code,
              attachments: c.attachments,
            })),
          })),
        })),
        active: sphere.status.active,
        isDefault: sphere.status.isDefault,
      });

      toast({
        title: t("saved-succesfully", { name: sphere.name }),
        className: "text-status-success",
        variant: "success",
      });

      queryClient.invalidateQueries({
        queryKey: useGetCommunicationSphere.getKey(),
      });
      setIsEditing(false);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setSaveLoading(false);
  };

  return (
    <>
      <div className="p-4 text-primary-text">
        <NavigationArrows
          rootPath={`/${module}/${ROUTES.COMMUNICATION_SPHERES}`}
        />
        <Card className=" mt-4 flex max-w-full flex-nowrap items-center overflow-x-auto rounded-xl p-0">
          <div className=" w-[300px] min-w-[300px] max-w-[300px] pb-4 pl-4 pt-4">
            <Input
              value={sphere.name}
              onChange={(e) =>
                setSphere((sphere) => ({ ...sphere, name: e.target.value }))
              }
              placeholder={t("name")}
              disabled={!isEditing}
            />
            <div className=" mt-2 flex items-center space-x-2">
              <Checkbox
                id="standardSphere"
                disabled={!isEditing || sphereData?.status.isDefault}
                checked={sphere.status.isDefault}
                onCheckedChange={(checked) => {
                  if (checked) {
                    setDefaultDialogOpen(true);
                  } else {
                    setSphere((sphere) => ({
                      ...sphere,
                      status: {
                        ...sphere.status,
                        isDefault: false,
                      },
                    }));
                  }
                }}
              />
              <label
                className={cn(
                  " mt-1 cursor-pointer text-sm font-extrabold text-primary-text",
                  { " text-secondary-text": !isEditing },
                )}
                htmlFor="standardSphere"
              >
                {t("standard-sphere")}
              </label>
            </div>
          </div>
          <div className=" flex max-w-[calc(100%-300px)] flex-grow flex-nowrap justify-between p-4">
            <div className=" relative flex max-w-full flex-shrink flex-grow flex-nowrap space-x-2 overflow-x-auto">
              {(languagesLoading || languagesRefetching) && (
                <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                  <Loader2 className="h-12 w-12 animate-spin text-primary-color" />
                </div>
              )}
              {languagesData?.map((l) => (
                <div
                  key={l.code}
                  className={cn(" rounded-lg bg-secondary-card-backplate", {
                    " bg-amber-500-50": sphere.languages.find(
                      (l2) => l.code === l2.code,
                    ),
                  })}
                >
                  <div className="p-4 pb-2 pt-3 text-center text-sm font-extrabold">
                    {l.short}
                  </div>
                  <div className=" bg-primary-card-backplate px-2 py-1 pt-2">
                    <Switch
                      disabled={!isEditing}
                      className=" data-[state=checked]:bg-highlighted-backplate"
                      checked={
                        !!sphere.languages.find((l2) => l.code === l2.code)
                      }
                      onCheckedChange={(checked) => {
                        if (checked) {
                          const newTriggers = sphere.triggers.map((t) => ({
                            ...t,
                            entries: t.entries.map((e) => ({
                              ...e,
                              content: e.content.some(
                                (c) => c.language.code === l.code,
                              )
                                ? e.content.map((c) =>
                                    c.language.code === l.code
                                      ? { ...c, active: true }
                                      : c,
                                  )
                                : [
                                    ...e.content,
                                    {
                                      language: l,
                                      body: "",
                                      title: "",
                                      active: true,
                                      attachments: [],
                                    },
                                  ],
                            })),
                          }));
                          setSphere((sphere) => ({
                            ...sphere,
                            languages: [...sphere.languages, l],
                            triggers: newTriggers,
                          }));
                          setSelectedTrigger(
                            newTriggers.find(
                              (t) =>
                                t.triggerTypeId ===
                                selectedTrigger?.triggerTypeId,
                            ),
                          );
                        } else {
                          const newTriggers = sphere.triggers.map((t) => ({
                            ...t,
                            entries: t.entries.map((e) => ({
                              ...e,
                              content: e.content.some(
                                (c) =>
                                  c.language.code === l.code &&
                                  (!c.body || c.body === "<p><br></p>") &&
                                  e.type === "email" &&
                                  !c.title?.trim(),
                              )
                                ? e.content.filter(
                                    (c) => c.language.code !== l.code,
                                  )
                                : e.content.map((c) =>
                                    c.language.code === l.code
                                      ? { ...c, active: false }
                                      : c,
                                  ),
                            })),
                          }));
                          setSphere((sphere) => ({
                            ...sphere,
                            languages: sphere.languages.filter(
                              (l2) => l.code !== l2.code,
                            ),
                            triggers: newTriggers,
                          }));
                          setSelectedTrigger(
                            newTriggers.find(
                              (t) =>
                                t.triggerTypeId ===
                                selectedTrigger?.triggerTypeId,
                            ),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className=" ml-2 flex flex-nowrap items-center space-x-2">
              {isEditing && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditing(false);
                    setSphere(sphereData ?? initialSphere);
                    setSelectedTrigger(
                      sphereData?.triggers.find(
                        (t) =>
                          t.triggerTypeId === selectedTrigger?.triggerTypeId,
                      ),
                    );
                  }}
                >
                  {t("cancel")}
                </Button>
              )}
              {isEditing && (
                <Button onClick={handleSave} loading={saveLoading}>
                  {t("save")}
                </Button>
              )}
              {!isEditing && (
                <Button onClick={() => setIsEditing(true)}>{t("edit")}</Button>
              )}
            </div>
          </div>
        </Card>
        <div className=" mt-2 flex flex-nowrap">
          <Card className=" relative mr-2 min-w-[300px] rounded-xl p-0">
            {(sphereLoading || sphereRefetching) && (
              <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                <Loader2 className="h-12 w-12 animate-spin text-primary-color" />
              </div>
            )}
            {sphere.triggers.map((trig, i) => (
              <div
                key={trig.triggerTypeId}
                className={cn(
                  " flex h-[55px] cursor-pointer flex-nowrap items-center justify-between border-r-4 border-transparent p-3",
                  {
                    "border-amber-500 bg-amber-500-25":
                      selectedTrigger?.triggerTypeId === trig.triggerTypeId,
                    "rounded-t": i === 0,
                    "rounded-b": i === sphere.triggers.length - 1,
                  },
                )}
                onClick={() => setSelectedTrigger(trig)}
              >
                <p
                  className={cn(
                    " truncate text-sm font-normal text-secondary-text ",
                    {
                      " font-extrabold text-primary-text":
                        selectedTrigger?.triggerTypeId === trig.triggerTypeId,
                    },
                  )}
                >
                  {t(trig.triggerTypeId)}
                </p>
                <div className=" flex flex-nowrap items-center space-x-2">
                  <div className=" flex flex-nowrap items-center space-x-2 ">
                    {trig.active &&
                      trig.entries.find(
                        (e) => e.type === "email" && e.active,
                      ) && (
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger>
                            <Mail size={18} />
                          </TooltipTrigger>
                          <TooltipContent>{t("email")}</TooltipContent>
                        </Tooltip>
                      )}
                    {trig.active &&
                      trig.entries.find(
                        (e) => e.type === "sms" && e.active,
                      ) && (
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger>
                            <MessageSquare size={18} />
                          </TooltipTrigger>
                          <TooltipContent>{t("sms")}</TooltipContent>
                        </Tooltip>
                      )}
                  </div>
                  {trig.active &&
                    trig.entries.some((e) =>
                      e.content.some(
                        (c) =>
                          e.active &&
                          (!c.body ||
                            c.body === "<p><br></p>" ||
                            (e.type === "email" && !c.title?.trim())),
                      ),
                    ) && (
                      <div className=" rounded-lg bg-status-warning-100 p-2 ">
                        <AlertTriangle
                          className=" text-status-warning"
                          size={14}
                        />
                      </div>
                    )}
                  <Switch
                    disabled={!isEditing}
                    checked={trig.active}
                    onCheckedChange={(checked) => {
                      trig.active = checked;
                      setSphere({ ...sphere });
                    }}
                  />
                </div>
              </div>
            ))}
          </Card>

          <Card className=" flex-grow rounded-xl">
            {selectedTrigger && (
              <>
                <h2 className=" text-lg font-extrabold">
                  {t(selectedTrigger.name)}
                </h2>
                <div className=" flex flex-nowrap">
                  <div className=" relative flex-grow">
                    {(sphereLoading || sphereRefetching) && (
                      <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                        <Loader2 className="h-12 w-12 animate-spin text-primary-color" />
                      </div>
                    )}
                    {supportedCommunicationChannels.map((com, i) => {
                      const entry = selectedTrigger.entries.find(
                        (e) => e.type === com,
                      );
                      let enabledAbove = false;
                      for (let j = i - 1; j >= 0; j--) {
                        const entryAbove = selectedTrigger.entries.find(
                          (e) =>
                            e.type === supportedCommunicationChannels[j] &&
                            e.active,
                        );
                        if (entryAbove) {
                          enabledAbove = true;
                          break;
                        }
                      }
                      if (entry) {
                        return (
                          <TriggerEntry
                            triggerTypeId={selectedTrigger.triggerTypeId}
                            key={com + "-" + selectedTrigger.triggerTypeId}
                            entry={entry}
                            isEditing={isEditing}
                            index={i}
                            firstEnabled={!enabledAbove}
                            onChange={(entry) => {
                              let newTrigger;
                              if (entry) {
                                newTrigger = {
                                  ...selectedTrigger,
                                  entries: selectedTrigger.entries.map((e) =>
                                    e.type === com ? entry : e,
                                  ),
                                };
                              } else {
                                newTrigger = {
                                  ...selectedTrigger,
                                  entries: selectedTrigger.entries.filter(
                                    (e) => e.type !== com,
                                  ),
                                };
                              }

                              const newTriggers = sphere.triggers.map((t) =>
                                t.triggerTypeId ===
                                selectedTrigger.triggerTypeId
                                  ? newTrigger
                                  : t,
                              );
                              setSphere({
                                ...sphere,
                                triggers: newTriggers,
                              });
                              setSelectedTrigger(newTrigger);
                            }}
                            onCopyAbove={(languageCode: string) => {
                              for (let j = i - 1; j >= 0; j--) {
                                const entryAbove = selectedTrigger.entries.find(
                                  (e) =>
                                    e.type ===
                                    supportedCommunicationChannels[j],
                                );
                                if (entryAbove) {
                                  const content = entryAbove.content.find(
                                    (c) => c.language.code === languageCode,
                                  );
                                  let newTrigger = {
                                    ...selectedTrigger,
                                    entries: selectedTrigger.entries.map((e) =>
                                      e.type === com
                                        ? {
                                            ...entry,
                                            content: entry.content.map((c) =>
                                              c.language.code === languageCode
                                                ? { ...c, ...content }
                                                : c,
                                            ),
                                          }
                                        : e,
                                    ),
                                  };

                                  const newTriggers = sphere.triggers.map(
                                    (t) =>
                                      t.triggerTypeId ===
                                      selectedTrigger.triggerTypeId
                                        ? newTrigger
                                        : t,
                                  );
                                  setSphere({
                                    ...sphere,
                                    triggers: newTriggers,
                                  });
                                  setSelectedTrigger(newTrigger);
                                  break;
                                }
                              }
                            }}
                            onCurrentSelectionChange={(
                              range,
                              type,
                              insertVariable,
                            ) => {
                              setCurrectSelection({
                                range,
                                type,
                                insertVariable,
                              });
                            }}
                          />
                        );
                      } else {
                        return (
                          <div
                            key={com}
                            className={cn(
                              " rounded-xl bg-secondary-card-backplate p-4",
                              {
                                "mb-2":
                                  i < supportedCommunicationChannels.length - 1,
                              },
                            )}
                          >
                            <div className=" flex flex-nowrap items-center space-x-2">
                              <Checkbox
                                id={com}
                                disabled={!isEditing}
                                checked={false}
                                onCheckedChange={() => {
                                  selectedTrigger.entries.push({
                                    type: com,
                                    active: true,
                                    content: sphere.languages.map((l) => ({
                                      language: l,
                                      body: "",
                                      title: "",
                                      active: true,
                                      attachments: [],
                                    })),
                                  });
                                  setSphere({
                                    ...sphere,
                                    triggers: sphere.triggers.map((t) =>
                                      t.triggerTypeId ===
                                      selectedTrigger.triggerTypeId
                                        ? selectedTrigger
                                        : t,
                                    ),
                                  });
                                  setSelectedTrigger({ ...selectedTrigger });
                                }}
                              />
                              <label
                                className={cn(
                                  " mt-1 cursor-pointer text-sm font-extrabold text-primary-text",
                                  { " text-secondary-text": !isEditing },
                                )}
                                htmlFor={com}
                              >
                                {t(com)}
                              </label>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className=" relative ml-2 min-w-[300px] rounded-xl bg-secondary-card-backplate p-4">
                    {(variablesLoading || variablesRefetching) && (
                      <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                        <Loader2 className="h-12 w-12 animate-spin text-primary-color" />
                      </div>
                    )}
                    <h3 className=" text-sm font-extrabold ">
                      {t("variables")}
                    </h3>
                    <div className=" flex flex-col space-y-2">
                      {variableGroups.map((g, i) => (
                        <Collapsible.Root
                          className=" w-full rounded-lg bg-primary-card-backplate"
                          key={g.variableCategory}
                          open={g.open}
                          onOpenChange={(open) => {
                            variableGroups[i].open = open;
                            setVariableGroups([...variableGroups]);
                          }}
                        >
                          <Collapsible.Trigger className=" w-full">
                            <div className=" flex items-center justify-between p-4">
                              <p className=" text-sm font-extrabold">
                                {t(g.variableCategory)}
                              </p>
                              <div className="rounded-lg bg-primary-button-backplate p-2">
                                {g.open ? (
                                  <ChevronUp size={12} />
                                ) : (
                                  <ChevronDown size={12} />
                                )}
                              </div>
                            </div>
                          </Collapsible.Trigger>
                          <Collapsible.Content className=" w-full">
                            <div className=" flex flex-col space-y-2 px-2 pb-2">
                              {g.variables?.map((v) => (
                                <div
                                  role="button"
                                  key={v.id}
                                  className=" w-fit rounded bg-primary-button-backplate p-1 text-xs font-extrabold"
                                  onClick={() => handleVariableClick(v)}
                                >
                                  {t(v.name.slice(1, -1).split(" ").join("-"))}
                                </div>
                              ))}
                            </div>
                          </Collapsible.Content>
                        </Collapsible.Root>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
      <ConfirmNavigationDialog
        title={t("changes-not-saved")}
        description={t("you-have-unsaved-changes-that-will-be-lost")}
        shouldWarn={saveIsEnabled}
        onProceed={onSave}
      />
      <ConfirmDialog
        proceedBtnText={t("change-default-communication-sphere")}
        title={t("change-default-communication-sphere")}
        description={t(
          "are-you-sure-you-want-to-change-the-default-communication-sphere?",
        )}
        onOpenChange={setDefaultDialogOpen}
        isOpen={defaultDialogOpen}
        onProceed={() => {
          setSphere((sphere) => ({
            ...sphere,
            status: {
              ...sphere.status,
              isDefault: true,
            },
          }));
        }}
      />
    </>
  );
};

const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
};

const editorDisabledModules = {
  toolbar: false,
};

const editorFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

interface TriggerEntryProps {
  entry: Unpacked<
    Unpacked<GetDetailedCommunicationSphere["triggers"]>["entries"]
  >;
  isEditing: boolean;
  index: number;
  onChange: (
    entry?: Unpacked<
      Unpacked<GetDetailedCommunicationSphere["triggers"]>["entries"]
    >,
  ) => void;
  firstEnabled: boolean;
  onCopyAbove: (languageCode: string) => void;
  onCurrentSelectionChange: (
    range: ReactQuill.Range,
    type: "body" | "title",
    insertVariable: (
      range: ReactQuill.Range,
      type: "body" | "title",
      variable: Omit<CommunicationSphereVariable, "variableCategory">,
    ) => void,
  ) => void;
  triggerTypeId: TriggerType;
}

const TriggerEntry = ({
  entry,
  isEditing,
  index,
  onChange,
  firstEnabled,
  onCopyAbove,
  onCurrentSelectionChange,
  triggerTypeId,
}: TriggerEntryProps) => {
  const { t } = useTranslation();
  const [selectedContent, setSelectedContent] = useState<
    Unpacked<TriggerEntryProps["entry"]["content"] | undefined>
  >(entry.content[0]);
  const titleRef = useRef<HTMLInputElement>(null);
  const [copyLoading, setCopyLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [titleCursor, setTitleCursor] = useState<number | null>(null);
  const [savedRange, setSavedRange] = useState<{
    range: ReactQuill.Range;
    type: "title" | "body";
  }>();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();

  const copyDefault = useCopyDefaultCommunicationSphereContent();
  const uploadFile = useUploadAttachment();
  const downloadFile = useDownloadAttachment();

  const {
    data: preview,
    isLoading: previewLoading,
    isRefetching: previewRefetching,
  } = useGetCommunicationSpherePreview({
    variables: {
      channel: entry.type,
      body: selectedContent?.body ?? "",
      language: selectedContent?.language.code ?? "en-GB",
      title: selectedContent?.title ?? undefined,
    },
    enabled: previewOpen,
  });

  useEffect(() => {
    const content = entry.content.find(
      (c) => selectedContent?.language.code === c.language.code,
    );
    if (!content) {
      setSelectedContent(entry.content[0]);
    } else {
      setSelectedContent(content);
    }
  }, [entry]);

  useEffect(() => {
    titleRef.current?.setSelectionRange(titleCursor, titleCursor);
  }, [titleRef, titleCursor, selectedContent]);

  const handleInsertVariable = useCallback(
    (
      range: ReactQuill.Range,
      type: "title" | "body",
      variable: Omit<CommunicationSphereVariable, "variableCategory">,
    ) => {
      if (type === "body") {
        if (entry.active && range && variable) {
          const editor = quillRef.current?.getEditor();
          if (editor) {
            editor.insertText(range.index, variable.id);
            editor.setSelection(range.index + variable.id.length);
            editor.focus();
          }
        }
      } else {
        if (titleRef.current && range) {
          const title =
            titleRef.current.value.substring(0, range.index) +
            variable.id +
            titleRef.current.value.substring(range.index);
          titleRef.current.value = title;
          const focusIndex = range.index + variable.id.length;
          titleRef.current.setSelectionRange(focusIndex, focusIndex);
          titleRef.current.focus();
          onChange({
            ...entry,
            content: entry.content.map((c) =>
              c.language.code === selectedContent?.language.code
                ? { ...c, title: title }
                : c,
            ),
          });
          setSavedRange({
            range: {
              index: focusIndex,
              length: 0,
            },
            type: "title",
          });
          setTitleCursor(focusIndex);
        }
      }
    },
    [entry.active],
  );

  useEffect(() => {
    if (savedRange) {
      onCurrentSelectionChange(
        savedRange.range,
        savedRange.type,
        handleInsertVariable,
      );
    }
  }, [savedRange, handleInsertVariable]);

  const handleCopyDefault = async () => {
    setCopyLoading(true);
    try {
      const content = await copyDefault.mutateAsync({
        triggerTypeId,
        type: entry.type,
        languageCode: selectedContent!.language.code,
      });

      const editor = quillRef.current?.getEditor();
      const root = quillRef.current?.editor?.root;
      if (editor && root) {
        root.innerHTML = content.body ?? "";
        editor.setSelection(0);
        editor.focus();
      }

      setTimeout(() => {
        onChange({
          ...entry,
          content: entry.content.map((c) =>
            c.language.code === selectedContent?.language.code
              ? { ...c, title: content.title, body: content.body }
              : c,
          ),
        });
        setTitleCursor(content.body?.length ? content.body.length : 0);
      }, 1);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCopyLoading(false);
  };

  const handleAddAttachment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!uploadRef || !uploadRef.current) return;

    uploadRef.current.click();
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setUploadLoading(true);

    const files: File[] = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i); // or files[i]
      if (file) {
        files.push(file);
      }
    }

    try {
      const responses = await Promise.all(
        files.map((f) =>
          uploadFile.mutateAsync({
            file: f,
          }),
        ),
      );

      onChange({
        ...entry,
        content: entry.content.map((c, i) =>
          selectedContent?.language.code === c.language.code
            ? {
                ...c,
                attachments: [
                  ...c.attachments,
                  ...responses.map((r) => ({ id: r.id, name: files[i].name })),
                ],
              }
            : c,
        ),
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
    setUploadLoading(false);
  };

  const downloadAttachment = async (id: string, name: string) => {
    setDownloadLoading(true);
    try {
      const data = await downloadFile.mutateAsync({
        id,
      });

      const tempLink = document.createElement("a");
      tempLink.href = data.url;
      tempLink.setAttribute("download", name);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(data.url);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
    setDownloadLoading(false);
  };

  return (
    <div
      className={cn(" rounded-xl bg-secondary-card-backplate p-4", {
        "mb-2": index < supportedCommunicationChannels.length - 1,
      })}
    >
      <div className=" flex flex-nowrap items-center space-x-2 text-secondary-text">
        <Checkbox
          id={entry.type}
          disabled={!isEditing}
          checked={entry.active}
          onCheckedChange={(checked) => {
            if (
              entry.content.some(
                (c) => (c.body && c.body !== "<p><br></p>") || c.title?.trim(),
              )
            ) {
              onChange({ ...entry, active: !!checked });
            } else {
              onChange();
            }
          }}
        />
        <label
          className={cn(
            " mt-1 cursor-pointer text-sm font-extrabold text-primary-text",
            { " text-secondary-text": !isEditing },
          )}
          htmlFor={entry.type}
        >
          {t(entry.type)}
        </label>
      </div>
      {selectedContent && (
        <>
          <div className=" mt-2 flex items-center justify-between">
            <div className=" flex flex-nowrap space-x-2 rounded-md bg-primary-card-backplate p-1">
              <div className=" flex flex-nowrap space-x-2 ">
                {entry.content.map((c) => (
                  <div
                    key={c.language.code}
                    className={cn(
                      " flex cursor-pointer flex-nowrap space-x-2 bg-secondary-card-backplate p-2 text-sm font-normal",
                      {
                        " bg-status-warning-100 text-status-warning":
                          !c.body ||
                          c.body === "<p><br></p>" ||
                          (entry.type === "email" && !c.title?.trim()),
                        " font-extrabold":
                          selectedContent.language.code === c.language.code,
                      },
                    )}
                    onClick={() => setSelectedContent(c)}
                  >
                    <p> {c.language.short}</p>
                    {(!c.body ||
                      c.body === "<p><br></p>" ||
                      (entry.type === "email" && !c.title?.trim())) && (
                      <AlertTriangle size={16} />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=" flex flex-nowrap space-x-2 ">
              <Button
                variant="secondary"
                size="sm"
                className=" text-secondary-text"
                disabled={!isEditing || !entry.active}
                loading={copyLoading}
                onClick={handleCopyDefault}
              >
                {t("insert-standard-text")}
              </Button>
              {!firstEnabled && (
                <Button
                  variant="secondary"
                  size="sm"
                  className=" flex flex-nowrap items-center space-x-2 text-secondary-text "
                  disabled={!isEditing || !entry.active}
                  onClick={() => onCopyAbove(selectedContent.language.code)}
                >
                  <Copy size={12} />
                  <p>{t("copy-above")}</p>
                </Button>
              )}
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button
                    variant="secondary"
                    size="sm"
                    className=" text-secondary-text"
                    disabled={!isEditing || !entry.active}
                    onClick={() =>
                      onChange({
                        ...entry,
                        content: entry.content.map((c) =>
                          c.language.code === selectedContent.language.code
                            ? { ...c, body: "", title: "" }
                            : c,
                        ),
                      })
                    }
                  >
                    <Trash size={12} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t("clear-text")}</TooltipContent>
              </Tooltip>
              <Button
                variant="secondary"
                size="sm"
                className=" text-secondary-text"
                disabled={!isEditing || !entry.active}
                loading={uploadLoading}
                onClick={handleAddAttachment}
              >
                <Paperclip size={12} />
              </Button>
              <input
                ref={uploadRef}
                type="file"
                multiple
                hidden
                onChange={handleFileUpload}
              />
              <Button
                variant="secondary"
                size="sm"
                className=" text-secondary-text"
                onClick={() => setPreviewOpen(true)}
              >
                <Eye size={12} />
              </Button>
            </div>
          </div>
          <div className=" mt-2">
            {entry.type === "email" && (
              <Input
                key={selectedContent.language.code}
                ref={titleRef}
                disabled={!isEditing || !entry.active}
                placeholder={t("title")}
                value={selectedContent.title ?? undefined}
                onSelect={(e: any) => {
                  if (
                    e.target.selectionStart !== null &&
                    e.target.selectionEnd !== null
                  ) {
                    setSavedRange({
                      range: {
                        index: e.target.selectionStart,
                        length: e.target.selectionEnd - e.target.selectionStart,
                      },
                      type: "title",
                    });
                  }
                }}
                onChange={(e) => {
                  setTitleCursor(e.target.selectionStart);
                  onChange({
                    ...entry,
                    content: entry.content.map((c) =>
                      c.language.code === selectedContent.language.code
                        ? { ...c, title: e.target.value }
                        : c,
                    ),
                  });
                }}
              />
            )}
            <ReactQuill
              ref={quillRef}
              modules={
                !isEditing || !entry.active || entry.type === "sms"
                  ? editorDisabledModules
                  : editorModules
              }
              formats={entry.type === "sms" ? [] : editorFormats}
              readOnly={!isEditing || !entry.active}
              key={
                triggerTypeId +
                "-" +
                selectedContent.language.code +
                "-" +
                String(!isEditing || !entry.active)
              }
              theme="snow"
              className={cn(" mt-2 bg-primary-button-backplate", {
                "pointer-events-none cursor-not-allowed opacity-60 ":
                  !isEditing || !entry.active,
              })}
              placeholder={t("text")}
              defaultValue={selectedContent.body ?? undefined}
              onChangeSelection={(range, source, editor) => {
                if (range) {
                  setSavedRange({ range, type: "body" });
                }
              }}
              onChange={(e) => {
                onChange({
                  ...entry,
                  content: entry.content.map((c) =>
                    c.language.code === selectedContent.language.code
                      ? { ...c, body: e }
                      : c,
                  ),
                });
              }}
            />
          </div>
          {selectedContent.attachments.length > 0 && (
            <div className=" flex flex-wrap bg-secondary-card-backplate p-4 pt-2">
              {selectedContent.attachments.map((a) => (
                <Button
                  variant="secondary"
                  className=" mr-2 mt-2 flex flex-nowrap space-x-1"
                  size="sm"
                  key={a.id}
                  loading={downloadLoading}
                  onClick={() => {
                    downloadAttachment(a.id, a.name);
                  }}
                >
                  <FileText size={14} />
                  <p>{a.name}</p>
                  <X
                    size={14}
                    onClick={(e) => {
                      e.preventDefault();
                      onChange({
                        ...entry,
                        content: entry.content.map((c) =>
                          selectedContent?.language.code === c.language.code
                            ? {
                                ...c,
                                attachments: c.attachments.filter(
                                  (a1) => a1 !== a,
                                ),
                              }
                            : c,
                        ),
                      });
                    }}
                  />
                </Button>
              ))}
            </div>
          )}
        </>
      )}
      <Dialog open={previewOpen} onOpenChange={setPreviewOpen}>
        <DialogContent className=" max-w-screen h-screen max-h-screen  w-screen sm:h-4/5 sm:w-3/4  ">
          <div className=" relative h-full max-h-screen overflow-auto">
            {(previewLoading || previewRefetching) && (
              <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                <Loader2 className="h-12 w-12 animate-spin text-primary-color" />
              </div>
            )}
            {preview?.title && (
              <div
                className=" mb-1 border-b border-border-color pb-1 text-xl font-medium"
                dangerouslySetInnerHTML={{ __html: preview.title }}
              />
            )}
            {preview?.body && (
              <div
                className="ql-editor ql-snow whitespace-normal"
                dangerouslySetInnerHTML={{ __html: preview.body }}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CommunicationSphereDetailsPage;
