import { Column } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Organization } from "../../../../../api-contracts/customer-list/organization";
import { Button } from "@primitives/button";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { Filter } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { useEffect } from "react";

export const ColumnVisibilityToggle = ({
  columns,
}: {
  columns: Column<Organization, unknown>[];
}) => {
  const { t } = useTranslation();

  const addressGroup = columns.filter(
    (col) =>
      col?.parent?.id === "address" && col.columnDef.enableHiding !== false,
  );
  const invoiceAddressGroup = columns.filter(
    (col) =>
      col?.parent?.id === "invoiceAddress" &&
      col.columnDef.enableHiding !== false,
  );

  const nonAddressGroup = columns.filter(
    (col) =>
      col?.parent?.id !== "address" &&
      col?.parent?.id !== "invoiceAddress" &&
      col.columnDef.enableHiding !== false,
  );

  const isAddressGroupChecked = addressGroup.some((col) => col.getIsVisible());
  const isInvoiceAddressGroupChecked = invoiceAddressGroup.some((col) =>
    col.getIsVisible(),
  );

  const toggleAddressGroup = (checked: boolean) => {
    addressGroup.forEach((col) => col.toggleVisibility(checked));
  };
  const toggleInvoiceAddressGroup = (checked: boolean) => {
    invoiceAddressGroup.forEach((col) => col.toggleVisibility(checked));
  };

  useEffect(() => {
    columns.forEach((col) => {
      const customMeta = col.columnDef.meta;
      if (customMeta?.initialVisibility !== undefined) {
        col.toggleVisibility(customMeta.initialVisibility);
      }
    });
  }, []);

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Button className=" flex" variant={"secondary"}>
          <Filter size={17} className="mr-1" />
          {t("show")}
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="max-h-[60vh] w-[250px] overflow-auto">
        <DropdownMenuLabel>{t("columns")}</DropdownMenuLabel>
        {nonAddressGroup.map((col) => {
          const label = col?.columnDef?.header?.toString();

          return (
            <div key={col.id} className="mx-2 flex h-[32px] items-center">
              <CheckboxWithLabel
                key={col.id}
                label={label}
                checked={col.getIsVisible()}
                onCheckedChange={() => {
                  col.toggleVisibility();
                }}
              />
            </div>
          );
        })}
        <Accordion type="multiple">
          <AccordionItem value="address" className="border-none">
            <AccordionTrigger className="m-0 p-0">
              <div className="mx-2 flex h-[32px] items-center">
                <CheckboxWithLabel
                  label={t("address")}
                  checked={isAddressGroupChecked}
                  onCheckedChange={(checked) =>
                    toggleAddressGroup(checked as boolean)
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {addressGroup.map((col) => {
                const label = col?.columnDef?.header?.toString();

                return (
                  <div key={col.id} className="mx-2 flex h-[32px] items-center">
                    <CheckboxWithLabel
                      key={col.id}
                      label={label}
                      checked={col.getIsVisible()}
                      onCheckedChange={() => col.toggleVisibility()}
                    />
                  </div>
                );
              })}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="invoiceAddress" className="border-none">
            <AccordionTrigger className="m-0 p-0">
              <div className="mx-2 flex h-[32px] items-center">
                <CheckboxWithLabel
                  label={t("invoice-address")}
                  checked={isInvoiceAddressGroupChecked}
                  onCheckedChange={(checked) =>
                    toggleInvoiceAddressGroup(checked as boolean)
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {invoiceAddressGroup.map((col) => {
                const label = col?.columnDef?.header?.toString();
                return (
                  <div key={col.id} className="mx-2 flex h-[32px] items-center">
                    <CheckboxWithLabel
                      key={col.id}
                      label={label}
                      defaultChecked={col.columnDef.meta?.initialVisibility}
                      checked={col.getIsVisible()}
                      onCheckedChange={() => col.toggleVisibility()}
                    />
                  </div>
                );
              })}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
