import { InputWithLabel } from "@pages/simple-search/components/filter-side-sheet/input-with-label";
import { Button } from "@primitives/button";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { SwitchWithLabel } from "@primitives/switch-with-label";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CreateOrganizationRequest,
  Organization,
  PatchOrganizationRequest,
} from "../../../../../api-contracts/customer-list/organization";
import {
  useCreateOrganization,
  useUpdateOrganization,
} from "@api/customer-list/organizations";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";

export const CreateOrganizationSheet = ({
  open,
  onOpenChange,
  handleInputChange,
  organization,
  isEditMode,
  clearSelected,
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  handleInputChange: (
    field: keyof CreateOrganizationRequest,
    value: string | null,
  ) => void;
  organization: CreateOrganizationRequest;
  isEditMode: boolean;
  clearSelected: () => void;
}) => {
  const [isBillingSameAsAddress, setIsBillingSameAsAddress] =
    useState<boolean>(true);

  const { t } = useTranslation();

  const { mutateAsync: createOrganization } = useCreateOrganization();
  const { mutateAsync: updateOrganization } = useUpdateOrganization();

  // TODO - Move to a utils/helper file (used in merge file aswell in another PR)
  const getValueOrNull = (value: any) => {
    return value === undefined || value === "" ? null : value;
  };

  const isCreateBtnDisabled = useMemo(() => {
    const organizationNumberValid = !!getValueOrNull(
      organization?.organizationNumber,
    );
    const isTravelAgency = organization?.type === "travel-agency";
    const IATAValid = organization.IATA?.length === 7;

    return !organizationNumberValid || (isTravelAgency && !IATAValid);
  }, [organization]);

  const handleSubmit = async () => {
    // Setting empty strings and undefined values to null
    const orgWithNullValues = Object.entries(organization).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: getValueOrNull(value),
      }),
      {} as CreateOrganizationRequest | PatchOrganizationRequest,
    );

    try {
      if (isEditMode) {
        const { id, type, ...updates } =
          orgWithNullValues as PatchOrganizationRequest;
        await updateOrganization({ id, updates });
        toast({
          title: t("organization-updated-successfully", {
            name: orgWithNullValues?.name ?? "company",
          }),
          variant: "success",
        });
        clearSelected();
      } else {
        const { id, ...createPayload } = orgWithNullValues;
        await createOrganization(createPayload);
        toast({
          title: t("new-organization-succesfully", {
            name: orgWithNullValues.name ?? "company",
          }),
          variant: "success",
        });
      }

      queryClient.invalidateQueries({
        queryKey: isEditMode
          ? useUpdateOrganization.getKey()
          : useCreateOrganization.getKey(),
      });

      onOpenChange(false);
    } catch (error) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((error as any)?.message || t("no-message"))),
      });
    }
  };

  const title = isEditMode ? t("edit-company") : t("create-new-company");
  const buttonText = isEditMode ? t("save-changes") : t("create");

  return (
    <DefaultSideSheet
      title={title}
      open={open}
      onOpenChange={onOpenChange}
      className="flex h-full flex-col"
    >
      <div className="w-full flex-grow justify-start overflow-auto text-primary-text">
        <OrganizationInformation
          handleInputChange={handleInputChange}
          organization={organization}
        />
        <div className="py-2.5" />
        <AddressInformation
          handleInputChange={handleInputChange}
          organization={organization}
        />
        <div className="py-2.5" />
        <InvoiceAddressInformation
          isBillingSameAsAddress={isBillingSameAsAddress}
          setIsBillingSameAsAddress={setIsBillingSameAsAddress}
          handleInputChange={handleInputChange}
          organization={organization}
        />
      </div>
      <div className="flex justify-end">
        <Button disabled={isCreateBtnDisabled} onClick={() => handleSubmit()}>
          {buttonText}
        </Button>
      </div>
    </DefaultSideSheet>
  );
};

const OrganizationInformation = ({
  handleInputChange,
  organization,
}: {
  handleInputChange: (
    field: keyof CreateOrganizationRequest,
    value: string | null,
  ) => void;
  organization: Partial<Organization>;
}) => {
  const { t } = useTranslation();

  const handleSwitchChange = (checked: boolean) => {
    handleInputChange("type", checked ? "travel-agency" : "organization");
  };

  useEffect(() => {
    if (organization.type === "organization") {
      handleInputChange("IATA", null);
    }
  }, [organization.type]);

  return (
    <div className="flex flex-col rounded-lg bg-secondary-card-backplate p-4 text-primary-text">
      <p className="text-sm font-bold">{t("company-data")}</p>
      <div className="flex flex-col">
        <div className="flex pt-4">
          <SwitchWithLabel
            checked={organization.type === "travel-agency"}
            onCheckedChange={(checked) =>
              handleSwitchChange(checked as boolean)
            }
            label={t("travel-agency")}
          />
        </div>
        {organization.type === "travel-agency" && (
          <InputWithLabel
            label={t("iata-number")}
            onChange={(e) => handleInputChange("IATA", e.target.value)}
            value={organization.IATA ?? ""}
          />
        )}
        <InputWithLabel
          label={t("organization-number")}
          onChange={(e) =>
            handleInputChange("organizationNumber", e.target.value)
          }
          value={organization.organizationNumber ?? ""}
        />

        <InputWithLabel
          label={t("company-name")}
          onChange={(e) => handleInputChange("name", e.target.value)}
          value={organization.name ?? ""}
        />

        <InputWithLabel
          label={t("email")}
          onChange={(e) => handleInputChange("email", e.target.value)}
          value={organization.email ?? ""}
        />

        <InputWithLabel
          label={t("phone")}
          onChange={(e) => handleInputChange("phone", e.target.value)}
          value={organization.phone ?? ""}
        />

        <InputWithLabel
          label={`${t("phone")} 2`}
          onChange={(e) => handleInputChange("phone2", e.target.value)}
          value={organization.phone2 ?? ""}
        />
      </div>
    </div>
  );
};

const AddressInformation = ({
  handleInputChange,
  organization,
}: {
  handleInputChange: (
    field: keyof CreateOrganizationRequest,
    value: string,
  ) => void;
  organization: Partial<Organization>;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col rounded-lg bg-secondary-card-backplate p-4 text-primary-text">
      <p className="text-sm font-bold">{t("address")}</p>
      <div className="flex flex-col">
        <InputWithLabel
          label={`${t("address-line")} 1`}
          onChange={(e) => handleInputChange("address", e.target.value)}
          value={organization.address ?? ""}
        />

        <InputWithLabel
          label={`${t("address-line")} 2`}
          onChange={(e) => handleInputChange("address2", e.target.value)}
          value={organization.address2 ?? ""}
        />

        <InputWithLabel
          label={t("zip-code")}
          onChange={(e) => handleInputChange("zip", e.target.value)}
          value={organization.zip ?? ""}
        />

        <InputWithLabel
          label={t("city")}
          onChange={(e) => handleInputChange("city", e.target.value)}
          value={organization.city ?? ""}
        />

        <InputWithLabel
          label={t("state-province-region")}
          onChange={(e) => handleInputChange("state", e.target.value)}
          value={organization.state ?? ""}
        />

        <InputWithLabel
          label={t("country")}
          onChange={(e) => handleInputChange("countryCode", e.target.value)}
          value={organization.countryCode ?? ""}
        />
      </div>
    </div>
  );
};

const InvoiceAddressInformation = ({
  isBillingSameAsAddress,
  setIsBillingSameAsAddress,
  handleInputChange,
  organization,
}: {
  isBillingSameAsAddress: boolean;
  setIsBillingSameAsAddress: Dispatch<SetStateAction<boolean>>;
  handleInputChange: (
    field: keyof CreateOrganizationRequest,
    value: string,
  ) => void;
  organization: Partial<Organization>;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isBillingSameAsAddress) {
      handleInputChange("invoiceAddress", organization.address ?? "");
      handleInputChange("invoiceAddress2", organization.address2 ?? "");
      handleInputChange("invoiceZip", organization.zip ?? "");
      handleInputChange("invoiceCity", organization.city ?? "");
      handleInputChange("invoiceState", organization.state ?? "");
      handleInputChange("invoiceCountry", organization.countryCode ?? "");
    }
  }, [isBillingSameAsAddress, organization]);

  return (
    <div className="flex flex-col rounded-lg bg-secondary-card-backplate p-4 text-primary-text">
      <p className="text-sm font-bold">{t("billing-address")}</p>

      <div className="flex flex-col">
        <InputWithLabel
          label={t("reference")}
          onChange={(e) =>
            handleInputChange("invoiceReference", e.target.value)
          }
          value={organization.invoiceReference ?? ""}
        />

        <div className="flex pt-2">
          <SwitchWithLabel
            label={t("same-as-address")}
            checked={isBillingSameAsAddress}
            onCheckedChange={(checked) => setIsBillingSameAsAddress(checked)}
          />
        </div>

        {!isBillingSameAsAddress && (
          <>
            <InputWithLabel
              label={`${t("address-line")} 1`}
              onChange={(e) =>
                handleInputChange("invoiceAddress", e.target.value)
              }
              value={organization.invoiceAddress ?? ""}
            />

            <InputWithLabel
              label={`${t("address-line")} 2`}
              onChange={(e) =>
                handleInputChange("invoiceAddress2", e.target.value)
              }
              value={organization.invoiceAddress2 ?? ""}
            />

            <InputWithLabel
              label={t("zip-code")}
              onChange={(e) => handleInputChange("invoiceZip", e.target.value)}
              value={organization.invoiceZip ?? ""}
            />

            <InputWithLabel
              label={t("city")}
              onChange={(e) => handleInputChange("invoiceCity", e.target.value)}
              value={organization.invoiceCity ?? ""}
            />

            <InputWithLabel
              label={t("state-province-region")}
              onChange={(e) =>
                handleInputChange("invoiceState", e.target.value)
              }
              value={organization.invoiceState ?? ""}
            />

            <InputWithLabel
              label={t("country")}
              onChange={(e) =>
                handleInputChange("invoiceCountry", e.target.value)
              }
              value={organization.invoiceCountry ?? ""}
            />
          </>
        )}
      </div>
    </div>
  );
};
