import { createMutation, createQuery } from "react-query-kit";
import {
  GetSiteResponse,
  GetSystemConfigResponse,
  PatchSiteRequest,
  PatchSiteResponse,
} from "../../../../api-contracts/sites";
import { api } from "./api";

export const SITES_QUERY_KEY = "/api/sites";

export const useGetSiteSystemConfig = createQuery<
  GetSystemConfigResponse,
  { siteId: string }
>({
  primaryKey: `${SITES_QUERY_KEY}/id/system-config`,
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    api.get(`${SITES_QUERY_KEY}/${siteId}/system-config`),
});

export const useGetSite = createQuery<GetSiteResponse, { siteId: string }>({
  primaryKey: SITES_QUERY_KEY,
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    api.get(`${SITES_QUERY_KEY}/${siteId}`),
});

export const useUpdateSite = createMutation<
  PatchSiteResponse,
  PatchSiteRequest & { id: string }
>({
  mutationFn: async ({ id, ...site }) =>
    api.patch(`${SITES_QUERY_KEY}/${id}`, site),
});
