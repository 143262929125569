import { CategoryCard } from "@pages/settings/category-cluster/components/category-card.tsx";
import { ClusterCard } from "@pages/settings/category-cluster/components/clusterCard.tsx";
import { Space } from "@components/space.tsx";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "@primitives/button.tsx";
import {
  ClusterCategory,
  CategoryClusterContext,
  Cluster,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";

import { useTranslation } from "react-i18next";
import { DeleteClusterDialog } from "@pages/settings/category-cluster/components/delete-cluster-dialog.tsx";
import { MoveCategoriesDialog } from "@pages/settings/category-cluster/components/move-categories-dialog.tsx";
import clsx from "clsx";
import { CreateClusterDialog } from "@pages/settings/category-cluster/components/create-cluster-dialog.tsx";
import { ChangeNameDialog } from "@pages/settings/category-cluster/components/change-name-dialog.tsx";
import { RemoveCategoriesFromClusterDialog } from "@pages/settings/category-cluster/components/remove-categories-from-cluster-dialog.tsx";
import { X } from "lucide-react";

export const CategoryClusterPage = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openChangeDialog, setOpenChangeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [openRemoveCatDialog, setOpenRemoveCatDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(0);
  const [selectedClusters, setSelectedClusters] = useState(0);
  const [selectedCategoryClusters, setSelectedCategoryClusters] = useState(0);
  const { clusterData, selectCategory, selectClusterCategory, selectCluster } =
    useContext(CategoryClusterContext);
  const { t } = useTranslation();
  const [selectedClustersAndCategories, setselectedClustersAndCategories] =
    useState(0);

  useEffect(() => {
    setselectedClustersAndCategories(
      selectedCategories + selectedClusters + selectedCategoryClusters,
    );
  }, [selectedCategories, selectedClusters, selectedCategoryClusters]);

  const Toolbar = () => {
    return (
      <>
        <div
          className={clsx(
            "flex flex-shrink items-center justify-center rounded-xl bg-solid-backplate p-2 shadow-lg",
            (selectedCategories > 0 ||
              selectedClusters > 0 ||
              selectedCategoryClusters > 0) &&
              "bg-solid-backplate",
          )}
        >
          {selectedCategories > 0 && (
            <>
              <CreateClusterDialog
                open={openCreateDialog}
                onOpenChange={setOpenCreateDialog}
                selectedClusterCategories={1}
                selectedClusters={1}
              />
              <Space w={8} />
            </>
          )}
          {selectedClusters === 1 && (
            <>
              <ChangeNameDialog
                selectedClusters={selectedClusters}
                selectedClusterCategories={selectedClustersAndCategories}
                open={openChangeDialog}
                onOpenChange={setOpenChangeDialog}
              />
              <Space w={8} />
            </>
          )}
          {selectedCategories > 0 && clusterData.clusters.length > 0 && (
            <>
              <MoveCategoriesDialog
                open={openMoveDialog}
                onOpenChange={setOpenMoveDialog}
              />
              <Space w={8} />
            </>
          )}
          {selectedClusters > 0 && (
            <>
              <DeleteClusterDialog
                clusters={clusterData?.clusters.filter(
                  (cl: Cluster) => cl.selected,
                )}
                open={openDeleteDialog}
                onOpenChange={setOpenDeleteDialog}
              />
              <Space w={8} />
            </>
          )}
          {selectedCategoryClusters > 0 && (
            <>
              <RemoveCategoriesFromClusterDialog
                open={openRemoveCatDialog}
                onOpenChange={setOpenRemoveCatDialog}
              />
              <Space w={8} />
            </>
          )}
          {selectedClustersAndCategories > 0 && (
            <Button
              className={"text-[12px]"}
              variant={"primary"}
              size={"sm"}
              onClick={() => {
                clusterData.categoryTypes.map((cat: ClusterCategory) => {
                  if (cat.selected) return selectCategory(cat.category);
                });
                clusterData.clusters.map((cl: Cluster) => {
                  if (cl.selected) return selectCluster(cl.id);
                });
                clusterData.clusters.map((cl) => {
                  cl.categories
                    .filter((cat) => cat.selected)
                    .map((cat) => selectClusterCategory(cat.category, cl.id));
                });
              }}
            >
              <X strokeWidth={2} size={18} className={"mr-2 items-center"} />
              {t("marked", { count: selectedClustersAndCategories })}
            </Button>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    const catLength = clusterData?.categoryTypes.filter(
      (cat: ClusterCategory) => cat.selected,
    );
    const clLength = clusterData?.clusters.filter((cl: Cluster) => cl.selected);
    const catClLength = clusterData?.clusters
      .flatMap((cl: Cluster) =>
        cl.categories.filter((cat: ClusterCategory) => cat.selected),
      )
      .map((cat: ClusterCategory) => cat.category);
    setSelectedCategories(catLength?.length);
    setSelectedClusters(clLength?.length);
    setSelectedCategoryClusters(catClLength?.length);
  }, [clusterData]);

  return (
    <>
      <div className={"h-[calc(100vh_-_115px)] overflow-hidden p-4"}>
        <div className={"flex"}>
          <div className={"w-5/12"}>
            <p className={" font-itc text-3xl font-bold text-primary-text"}>
              {t("category-cluster")}
            </p>
            <p className={"pb-2 text-sm text-secondary-text"}>
              {t("category-cluster-desc")}
            </p>
          </div>
          {selectedClustersAndCategories > 0 && (
            <div>
              <Toolbar />
            </div>
          )}
        </div>
        <Space h={10} />
        <div className={"align flex items-center"}>
          {clusterData?.clusters.map((cl: Cluster) => (
            <ClusterCard
              selectedClusterCategories={selectedCategoryClusters}
              selectedClusters={selectedClusters}
              key={cl.id}
              cluster={cl}
              selectedCategories={selectedCategories}
            />
          ))}
          {clusterData?.categoryTypes && (
            <div className={"mt-2 flex cursor-pointer"}>
              {clusterData?.categoryTypes?.map(
                (cat: ClusterCategory, index: number) => (
                  <CategoryCard
                    selectedClusterCategories={selectedCategoryClusters}
                    selectedClusters={selectedClusters}
                    key={index}
                    category={cat}
                    showCheck={true}
                  />
                ),
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
