import {
  AgeType,
  BedPreference,
  BookingGuestPayload,
} from "../../../../api-contracts/reservations";

export const initialGuestForBookingPayload = (
  type: AgeType,
  bedPreference?: BedPreference,
  age?: number,
): BookingGuestPayload => {
  return {
    ageType: type,
    age: age,
    bedPreference: bedPreference,
    name: null,
    surname: null,
    passportNumber: null,
    address: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    countryCode: null,
    phone: null,
    phone2: null,
    email: null,
    invoiceReference: null,
    invoiceName: null,
    invoiceAddress: null,
    invoiceAddress2: null,
    invoiceZip: null,
    invoiceCity: null,
    invoiceState: null,
    invoiceCountry: null,
    types: ["guest", "booker"],
  };
};
