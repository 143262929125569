import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  OutOfOrder,
  OutOfOrderType,
} from "../../../../../api-contracts/out-of-order";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { useTranslation } from "react-i18next";
import { Card } from "@primitives/card.tsx";
import { Button } from "@primitives/button.tsx";
import { Space } from "@components/space.tsx";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { AssetOverview } from "../../../../../api-contracts/assets";
import { MultiSelect } from "@primitives/multi-select.tsx";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group.tsx";
import { Label } from "@primitives/label.tsx";
import { Textarea } from "@primitives/textarea.tsx";
import DatePicker from "@primitives/date-picker.tsx";
import { useGetAvailability } from "@api/out-of-order.ts";

interface Props {
  ooo: OutOfOrder;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

interface AssetData {
  id: string;
  name: string;
}
export const EditOutOfOrderSheet: FC<Props> = ({ ooo, open, onOpenChange }) => {
  const { t } = useTranslation();
  const { assetData, patchOutOfOrder } = useContext(OutOfOrderContext);
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(new Date().toLocaleDateString("sv"));
  const [toDate, setToDate] = useState(
    new Date(tomorrow).toLocaleDateString("sv"),
  );
  const [oooType, setOoType] = useState<OutOfOrderType>(ooo.type);
  const [reason, setReason] = useState(ooo.reason);
  const [assetsData, setAssetsData] = useState<AssetOverview[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<AssetData[]>([ooo.asset]);

  const [availableUnits, setAvailableUnits] = useState<
    { id: string; name: string }[]
  >([]);
  const { data: availableAssets } = useGetAvailability({
    variables: {
      from: fromDate,
      to: toDate,
    },
  });

  const isDisabled = useMemo(() => {
    return (
      reason !== ooo.reason ||
      fromDate !== ooo.startDate ||
      toDate !== ooo.endDate ||
      oooType !== ooo.type
    );
  }, [reason, fromDate, toDate, oooType, selectedUnits]);

  const resetValues = () => {
    setReason("");
    setSelectedUnits([]);
    setAvailableUnits([]);
    setFromDate(new Date().toLocaleDateString("sv"));
    setToDate(new Date().toLocaleDateString("sv"));
  };

  useEffect(() => {
    setFromDate(ooo.startDate);
    setToDate(ooo.endDate);
    setOoType(ooo.type);
    setReason(ooo.reason);
  }, [ooo]);

  useEffect(() => {
    setAssetsData(assetData);
  }, [assetData]);

  useEffect(() => {
    setSelectedUnits([{ id: ooo.id, name: ooo.asset.name }]);
    if (!availableAssets?.assets) return;
    setAvailableUnits([
      { id: ooo.id, name: ooo.asset.name },
      ...availableAssets.assets
        .filter((a) => a.states.length === 0)
        .map((a) => ({ id: a.id, name: a.name })),
    ]);
  }, [availableAssets]);

  useEffect(() => {
    resetValues();
  }, [onOpenChange]);

  useEffect(() => {
    if (fromDate) {
      const fromDateObj = new Date(fromDate);
      const nextDay = new Date(fromDateObj);
      nextDay.setDate(nextDay.getDate() + 1);
      setToDate(nextDay.toISOString().split("T")[0]);
    }
  }, [fromDate]);

  return (
    <DefaultSideSheet title={t("edit")} open={open} onOpenChange={onOpenChange}>
      <div className={"flex h-full flex-col justify-between overflow-auto"}>
        <div>
          <Card className={" mt-0 bg-secondary-card-backplate"}>
            <p className={"mb-2 text-sm font-bold"}>{t("unit")}</p>
            <MultiSelect
              multiple={false}
              disabled={ooo.status === "active"}
              placeholder={t("units")}
              options={availableUnits}
              value={availableUnits.filter((a) =>
                selectedUnits.some((selected) => selected.name === a.name),
              )}
              onChange={(value: AssetData[]) => {
                setSelectedUnits(value);
              }}
              getName={(opt) => opt.name}
            />
          </Card>
          <Card className={" mt-4 bg-secondary-card-backplate"}>
            <div>
              <div>
                <div className={"mb-3 flex"}>
                  <p className={"text-sm font-bold"}>{t("from")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                {ooo.status === "active" && <p>{ooo.startDate}</p>}
                {ooo.status === "planned" && (
                  <DatePicker
                    onValueChange={(e) => {
                      if (!e) return;
                      setFromDate(new Date(e).toLocaleDateString("sv"));
                    }}
                    value={new Date(fromDate)}
                    calendarProps={{
                      disabled: (date) => {
                        const fromDateObj = fromDate
                          ? new Date(fromDate)
                          : null;
                        return fromDateObj ? date < fromDateObj : false;
                      },
                    }}
                  />
                )}
              </div>
              <div className={"mt-4"}>
                <div className={"mb-3 flex"}>
                  <p className={"text-sm font-bold"}>{t("to")}</p>
                  <p className={"pl-1.5 text-red-700"}>*</p>
                </div>
                <DatePicker
                  onValueChange={(e) => {
                    if (!e) return;
                    setToDate(new Date(e).toLocaleDateString("sv"));
                  }}
                  value={new Date(toDate)}
                  calendarProps={{
                    disabled: (date) => {
                      const fromDateObj = fromDate ? new Date(fromDate) : null;
                      if (fromDateObj) {
                        const nextDay = new Date(fromDateObj);
                        nextDay.setDate(nextDay.getDate() + 1);
                        return date < nextDay;
                      }
                      return false;
                    },
                  }}
                />
              </div>
            </div>
          </Card>
          <Card className={" mt-4 bg-secondary-card-backplate"}>
            <div>
              <div className={"flex"}>
                <p className={"text-sm font-bold"}>{t("block")}</p>
                <p className={"t pl-1.5 text-status-error"}>*</p>
              </div>
              <Space h={22} />
              <RadioGroup
                className="mb-4"
                value={oooType}
                onValueChange={(v) => setOoType(v as OutOfOrderType)}
              >
                <div className={"flex"}>
                  <div className={"flex items-center"}>
                    <RadioGroupItem
                      value={"out-of-order"}
                      disabled={ooo.status === "active"}
                    />
                    <Space w={4} />
                    <Label className={"text-sm font-extrabold"}>
                      {t("oof")}
                    </Label>
                  </div>
                  <Space w={85} />
                  <div className={"flex items-center"}>
                    <RadioGroupItem
                      value={"out-of-service"}
                      disabled={ooo.status === "active"}
                    />
                    <Space w={4} />
                    <Label className={"text-sm font-extrabold"}>
                      {t("ooo")}
                    </Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
          </Card>
          <Card className={" mt-4 bg-secondary-card-backplate"}>
            <div>
              <div className={"flex"}>
                <p className={"text-sm font-bold"}>{t("reason")}</p>
                <p className={"pl-1.5 text-red-700"}>*</p>
              </div>
              <Textarea
                placeholder={t("specify-reason")}
                disabled={false}
                value={reason}
                onChange={(v) => setReason(v.target.value)}
              />
            </div>
          </Card>
        </div>
        <div className={"flex justify-end"}>
          <Button
            variant={"outline"}
            onClick={() => {
              resetValues();
              onOpenChange(false);
            }}
          >
            {t("cancel")}
          </Button>
          <Space w={8} />
          <Button
            disabled={!isDisabled}
            onClick={() => {
              patchOutOfOrder({
                newAssetId: selectedUnits[0].id,
                startDate: fromDate,
                endDate: toDate,
                type: oooType,
                reason: reason,
              }).then();
              resetValues();
              onOpenChange(false);
            }}
          >
            {t("edit")}
          </Button>
        </div>
      </div>
    </DefaultSideSheet>
  );
};
