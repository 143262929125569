import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useGetProfileContext } from "@api/profile-site-module-context";
import { ACTIVE_SITE_STORAGE_KEY, currentSite } from "./site-context";
import { useAuth } from "@hooks/use-auth";
import { Loading } from "@primitives/loading";
import { useGetCategoryClusters } from "@api/category-clusters.ts";
import { CategoryType } from "../../../../api-contracts/categories";
import { SiteContextWithImage } from "../../../../api-contracts/profile";
import { Permission, PermissionModule } from "../../../../api-contracts/auth";
import { hasPermissionMatch } from "@utils/permissions.ts";
import { SiteWithImage } from "../../../../api-contracts/sites";

export interface Clusters {
  name: string;
  categoryTypes: CategoryType[];
}

interface ProfileContextType {
  module: PermissionModule;
  setModule: (module: PermissionModule) => void;
  getModuleCluster: (module: PermissionModule) => Clusters[];
  modulePermissions: string[];
  routePermissions: Permission[];
  useHasPermission: (requiredPermissons: Permission[]) => boolean;
  clusters: ModuleClusters;
  activeCluster: Clusters[];
  sites?: SiteContextWithImage[];
  activeSite?: SiteWithImage;
  setActiveSite: (site: SiteWithImage) => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);
ProfileContext.displayName = "ProfileContext";

export const ACTIVE_MODULE_STORAGE_KEY = "activeModule";

export type ModuleClusters = {
  roomr: Clusters[];
  campr: Clusters[];
};

const roomrClusters: Clusters[] = [
  { name: "hotel", categoryTypes: ["room"] },
  { name: "area", categoryTypes: ["area"] },
  { name: "hostel", categoryTypes: ["bed", "dormitory"] },
];
const camprClusters: Clusters[] = [
  { name: "motorhome", categoryTypes: ["motorhome"] },
  { name: "mobile-home", categoryTypes: ["mobile-home"] },
  { name: "tent", categoryTypes: ["tent"] },
  { name: "glamping", categoryTypes: ["glamping"] },
  { name: "cabin", categoryTypes: ["cabin"] },
  { name: "caravan", categoryTypes: ["caravan"] },
  { name: "misc", categoryTypes: ["misc"] },
];

const defaultClusters: ModuleClusters = {
  roomr: roomrClusters,
  campr: camprClusters,
};

export const ProfileContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user, isAuthenticated } = useAuth();
  const [siteId, setSiteId] = useState<string | null>(null);
  const [activeSite, setActiveSite1] = useState<SiteWithImage>();
  const { data: sites } = useGetProfileContext({
    enabled: isAuthenticated,
  });

  const setActiveSite = (site?: SiteWithImage) => {
    localStorage.setItem(ACTIVE_SITE_STORAGE_KEY, site?.id || "");
    setActiveSite1(site);
  };

  useEffect(() => {
    if (sites) {
      const activeSiteId = localStorage.getItem(ACTIVE_SITE_STORAGE_KEY);
      const site = sites.find((s: any) => s.id === activeSiteId) || sites[0];
      setActiveSite1(site);
    }
  }, [sites]);

  useEffect(() => {
    if (sites) {
      const activeSiteId = localStorage.getItem(ACTIVE_SITE_STORAGE_KEY);
      const site = sites.find((s: any) => s.id === activeSiteId) || sites[0];
      setSiteId(site?.id ?? null);
    }
  }, [sites]);

  const { data: categoryClusters } = useGetCategoryClusters({
    enabled: siteId !== null,
    variables: { optionalSite: siteId },
  });

  const getInitialModule = (): PermissionModule => {
    const savedModule = localStorage.getItem(
      ACTIVE_MODULE_STORAGE_KEY,
    ) as PermissionModule;
    return savedModule || "default";
  };
  const [module, setModule1] = useState<PermissionModule>(getInitialModule());
  const [modulePermissions, setModulePermissions] = useState<string[]>([]);
  const [routePermissions, setRoutePermissions] = useState<Permission[]>([]);
  const [clusters, setClusters] = useState<ModuleClusters>(defaultClusters);
  const [activeCluster, setActiveCluster] = useState<Clusters[]>([]);

  const setModule = (module: PermissionModule) => {
    localStorage.setItem(ACTIVE_MODULE_STORAGE_KEY, module);
    setModule1(module);
  };

  const normalizePermission = (permission: Permission): Permission[] => {
    // If permission starts with 'write', generate both 'read' and 'write' versions
    if (permission.startsWith("write")) {
      return [permission, permission.replace(/^write/, "read") as Permission];
    }
    // Otherwise, return the permission as is
    return [permission];
  };

  const uniquePermissionsFilter = (
    value: Permission,
    index: number,
    self: Permission[],
  ) => {
    return self.indexOf(value) === index;
  };

  useEffect(() => {
    if (isAuthenticated && user && sites) {
      const activeSiteId = localStorage.getItem(ACTIVE_SITE_STORAGE_KEY);
      const site: SiteContextWithImage =
        sites.find((s: SiteWithImage) => s.id === activeSiteId) || sites[0];

      setModulePermissions(site?.modules ?? []);
      setRoutePermissions(
        site?.permissions
          .flatMap(normalizePermission)
          .filter(uniquePermissionsFilter) ?? [],
      );

      const savedModule = localStorage.getItem(ACTIVE_MODULE_STORAGE_KEY);
      let initialModule: PermissionModule;

      if (savedModule && site?.modules.includes(savedModule)) {
        initialModule = savedModule as PermissionModule;
      } else {
        initialModule = site?.modules[0] as PermissionModule;
      }

      const pathModule = window.location.pathname.split(
        "/",
      )[1] as PermissionModule;

      if (!site?.modules.includes(pathModule)) {
        setModule(initialModule);
        if (user.type !== "admin") {
          window.location.replace(`/${initialModule}`);
        }
      } else if (pathModule !== initialModule) {
        setModule(pathModule);
      } else {
        setModule(initialModule);
      }
    }
  }, [sites, isAuthenticated]);

  useEffect(() => {
    if (!categoryClusters) return;
    if (categoryClusters.clusters.length > 0) {
      setClusters({
        ...defaultClusters,
        campr: categoryClusters.clusters.map((cluster: Clusters) => ({
          name: cluster.name,
          categoryTypes: cluster.categoryTypes,
        })),
      });
    } else {
      setClusters({ roomr: defaultClusters.roomr, campr: camprClusters });
    }
  }, [categoryClusters]);

  const getModuleCluster = (mod: PermissionModule): Clusters[] => {
    switch (mod) {
      case "campr":
        return clusters.campr;
      case "roomr":
      default:
        return clusters.roomr;
    }
  };

  useEffect(() => {
    setActiveCluster(getModuleCluster(module));
  }, [clusters, module]);

  const proceed = !!sites || !!module || !!isAuthenticated;

  //** Checking if site has permission - Useable in FE to hide/show components */
  const useHasPermission = (requiredPermissions: Permission[]): boolean =>
    hasPermissionMatch(routePermissions, requiredPermissions);

  return (
    <ProfileContext.Provider
      value={{
        module,
        setModule,
        getModuleCluster,
        modulePermissions,
        routePermissions,
        useHasPermission,
        clusters,
        activeCluster,
        sites,
        activeSite,
        setActiveSite,
      }}
    >
      {proceed ? children : <Loading />}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error(
      "useProfileContext must be used within a ProfileContextProvider",
    );
  }
  return context;
};
