import { Filters } from "@pages/simple-search/search-types";
import { Address } from "./address";
import { InputWithLabel } from "./input-with-label";
import { Tags } from "./tags";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const TravelAgency = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    name: filters.travelAgency.name,
    iata: filters.travelAgency.IATA,
    organizationNumber: filters.travelAgency.organizationNumber,
    phone: filters.travelAgency.phone,
    email: filters.travelAgency.email,
    tags: filters.travelAgency.tags,
    address: filters.travelAgency.address,
    address2: filters.travelAgency.address2,
    zip: filters.travelAgency.zip,
    city: filters.travelAgency.city,
    state: filters.travelAgency.state,
    countryCode: filters.travelAgency.countryCode, // Change to country?
  });

  const { t } = useTranslation();

  const handleInputChange = (
    key: keyof typeof tempInput,
    value: string | string[],
  ) => {
    setTempInput((prev) => ({ ...prev, [key]: value }));
    updateFilter("travelAgency", key, value);
  };

  useEffect(() => {
    setTempInput({
      name: filters.travelAgency.name,
      iata: filters.travelAgency.IATA,
      organizationNumber: filters.travelAgency.organizationNumber,
      phone: filters.travelAgency.phone,
      email: filters.travelAgency.email,
      tags: filters.travelAgency.tags,
      address: filters.travelAgency.address,
      address2: filters.travelAgency.address2,
      zip: filters.travelAgency.zip,
      city: filters.travelAgency.city,
      state: filters.travelAgency.state,
      countryCode: filters.travelAgency.countryCode, // Change to country?
    });
  }, [filters.travelAgency]);

  return (
    <div className="flex flex-col pb-4">
      <InputWithLabel
        label={t("name")}
        value={tempInput.name}
        onChange={(e) => handleInputChange("name", e.target.value)}
      />

      <InputWithLabel
        label={t("iata-number")}
        value={tempInput.iata}
        onChange={(e) => handleInputChange("iata", e.target.value.trim())}
      />
      <InputWithLabel
        label={t("organization-number")}
        value={tempInput.organizationNumber}
        onChange={(e) =>
          handleInputChange("organizationNumber", e.target.value.trim())
        }
      />
      <InputWithLabel
        label={t("phone")}
        value={tempInput.phone}
        onChange={(e) => handleInputChange("phone", e.target.value.trim())}
      />
      <InputWithLabel
        label={t("email")}
        value={tempInput.email}
        onChange={(e) => handleInputChange("email", e.target.value.trim())}
      />
      <Tags
        disabled
        selectedTags={tempInput.tags ?? []}
        onValueChange={(newTags) => handleInputChange("tags", newTags)}
      />
      <Address
        address={{
          address: tempInput.address,
          address2: tempInput.address2,
          zip: tempInput.zip,
          city: tempInput.city,
          state: tempInput.state,
          countryCode: tempInput.countryCode,
        }}
        handleInputChange={handleInputChange}
      />
    </div>
  );
};
