import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  DeleteImageResponse,
  GetImageQueryParams,
  GetImageResponse,
  PatchImageRequest,
  PatchImageResponse,
  PostImageResponse,
  SearchImageQueryParams,
  SearchImageResponse,
} from "../../../../api-contracts/images";

export const IMAGES_QUERY_KEY = "images";

export const useSearchImages = createQuery<
  SearchImageResponse,
  SearchImageQueryParams
>({
  primaryKey: IMAGES_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { tag, variant }] }) =>
    api.get(`/api/${currentSite?.id}/${IMAGES_QUERY_KEY}`, { tag, variant }),
});

export const useUpdateImage = createMutation<
  PatchImageResponse,
  { patch: PatchImageRequest; id: string }
>({
  mutationFn: async (data) =>
    api.patch(
      `/api/${currentSite?.id}/${IMAGES_QUERY_KEY}/${data.id}`,
      data.patch,
    ),
});

export const useUploadImage = createMutation<
  PostImageResponse,
  { file: File; description?: string; tags: string[]; siteId?: string }
>({
  mutationFn: async ({ file, description, tags, siteId = currentSite?.id }) =>
    api.upload(`/api/${siteId}/${IMAGES_QUERY_KEY}`, file, [
      ...tags.map((t) => ({ key: "tags", value: t })),
      {
        key: "description",
        value: description || "",
      },
    ]),
});

export const useDeleteImage = createMutation<
  DeleteImageResponse,
  { id: string }
>({
  mutationFn: async (data) =>
    api.delete(`/api/${currentSite?.id}/${IMAGES_QUERY_KEY}/${data.id}`),
});

export const useGetImage = createQuery<
  GetImageResponse,
  GetImageQueryParams & { id: string; siteId?: string }
>({
  primaryKey: IMAGES_QUERY_KEY,
  queryFn: async ({
    queryKey: [_, { id, variant, siteId = currentSite?.id }],
  }) => api.get(`/api/${siteId}/${IMAGES_QUERY_KEY}/${id}`, { variant }),
});
