import { AlertDialog } from "@primitives/alert-dialog.tsx";
import { useTranslation } from "react-i18next";
import { FC, useContext } from "react";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { OutOfOrder } from "../../../../../api-contracts/out-of-order";
import { isDateToday, isTodayBetween } from "@utils/helpers.ts";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  outOfOrder: OutOfOrder;
}

export const QuitOutOfOrderDialog: FC<Props> = ({
  open,
  onOpenChange,
  outOfOrder,
}) => {
  const { t } = useTranslation();
  const { endOutOfOrder } = useContext(OutOfOrderContext);

  const getTitleText = (ooo: OutOfOrder): string => {
    if (isDateToday(ooo.endDate)) {
      return (
        "Är du säker på att du vill avsluta " +
        t(ooo.type).toLowerCase() +
        " för " +
        ooo.asset.name +
        " ?"
      );
    } else if (isTodayBetween(ooo.startDate, ooo.endDate)) {
      return (
        "Är du säker på att du vill avsluta " +
        t(ooo.type).toLowerCase() +
        " för " +
        ooo.asset.name +
        " innan satt slutdatum?"
      );
    } else {
      return (
        "Är du säker på att du vill avsluta " +
        t(ooo.type).toLowerCase() +
        " för " +
        ooo.asset.name +
        " efter satt slutdatum?"
      );
    }
  };
  const getStatusText = (ooo: OutOfOrder): string => {
    if (isDateToday(ooo.endDate)) {
      return "Rummet får status ledigt/ostädat.";
    } else {
      return "Slutdatumet på spärren ändras till dagens datum. Rummet får status ledigt/ostädat.";
    }
  };
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content className="z-[2001]">
        <AlertDialog.Header>
          <AlertDialog.Title>{getTitleText(outOfOrder)}</AlertDialog.Title>
          <AlertDialog.Description>
            {getStatusText(outOfOrder)}
          </AlertDialog.Description>
        </AlertDialog.Header>

        <AlertDialog.Footer className="relative">
          <AlertDialog.Cancel>{t("cancel")}</AlertDialog.Cancel>

          <AlertDialog.Action onClick={() => endOutOfOrder(outOfOrder)}>
            {t("confirm")}
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
