import { SearchBarWithIcon } from "@primitives/search-bar-with-icon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationTable } from "./organization-table";
import {
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { capitalizeFirstLetter } from "@shared/utils/helpers";
import { Checkbox } from "@primitives/checkbox";
import { ColumnVisibilityToggle } from "./column-visibility-toggle";
import { Header } from "./header";
import { ActionBar } from "./action-bar";
import { Organization } from "../../../../../api-contracts/customer-list/organization";
import { MergeCustomers } from "./merge-customers";
import { useSearchOrganizations } from "@api/customer-list/organizations";
import { Loading } from "@primitives/loading";
import { debounce } from "lodash";

export const TableContainer = ({
  tab,
  openOrganizationSheet,
  openCustomerSheet,
  selectedOrganizations,
  setSelectedOrganizations,
}: {
  tab: "organization" | "people";
  openOrganizationSheet: (org: Organization | undefined) => void;
  openCustomerSheet: Dispatch<SetStateAction<boolean>>;
  selectedOrganizations: Organization[];
  setSelectedOrganizations: Dispatch<SetStateAction<Organization[]>>;
}) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const [isMerging, setIsMerging] = useState<boolean>(false);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Organization>();

  const { data: organizationCustomers, isLoading: organizationIsLoading } =
    useSearchOrganizations({
      variables: {
        page: 1,
        limit: 10,
        pattern: debouncedSearchTerm,
      },
    });

  useEffect(() => {
    const executeSearch = debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    executeSearch();

    return () => {
      executeSearch.cancel();
    };
  }, [searchTerm]);

  const toggleRow = (row: Organization) => {
    setSelectedOrganizations((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (selectedRow) => selectedRow.id === row.id,
      );

      if (isSelected) {
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id,
        );
      }
      return [...prevSelectedRows, row];
    });
  };

  const toggleAll = () => {
    const isAllSelected =
      selectedOrganizations.length === organizationCustomers?.length;
    setSelectedOrganizations(
      isAllSelected ? [] : (organizationCustomers ?? []),
    );
  };

  const onEdit = () => {
    openOrganizationSheet(selectedOrganizations[0]);
  };

  const organizationColumnDefinition = [
    columnHelper.group({
      header: () => {
        const isAllRowsSelected =
          selectedOrganizations.length === organizationCustomers?.length;
        return (
          <Checkbox
            className={`h-3 w-3`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={isAllRowsSelected}
            onCheckedChange={() => toggleAll()}
          />
        );
      },
      id: "selectAll",
      columns: [
        columnHelper.display({
          id: "select",
          size: 5,
          enableHiding: false,
          cell: ({ row }) => {
            const isRowSelected = selectedOrganizations.some(
              (selectedRow) => selectedRow.id === row.original.id,
            );
            return (
              <Checkbox
                className={`${isRowSelected ? "visible" : "invisible"} h-3 w-3 group-hover:visible`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                checked={isRowSelected}
                onCheckedChange={() => toggleRow(row.original)}
              />
            );
          },
        }),
      ],
    }),
    columnHelper.group({
      header: t("company-data"),
      id: "companyData",
      columns: [
        columnHelper.accessor("id", {
          header: t("customer-number"),
          id: "customerNumber",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("name", {
          header: t("company-name"),
          id: "organizationName",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("type", {
          header: t("type"),
          id: "organizationType",
          cell: ({ getValue }) => {
            const orgType = getValue();
            const classNameStyle =
              orgType === "organization"
                ? "bg-amber-500-10 text-amber-500"
                : "bg-booking-confirmed-100 text-booking-confirmed";
            return (
              <div className="flex">
                <div
                  className={`${classNameStyle} rounded-md px-2 py-1 text-center font-bold`}
                >
                  {capitalizeFirstLetter(
                    t(orgType === "organization" ? "company" : orgType),
                  )}
                </div>
              </div>
            );
          },
        }),
        columnHelper.accessor("organizationNumber", {
          header: t("organization-number"),
          id: "organizationNumber",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("IATA", {
          header: t("iata-number"),
          id: "iata",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("phone", {
          header: t("phone"),
          id: "phone",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("email", {
          header: t("email"),
          id: "email",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
      ],
    }),
    columnHelper.group({
      header: t("address"),
      id: "address",
      meta: { group: "address" },
      columns: [
        columnHelper.accessor("address", {
          header: `${t("address")} 1`,
          id: "adress1",
          meta: { group: "address" },
        }),
        columnHelper.accessor("address2", {
          header: `${t("address")} 2`,
          id: "adress2",
          meta: { group: "address" },
        }),
        columnHelper.accessor("zip", {
          header: t("zip-code"),
          id: "zip",
          meta: { group: "address" },
        }),
        columnHelper.accessor("city", {
          header: t("city"),
          id: "city",
          meta: { group: "address" },
        }),
        columnHelper.accessor("state", {
          header: t("state-province-region"),
          id: "stateProvinceRegion",
          meta: { group: "address" },
        }),
        columnHelper.accessor("countryCode", {
          header: t("country"),
          id: "country",
          meta: { group: "address" },
        }),
      ],
    }),
    columnHelper.group({
      header: t("invoice-address"),
      id: "invoiceAddress",
      meta: { group: "invoice-address", initialVisibility: false },
      columns: [
        columnHelper.accessor("invoiceAddress", {
          header: `${t("address")} 1`,
          id: "invoiceAddress1",
          meta: { group: "invoiceAddress", initialVisibility: false },
        }),
        columnHelper.accessor("invoiceAddress2", {
          header: `${t("address")} 2`,
          id: "invoiceAddress2",
          meta: { group: "invoiceAddress", initialVisibility: false },
        }),
        columnHelper.accessor("invoiceZip", {
          header: t("zip-code"),
          id: "invoiceZip",
          meta: { group: "invoiceAddress", initialVisibility: false },
        }),
        columnHelper.accessor("invoiceCity", {
          header: t("city"),
          id: "invoiceCity",
          meta: { group: "invoiceAddress", initialVisibility: false },
        }),
        columnHelper.accessor("invoiceState", {
          header: t("state-province-region"),
          id: "invoiceState",
          meta: { group: "invoiceAddress", initialVisibility: false },
        }),
        columnHelper.accessor("invoiceCountry", {
          header: t("country"),
          id: "invoiceCountry",
          meta: { group: "invoiceAddress", initialVisibility: false },
        }),
      ],
    }),
  ];

  const organizationTable = useReactTable({
    data: organizationCustomers ?? [],
    columns: organizationColumnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: { columnVisibility: columnVisibility },
  });

  const cols = organizationTable.getAllLeafColumns();

  return (
    <div className="flex h-full w-full flex-col ">
      <div className="p-4">
        <Header
          tab={tab}
          openOrganizationSheet={openOrganizationSheet}
          openCustomerSheet={openCustomerSheet}
          columns={cols}
          columnData={organizationCustomers}
        />
      </div>
      <div className="relative flex justify-between px-4">
        <SearchBarWithIcon
          isExpanded={isSearchExpanded}
          setIsExpanded={setIsSearchExpanded}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {selectedOrganizations.length > 0 && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <ActionBar
              selectedItems={selectedOrganizations}
              tab={tab}
              isMerging={isMerging}
              setIsMerging={setIsMerging}
              clearSelected={() => setSelectedOrganizations([])}
              onEdit={onEdit}
            />
          </div>
        )}
        <ColumnVisibilityToggle columns={cols} />
      </div>
      <div className="h-full py-4 text-primary-text">
        {organizationIsLoading ? (
          <Loading />
        ) : (
          <div className=" flex h-full flex-col py-4 text-primary-text">
            {!isMerging ? (
              <OrganizationTable<Organization>
                selectedRowIds={selectedOrganizations.map((org) => org.id)}
                table={organizationTable}
                onRowClick={() => {}}
              />
            ) : (
              <MergeCustomers
                typeOfCustomer={tab}
                selectedCustomers={selectedOrganizations}
                cancelMerge={() => setIsMerging(false)}
                clearSelected={() => setSelectedOrganizations([])}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
