import { t } from "i18next";
import { OctagonAlert } from "lucide-react";

export const GenericInputError = () => {
  return (
    <div className="flex gap-2 p-2 text-status-error-500">
      <OctagonAlert className="text-status-error-500" size="18" />
      <span className="text-sm">{t("generic-field-is-required")}</span>
    </div>
  );
};
