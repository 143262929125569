import { Search } from "@pages/out-of-order/components/search.tsx";
import { TopBar } from "@pages/out-of-order/components/topbar.tsx";
import { OutOfOrderTable } from "@pages/out-of-order/components/out-of-order-table.tsx";

export const OutOfOrderPage = () => {
  return (
    <div>
      <TopBar />
      <OutOfOrderTable />
    </div>
  );
};
