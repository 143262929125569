import { Card, CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { MergeSteps } from "./merge-steps";
import { Organization } from "../../../../../../api-contracts/customer-list/organization";

export const MergeCustomers = ({
  selectedCustomers,
  clearSelected,
  cancelMerge,
  typeOfCustomer,
}: {
  selectedCustomers: Organization[];
  clearSelected: () => void;
  cancelMerge: () => void;
  typeOfCustomer: "organization" | "people";
}) => {
  const { t } = useTranslation();
  return (
    <Card className="flex flex-grow flex-col p-0">
      <CardTitle className="p-4">{t("merge-customers")}</CardTitle>
      <MergeSteps
        typeOfCustomer={typeOfCustomer}
        customers={selectedCustomers}
        cancelMerge={cancelMerge}
        clearSelected={clearSelected}
      />
    </Card>
  );
};
