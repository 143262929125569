import React from "react";
import { Button } from "@primitives/button";
import { CSVLink } from "react-csv";
import { Column, ColumnDef } from "@tanstack/react-table";

function getValueFromColumn<TData>(
  row: TData,
  column: Column<TData, unknown>,
): unknown {
  const columnDef = column.columnDef as ColumnDef<TData, unknown> & {
    accessorKey?: string;
    accessorFn?: (originalRow: TData) => unknown;
  };
  const { accessorKey, accessorFn } = columnDef;

  if (typeof accessorKey === "string") {
    const paths = accessorKey.split(".");
    let value: any = row;
    for (const p of paths) {
      value = value?.[p];
    }
    return value;
  }

  if (typeof accessorFn === "function") {
    return accessorFn(row);
  }
  return undefined;
}

interface CsvButtonProps<TData> {
  data: TData[];
  columns: Column<TData, unknown>[];
  filename?: string;
  buttonContent?: React.ReactNode | string;
}

export function CsvButton<TData>({
  data,
  columns,
  filename = "export.csv",
  buttonContent,
}: CsvButtonProps<TData>) {
  const extractHeaders = (cols: Column<TData, unknown>[]) =>
    cols.map((column) => {
      const { header } = column.columnDef;

      if (typeof header === "string") {
        return {
          label: header,
          key: column.id?.toString() ?? "",
        };
      }

      return {
        label: column.id?.toString() ?? "",
        key: column.id?.toString() ?? "",
      };
    });

  const formatData = (rows: TData[]) => {
    const headers = extractHeaders(columns);

    return rows.map((row) => {
      const record: Record<string, unknown> = {};

      headers.forEach(({ key }) => {
        const column = columns.find((col) => col.id?.toString() === key);
        if (!column) {
          record[key] = "";
          return;
        }

        const rawValue = getValueFromColumn(row, column);

        record[key] = Array.isArray(rawValue)
          ? rawValue.join(", ")
          : (rawValue ?? "");
      });

      return record;
    });
  };
  const csvHeaders = extractHeaders(columns);
  const csvData = formatData(data);

  return (
    <CSVLink
      data={csvData}
      headers={csvHeaders}
      filename={filename}
      enclosingCharacter={`"`}
      separator=","
    >
      <Button
        variant="secondary"
        className={`${typeof buttonContent !== "string" ? "w-10 p-0" : ""} text-primary-icon-color`}
      >
        {buttonContent}
      </Button>
    </CSVLink>
  );
}
