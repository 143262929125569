import { useTranslation } from "react-i18next";
import { Building, MapPin } from "lucide-react";
import { Organization } from "../../../../../../api-contracts/customer-list/organization";

export const ReviewStep = ({
  typeOfCustomer,
  companyData,
  addressData,
  invoiceData,
}: {
  typeOfCustomer: "organization" | "people";
  companyData: Partial<
    Record<
      keyof Organization,
      { value: string; rowId: string; source: "table" | "input" }
    >
  >;
  addressData: Partial<Organization>;
  invoiceData: Partial<Organization>;
}) => {
  const { t } = useTranslation();

  const organizationFields = [
    { key: "id", label: t("customer-number") },
    { key: "name", label: t("company-name") },
    { key: "organizationNumber", label: t("organization-number") },
    { key: "IATA", label: t("iata-number") },
    { key: "phone", label: t("phone") },
    { key: "phone2", label: `${t("phone")} 2` },
    { key: "email", label: t("email") },
  ];

  const peopleFields = [
    { key: "id", label: t("customer-number") },
    { key: "title", label: t("title") },
    { key: "name", label: t("name") },
    { key: "surname", label: t("surname") },
    { key: "phone", label: t("phone") },
    { key: "email", label: t("email") },
  ];

  const fieldsToRender =
    typeOfCustomer === "organization" ? organizationFields : peopleFields;

  const companyDataValues = Object.entries(companyData).reduce(
    (acc, [key, obj]) => ({
      ...acc,
      [key]: obj?.value || "\u00A0",
    }),
    {},
  );

  return (
    <div className="flex flex-grow items-stretch justify-center">
      <ReviewCard
        title={t("company-data")}
        fields={fieldsToRender}
        data={companyDataValues}
      />
      <div className="mx-3" />
      <AddressReviewCard
        title={t("address-data")}
        address={addressData}
        invoiceAddress={invoiceData}
      />
    </div>
  );
};

const ReviewCard = ({
  title,
  fields,
  data,
}: {
  title: string;
  fields: { key: string; label: string }[];
  data: Record<string, any>;
}) => {
  return (
    <div className="flex h-full min-w-[360px] flex-col rounded-xl border border-border-color bg-primary-card-backplate">
      <div className="border-b border-highlighted-backplate p-6">
        <Building />
        <h3 className="mt-2 font-bold">{title}</h3>
      </div>
      <ul className="flex-grow p-6">
        {fields.map((field) => (
          <li key={field.key} className="flex justify-between py-1  text-sm">
            <span className="font-normal text-secondary-text">
              {field.label}
            </span>
            <span className="font-medium">
              {data[field.key] ? data[field.key] : "\u00A0"}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const AddressReviewCard = ({
  title,
  address,
  invoiceAddress,
}: {
  title: string;
  address: Partial<Organization>;
  invoiceAddress: Partial<Organization>;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full min-w-[360px] flex-col rounded-xl border border-border-color bg-primary-card-backplate">
      <div className="border-b border-highlighted-backplate p-6">
        <MapPin />
        <h3 className="mt-2 font-bold">{title}</h3>
      </div>
      {/* Address Section */}
      <div className="flex justify-between p-6">
        <h4 className="mb-2 text-sm font-normal text-secondary-text">
          {t("address")}
        </h4>
        <ul>
          {[
            address.address,
            address.address2,
            `${address.zip} ${address.city}`,
            address.state,
            address.countryCode,
          ]
            .filter(Boolean)
            .map((line, index) => (
              <li key={index} className="text-right text-sm font-medium">
                {line}
              </li>
            ))}
        </ul>
      </div>
      {/* Invoice Address Section */}
      <div className=" flex justify-between p-6">
        <h4 className="mb-2 text-sm font-normal text-secondary-text">
          {t("invoice-address")}
        </h4>
        <ul>
          {[
            invoiceAddress.invoiceAddress,
            invoiceAddress.invoiceAddress2,
            `${invoiceAddress.invoiceZip} ${invoiceAddress.invoiceCity}`,
            invoiceAddress.invoiceState,
            invoiceAddress.invoiceCountry,
          ]
            .filter(Boolean)
            .map((invoice, index) => (
              <li key={index} className="text-right text-sm font-medium">
                {invoice}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
