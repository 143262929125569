import { useGetAllSystemConfigAdmin } from "@api/admin";
import { useToast } from "@hooks/use-toast";
import { Button } from "@primitives/button";
import { Card, CardTitle } from "@primitives/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Loader2 } from "lucide-react";
import { queryClient } from "query-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/types/navigation";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { SystemConfig } from "../../../../../api-contracts/sites";
import { format } from "date-fns";

const SuperadminSitesPage = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetAllSystemConfigAdmin.getKey(),
    });
  }, []);

  const { data, isLoading, isRefetching } = useGetAllSystemConfigAdmin({});

  const handleCreateNewSite = () => {
    navigate(`/admin/${ROUTES.SITES}/new/information`);
  };

  const columns: ColumnDef<SystemConfig>[] = [];

  columns.push({
    cell: (row) => row.row.original.organizationName,
    accessorKey: "organizationName",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("organization-name")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "organizationName",
  });

  columns.push({
    cell: (row) => row.row.original.name,
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("site")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "site",
  });

  columns.push({
    cell: (row) => row.row.original.organizationNumber,
    accessorKey: "organizationNumber",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("organization-number")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "organization-number",
  });

  columns.push({
    cell: (row) => row.row.original.addressInfo.address,
    accessorFn: (row) => row.addressInfo.address,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("address-1")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "address-1",
  });

  columns.push({
    cell: (row) => row.row.original.addressInfo.address2,
    accessorFn: (row) => row.addressInfo.address2,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("address-2")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "address-2",
  });

  columns.push({
    cell: (row) => row.row.original.addressInfo.zip,
    accessorFn: (row) => row.addressInfo.zip,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("zip-code")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "zip-code",
  });

  columns.push({
    cell: (row) => row.row.original.addressInfo.city,
    accessorFn: (row) => row.addressInfo.city,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("city")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "city",
  });

  columns.push({
    cell: (row) => row.row.original.addressInfo.state,
    accessorFn: (row) => row.addressInfo.state,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("state")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "state",
  });

  columns.push({
    cell: (row) => row.row.original.addressInfo.country,
    accessorFn: (row) => row.addressInfo.country,
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("country")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "country",
  });

  columns.push({
    cell: (row) => row.row.original.siteId,
    accessorKey: "siteId",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("customer-number")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "customer-number",
  });

  columns.push({
    cell: (row) =>
      row.row.original.startDate &&
      format(row.row.original.startDate, "yyyy-MM-dd"),
    accessorKey: "startDate",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("customer-from")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "startDate",
  });

  columns.push({
    cell: (row) =>
      row.row.original.endDate &&
      format(row.row.original.endDate, "yyyy-MM-dd"),
    accessorKey: "endDate",
    header: ({ column }) => {
      return (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("customer-to")}
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </div>
      );
    },
    size: 1,
    id: "endDate",
  });

  const table = useReactTable({
    data: data?.sites ?? [],
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <>
      <div className="p-4 text-primary-text">
        <Card className="p-0">
          <div className="flex items-center justify-between border-b border-highlighted-backplate p-4">
            <CardTitle>{t("companies")}</CardTitle>
            <div className=" flex flex-nowrap space-x-2">
              <Button
                variant="primary"
                onClick={handleCreateNewSite}
                loading={createLoading}
              >
                {t("create-new")}
              </Button>
            </div>
          </div>

          <div className="p-4">
            <div className="relative mt-4">
              {(isLoading || isRefetching) && (
                <div className="absolute z-20 flex h-full w-full items-center justify-center backdrop-blur-sm">
                  <Loader2 className="h-20 w-20 animate-spin text-primary-color" />
                </div>
              )}

              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>

                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className=" cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/admin/${ROUTES.SITES}/${row.original.siteId}/information`,
                          )
                        }
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center text-primary-text"
                      >
                        {t("no-results")}.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default SuperadminSitesPage;
