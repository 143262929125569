import { useCreateGuest, useGetGuests, useUpdateGuest } from "@api/guests";
import { useDebounce } from "@hooks/use-debounce";
import { Button } from "@primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { Input } from "@primitives/input";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { ChevronDown, ChevronUp, Plus, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "@hooks/use-toast";
import { queryClient } from "query-client";
import { Guest } from "../../../../../../../api-contracts/guests";
import TitleSelect from "./title-select";
import GuestOrganizationSimpleSelect from "./guest-organization-simple-select";
import { cn } from "@shared/utils/css";
import CountrySelect from "../../../../../shared/components/country-select";

interface Props {
  value?: Guest;
  onValueChange?: (value?: Guest) => void;
  id?: string;
  disabled?: boolean;
}

const initialGuest: Guest = {
  id: "",
  name: null,
  surname: null,
  passportNumber: null,
  address: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  countryCode: null,
  phone: null,
  phone2: null,
  email: null,
  title: null,
  organization: null,
  tags: [],
  invoiceReference: null,
  invoiceName: null,
  invoiceAddress: null,
  invoiceAddress2: null,
  invoiceZip: null,
  invoiceCity: null,
  invoiceState: null,
  invoiceCountry: null,
  types: ["guest"],
};

const GuestSelect = ({ value, onValueChange, id, disabled }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = React.useState<"search" | "new">();
  const [t] = useTranslation();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [guest, setGuest] = useState<Guest>(initialGuest);

  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const {
    data: guestsData,
    isLoading: guestsIsLoading,
    isRefetching: guestsIsRefetching,
  } = useGetGuests({
    variables: {
      limit: 200,
      page: 1,
      pattern: debouncedSearchTerm || "",
      types: ["guest"],
    },
    enabled: debouncedSearchTerm.length > 0,
  });

  const createGuest = useCreateGuest();
  const updateGuest = useUpdateGuest();

  useEffect(() => {
    setGuest(value ? { ...value } : initialGuest);
  }, [value]);

  const handleCreate = async () => {
    try {
      setCreateLoading(true);
      const g = await createGuest.mutateAsync({
        name: guest.name?.trim() || null,
        surname: guest.surname?.trim() || null,
        titleId: guest.title?.id || null,
        passportNumber: guest.passportNumber?.trim() || null,
        address: guest.address?.trim() || null,
        address2: guest.address2?.trim() || null,
        city: guest.city?.trim() || null,
        zip: guest.zip?.trim() || null,
        countryCode: guest.countryCode?.trim() || null,
        phone: guest.phone?.trim() || null,
        email: guest.email?.trim() || null,
        orgId: guest.organization?.id || null,
        state: guest.state?.trim() || null,
        phone2: guest.phone2?.trim() || null,
        invoiceReference: guest.invoiceReference?.trim() || null,
        invoiceName: guest.invoiceName?.trim() || null,
        invoiceAddress: guest.invoiceAddress?.trim() || null,
        invoiceAddress2: guest.invoiceAddress2?.trim() || null,
        invoiceZip: guest.invoiceZip?.trim() || null,
        invoiceCity: guest.invoiceCity?.trim() || null,
        invoiceState: guest.invoiceState?.trim() || null,
        invoiceCountry: guest.invoiceCountry?.trim() || null,
        types: guest.types || null,
      });

      setGuest(g);
      onValueChange?.(g);
      setOpen(undefined);
      queryClient.invalidateQueries({
        queryKey: useGetGuests.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: guest.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setUpdateLoading(true);
      const g = await updateGuest.mutateAsync({
        id: guest.id!,
        name: guest.name?.trim() || null,
        surname: guest.surname?.trim() || null,
        titleId: guest.title?.id || null,
        passportNumber: guest.passportNumber?.trim() || null,
        address: guest.address?.trim() || null,
        address2: guest.address2?.trim() || null,
        city: guest.city?.trim() || null,
        zip: guest.zip?.trim() || null,
        countryCode: guest.countryCode?.trim() || null,
        phone: guest.phone?.trim() || null,
        email: guest.email?.trim() || null,
        orgId: guest.organization?.id || null,
        state: guest.state?.trim() || null,
        phone2: guest.phone2?.trim() || null,
        invoiceReference: guest.invoiceReference?.trim() || null,
        invoiceName: guest.invoiceName?.trim() || null,
        invoiceAddress: guest.invoiceAddress?.trim() || null,
        invoiceAddress2: guest.invoiceAddress2?.trim() || null,
        invoiceZip: guest.invoiceZip?.trim() || null,
        invoiceCity: guest.invoiceCity?.trim() || null,
        invoiceState: guest.invoiceState?.trim() || null,
        invoiceCountry: guest.invoiceCountry?.trim() || null,
      });

      onValueChange?.(guest);
      setOpen(undefined);
      queryClient.invalidateQueries({
        queryKey: useGetGuests.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: guest.name }),
        className: "text-status-success",
        variant: "success",
      });
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        variant: "destructive",
        className: "text-status-error",
      });
    }
    setUpdateLoading(false);
  };

  return (
    <PopoverRoot
      open={!!open}
      onOpenChange={(o) =>
        disabled
          ? setOpen(undefined)
          : !o
            ? setOpen(undefined)
            : value
              ? setOpen("new")
              : setOpen("search")
      }
    >
      <PopoverTrigger
        asChild
        disabled={disabled}
        className=" flex-1 text-quaternary-text"
      >
        {value ? (
          <div
            className={cn(
              "flex h-full items-center justify-between rounded-lg bg-amber-500 px-4 py-2",
              {
                " opacity-70": disabled,
              },
            )}
          >
            {" "}
            <div>
              <p className=" text-sm font-extrabold text-quaternary-text">
                {value.name} {value.surname}
              </p>
              <p className=" text-xs font-normal text-quaternary-text">
                {value.phone}
              </p>
              <p className=" text-xs font-normal text-quaternary-text">
                {value.email}
              </p>
            </div>
            <div>
              {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </div>
          </div>
        ) : (
          <div className="relative h-full">
            <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
              <Search className="text-primary-icon-color" size={17} />
            </div>
            <Input
              autoComplete="off"
              disabled={disabled}
              id={id}
              className=" h-full pe-20 ps-10"
              placeholder={t("search-guest")}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setOpen("search");
              }}
            />
            <div className="absolute inset-y-0 end-0 flex items-center pe-3">
              <Button
                disabled={disabled}
                size="sm"
                variant="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("new");
                }}
              >
                <Plus />
              </Button>
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="PopoverContent p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {open === "search" && (
          <Command>
            <CommandList>
              <CommandEmpty>
                {debouncedSearchTerm.length === 0
                  ? t("start-typing-to-search")
                  : t("no-guests-found")}
              </CommandEmpty>
              <CommandGroup>
                {guestsData?.map((g) => (
                  <CommandItem
                    key={g.id}
                    value={g.id}
                    onSelect={(value) => {
                      onValueChange && onValueChange(g as any);
                      setOpen(undefined);
                    }}
                  >
                    <div>
                      <p className=" text-sm font-extrabold">
                        {g.name} {g.surname}
                      </p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {g.phone}
                      </p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {g.email}
                      </p>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        )}
        {open === "new" && (
          <div className="p-4">
            <label
              className=" block text-xs font-medium text-secondary-text"
              htmlFor="guestTitle"
            >
              {t("title")}
            </label>
            <TitleSelect
              value={guest.title || undefined}
              onValueChange={(v) => setGuest({ ...guest, title: v || null })}
              id="guestTitle"
            />

            <label
              className=" block text-xs font-medium text-secondary-text"
              htmlFor="guestName"
            >
              {t("name")}*
            </label>
            <Input
              id="guestName"
              value={guest?.name || ""}
              onChange={(e) => setGuest({ ...guest, name: e.target.value })}
            />

            <label
              className=" block text-xs font-medium text-secondary-text"
              htmlFor="guestSurname"
            >
              {t("surname")}*
            </label>
            <Input
              id="guestSurname"
              value={guest?.surname || ""}
              onChange={(e) => setGuest({ ...guest, surname: e.target.value })}
            />

            <label
              className=" block text-xs font-medium text-secondary-text"
              htmlFor="guestPassportNumber"
            >
              {t("passport-number")}
            </label>
            <Input
              id="guestPassportNumber"
              value={guest?.passportNumber || ""}
              onChange={(e) =>
                setGuest({ ...guest, passportNumber: e.target.value })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestAddress1"
            >
              {t("address-1")}
            </label>
            <Input
              id="guestAddress1"
              value={guest?.address || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  address: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestAddress2"
            >
              {t("address-2")}
            </label>
            <Input
              id="guestAddress2"
              value={guest?.address2 || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  address2: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestZipCode"
            >
              {t("zip-code")}
            </label>
            <Input
              id="guestZipCode"
              value={guest?.zip || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  zip: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestCity"
            >
              {t("city")}
            </label>
            <Input
              id="guestCity"
              value={guest?.city || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  city: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestState"
            >
              {t("state/province/region")}
            </label>
            <Input
              id="guestState"
              value={guest?.state || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  state: e.target.value || "",
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestCountry"
            >
              {t("country")}
            </label>
            <CountrySelect
              id="guestCountry"
              value={guest.countryCode || undefined}
              onValueChange={(v) =>
                setGuest({ ...guest, countryCode: v || null })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestPhone1"
            >
              {t("phone-1")}
            </label>
            <Input
              id="guestPhone1"
              value={guest?.phone || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  phone: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestPhone2"
            >
              {t("phone-2")}
            </label>
            <Input
              id="guestPhone2"
              value={guest?.phone2 || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  phone2: e.target.value,
                })
              }
            />

            <label
              className=" mt-1 block text-xs font-medium text-secondary-text"
              htmlFor="guestEmail"
            >
              {t("email")}
            </label>
            <Input
              id="guestEmail"
              value={guest?.email || ""}
              onChange={(e) =>
                setGuest({
                  ...guest,
                  email: e.target.value,
                })
              }
            />

            <label
              className=" block text-xs font-medium text-secondary-text"
              htmlFor="guestOrganization"
            >
              {t("organization")}
            </label>
            <GuestOrganizationSimpleSelect
              value={guest.organization || undefined}
              onValueChange={(v) =>
                setGuest({ ...guest, organization: v || null })
              }
              id="guestOrganization"
            />

            <div className="flex space-x-2">
              {!value && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="primary"
                  disabled={!guest.name?.trim() || !guest.surname?.trim()}
                  onClick={handleCreate}
                  loading={createLoading}
                >
                  {t("add")}
                </Button>
              )}
              {value && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="primary"
                  disabled={!guest.name?.trim() || !guest.surname?.trim()}
                  onClick={handleUpdate}
                  loading={updateLoading}
                >
                  {t("save")}
                </Button>
              )}
              {value && (
                <Button
                  className="mt-2"
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    onValueChange?.(undefined);
                    setOpen("search");
                  }}
                >
                  {t("remove")}
                </Button>
              )}
            </div>
          </div>
        )}
      </PopoverContent>
    </PopoverRoot>
  );
};

export default GuestSelect;
