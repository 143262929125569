import { Dot } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AddAddressDialog } from "./add-address-dialog";
import { Organization } from "../../../../../../api-contracts/customer-list/organization";

export const AddressDataStep = ({
  customers,
  addressData,
  setAddressData,
  invoiceData,
  setInvoiceAddressData,
  selectedAddressIndex,
  setSelectedAddressIndex,
  selectedInvoiceIndex,
  setSelectedInvoiceIndex,
}: {
  customers: Organization[];
  addressData: Partial<Organization>;
  setAddressData: React.Dispatch<React.SetStateAction<Partial<Organization>>>;
  invoiceData: Partial<Organization>;
  setInvoiceAddressData: React.Dispatch<
    React.SetStateAction<Partial<Organization>>
  >;
  selectedAddressIndex: number | null;
  setSelectedAddressIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selectedInvoiceIndex: number | null;
  setSelectedInvoiceIndex: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const { t } = useTranslation();
  const [openAddressDialog, setOpenAddressDialog] = useState<boolean>(false);
  const [openInvoiceAddressDialog, setOpenInvoiceAddressDialog] =
    useState<boolean>(false);

  const handleSelect = (
    customer: Organization,
    index: number,
    addressType: "address" | "invoice",
  ) => {
    if (addressType === "address") {
      if (selectedAddressIndex === index) {
        // Deselect
        setSelectedAddressIndex(null);
        setAddressData({
          address: "",
          address2: "",
          city: "",
          zip: "",
          state: "",
          countryCode: "",
        });
      } else {
        // Select
        setSelectedAddressIndex(index);
        setAddressData({
          address: customer.address,
          address2: customer.address2,
          city: customer.city,
          zip: customer.zip,
          state: customer.state,
          countryCode: customer.countryCode,
        });
      }
    } else if (addressType === "invoice") {
      if (selectedInvoiceIndex === index) {
        // Deselect
        setSelectedInvoiceIndex(null);
        setInvoiceAddressData({
          invoiceAddress: "",
          invoiceAddress2: "",
          invoiceCity: "",
          invoiceZip: "",
          invoiceState: "",
          invoiceCountry: "",
        });
      } else {
        // Select
        setSelectedInvoiceIndex(index);
        setInvoiceAddressData({
          invoiceAddress: customer.invoiceAddress,
          invoiceAddress2: customer.invoiceAddress2,
          invoiceCity: customer.invoiceCity,
          invoiceZip: customer.invoiceZip,
          invoiceState: customer.invoiceState,
          invoiceCountry: customer.invoiceCountry,
        });
      }
    }
  };

  const handleSaveDialog = (
    updatedAddress: Partial<Organization>,
    addressType: "address" | "invoice",
  ) => {
    if (addressType === "address") {
      setAddressData((prev) => ({
        ...prev,
        ...updatedAddress,
      }));
      setSelectedAddressIndex(-1); // Mark custom input as selected
    } else if (addressType === "invoice") {
      setInvoiceAddressData((prev) => ({
        ...prev,
        ...updatedAddress,
      }));
      setSelectedInvoiceIndex(-1); // Mark custom input as selected
    }
    addressType === "address"
      ? setOpenAddressDialog(false)
      : setOpenInvoiceAddressDialog(false);
  };

  return (
    <div className="flex h-full gap-4">
      {/* Regular Address Section */}
      <div className="flex w-full flex-col justify-between">
        <div>
          <p className="font-itc text-sm font-light">{t("address")}</p>
          {customers.map((customer, index) => {
            const isSelected = selectedAddressIndex === index;
            return (
              <div key={index} className="py-1">
                <AddressCard
                  customer={customer}
                  isSelected={isSelected}
                  addressType="address"
                  onClick={() => handleSelect(customer, index, "address")}
                />
              </div>
            );
          })}
          {/* Custom Dialog Input */}
          <AddAddressDialog
            addressType="address"
            addressData={addressData}
            isSelected={selectedAddressIndex === -1}
            onSaveDialog={(updatedAddress) =>
              handleSaveDialog(updatedAddress, "address")
            }
            openDialog={openAddressDialog}
            setOpenDialog={setOpenAddressDialog}
          />
        </div>
      </div>

      {/* Invoice Address Section */}
      <div className="flex w-full flex-col justify-between">
        <div>
          <p className="font-itc text-sm font-light">{t("invoice-address")}</p>
          {customers.map((customer, index) => {
            const isSelected = selectedInvoiceIndex === index;
            return (
              <div key={index} className="py-1">
                <AddressCard
                  customer={customer}
                  isSelected={isSelected}
                  addressType="invoice"
                  onClick={() => handleSelect(customer, index, "invoice")}
                />
              </div>
            );
          })}
          {/* Custom Dialog Input */}
          <AddAddressDialog
            addressType="invoice"
            addressData={invoiceData}
            regularAddressData={addressData}
            isSelected={selectedInvoiceIndex === -1}
            onSaveDialog={(updatedAddress) =>
              handleSaveDialog(updatedAddress, "invoice")
            }
            openDialog={openInvoiceAddressDialog}
            setOpenDialog={setOpenInvoiceAddressDialog}
          />
        </div>
      </div>
    </div>
  );
};

const AddressCard = ({
  customer,
  isSelected,
  addressType,
  onClick,
}: {
  customer: Organization;
  isSelected: boolean;
  addressType: "address" | "invoice";
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex w-full justify-between rounded-xl p-1 pl-2 text-left ${
        isSelected
          ? "bg-amber-500 text-quaternary-text"
          : "bg-secondary-card-backplate"
      } `}
    >
      <div className="flex flex-col text-sm font-medium">
        {addressType === "address" ? (
          <>
            <p>{customer.address || "\u00A0"}</p>
            <p>{customer.state || "\u00A0"}</p>
            <p>
              {customer.zip || "\u00A0"} {customer.city || "\u00A0"}
            </p>
            <p>{customer.countryCode || "\u00A0"}</p>
          </>
        ) : (
          <>
            <p>{customer.invoiceAddress || "\u00A0"}</p>
            <p>{customer.invoiceState || "\u00A0"}</p>
            <p>
              {customer.invoiceZip || "\u00A0"}{" "}
              {customer.invoiceCity || "\u00A0"}
            </p>
            <p>{customer.invoiceCountry || "\u00A0"}</p>
          </>
        )}
      </div>

      <div className="flex h-[32px] w-[32px] items-center justify-center rounded-lg bg-highlighted-backplate">
        {isSelected && <Dot className="text-quaternary-text" />}
      </div>
    </button>
  );
};
