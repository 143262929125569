import { createMutation, createQuery } from "react-query-kit";
import {
  CreateSiteRequest,
  CreateSiteResponse,
  GetAllSystemConfigResponse,
  GetDefaultRolesResponse,
  GetSitesResponse,
  GetUsersResponseWithSite,
} from "../../../../api-contracts/admin";
import { api } from "./api";
import {
  PatchSiteRequest,
  PatchSiteResponse,
} from "../../../../api-contracts/sites";

export const SITES_ADMIN_QUERY_KEY = "/api/admin/sites";
export const USERS_ADMIN_QUERY_KEY = "/api/admin/users";
export const DEFAULT_ROLES_QUERY_KEY = "/api/admin/default-roles";

export const useGetAllSitesAdmin = createQuery<GetSitesResponse, {}>({
  primaryKey: SITES_ADMIN_QUERY_KEY,
  queryFn: async () => api.get(`${SITES_ADMIN_QUERY_KEY}`),
});

export const useGetAllSystemConfigAdmin = createQuery<
  GetAllSystemConfigResponse,
  {}
>({
  primaryKey: `${SITES_ADMIN_QUERY_KEY}/system-config`,
  queryFn: async () => api.get(`${SITES_ADMIN_QUERY_KEY}/system-config`),
});

export const useCreateSiteAdmin = createMutation<
  CreateSiteResponse,
  CreateSiteRequest
>({
  mutationFn: async (site) => api.post(SITES_ADMIN_QUERY_KEY, site),
});

export const useGetAllUsersAdmin = createQuery<
  GetUsersResponseWithSite,
  { admin?: boolean }
>({
  primaryKey: USERS_ADMIN_QUERY_KEY,
  queryFn: async ({ queryKey: [, { admin }] }) =>
    api.get(`${USERS_ADMIN_QUERY_KEY}`, { admin: admin ? true : undefined }),
});

export const useGetDefaultRoles = createQuery<GetDefaultRolesResponse, {}>({
  primaryKey: DEFAULT_ROLES_QUERY_KEY,
  queryFn: async () => api.get(`${DEFAULT_ROLES_QUERY_KEY}`),
});
