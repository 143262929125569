import { OutOfOrderContextProvider } from "@pages/out-of-order/out-of-order-context.tsx";
import { OutOfOrderPage } from "@pages/out-of-order/out-of-order-page.tsx";

export const OutOfOrder = () => {
  return (
    <OutOfOrderContextProvider>
      <OutOfOrderPage />
    </OutOfOrderContextProvider>
  );
};
