import { Progress } from "@primitives/progress";
import { Circle, CircleCheck, CircleDot, LucideIcon } from "lucide-react";

export const StepIndicator = ({
  steps,
  currentStep,
}: {
  steps: { label: string }[];
  currentStep: number;
}) => {
  return (
    <ul className="flex space-x-4 py-2">
      {steps.map((step, index) => {
        const isCompleted = index < currentStep;
        const isCurrent = index === currentStep;

        let IconComponent = Circle;
        if (isCompleted) {
          IconComponent = CircleCheck;
        } else if (isCurrent) {
          IconComponent = CircleDot;
        }

        const progressValue = index <= currentStep ? 100 : 0;

        return (
          <li key={index} className="w-full justify-between">
            <ProgressBar
              icon={IconComponent}
              label={step.label}
              value={progressValue}
            />
          </li>
        );
      })}
    </ul>
  );
};

const ProgressBar = ({
  icon: Icon,
  label,
  value,
}: {
  icon: LucideIcon;
  label: string;
  value: number;
}) => {
  return (
    <div className="flex flex-col">
      <Icon width={"18px"} className={"text-primary-color"} />
      <div className="py-2">
        <Progress value={value} />
      </div>
      <p className="font-itc text-sm font-bold">{label}</p>
    </div>
  );
};
