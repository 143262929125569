import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ForgotPassword from "./auth/forgot-password";
import Login from "./auth/login";
import Logout from "./auth/logout";
import Protected from "./auth/protected";
import Verify from "./auth/verify";
import Root from "./root";
import Error from "./error";
import * as loginLoaderAction from "./auth/login.loader";
import * as superadminLoaderAction from "./auth/superadmin.loader";
import NotFound from "./not-found";
import { ROUTES } from "@shared/types/navigation";
import { Clusters, useProfileContext } from "@context/profile-context";
import { Loading } from "@primitives/loading";
import { Suspense, useEffect, useState } from "react";
import DashboardPage from "./dashboard/dashboard";
import ArrivalsPage from "./reception/arrivals";
import {
  Permission,
  PermissionGroup,
  PermissionKey,
  PermissionModule,
} from "../../../api-contracts/auth";
import DeparturesPage from "@pages/reception/departures";
import OccupantsPage from "@pages/reception/occupants";
import CategoryGridPage from "@pages/booking/category-grid";
import AssetGridPage from "@pages/booking/asset-grid";
import CommunicationSpheresPage from "@pages/settings/communication-spheres";
import CommunicationSphereDetailsPage from "@pages/settings/communication-spheres/communication-sphere-details";
import ReservationPage from "@pages/booking/reservations/reservation";
import ReservationsPage from "@pages/booking/reservations";
import PriceCalendarPage from "@pages/booking/price-calendar";
import UnallocatedPage from "@pages/booking/unallocated";
import PriceRulesPage from "@pages/booking/price-rules";
import LocationsPage from "@pages/settings/locations";
import Bookables from "@pages/settings/bookables";
import { BookableDetails } from "@pages/settings/bookables/bookable-details";
import CombinationDetailsPage from "@pages/settings/bookables/combination-details";
import FixtureAndAmenityPage from "@pages/settings/fixtures-and-amenities";
import TagsPage from "@pages/settings/tags";
import { StatusPage } from "@pages/settings/status";
import CategoriesDetailPage from "@pages/settings/categories/categories-detail";
import CategoriesPage from "@pages/settings/categories";
import TargetGroupsPage from "@pages/settings/target-groups";
import ChannelsPage from "@pages/settings/channels";
import TitlesPage from "@pages/settings/titles";
import { BridgesPage } from "@pages/settings/bridges";
import { GroupsPage } from "@pages/settings/groups";
import { BedTypesPage } from "@pages/settings/bed-types";
import SimpleSearchPage from "@pages/simple-search";
import { CategoryCluster } from "@pages/settings/category-cluster";
import FireListPage from "@pages/reception/fire-list";
import { hasPermissionMatch } from "@utils/permissions.ts";
import { OutOfOrder } from "@pages/out-of-order";
import Superadmin from "./auth/superadmin";
import CustomerListPage from "./customers";
import SuperadminSitesPage from "./superadmin/sites";
import { useAuth } from "@hooks/use-auth";
import SuperadminSitePage from "./superadmin/site";
import SuperadminUserPage from "./superadmin/user";

type RouteType = {
  path: string;
  component: JSX.Element;
  requirement: PermissionGroupKey;
  moduleRequirements: PermissionModule[];
};

interface Props {
  cluster: Clusters[];
}

type PermissionGroupKey<
  Group extends PermissionGroup = PermissionGroup,
  Key extends PermissionKey = PermissionKey,
> = `${Group}/${Key}`;

const defaultModules: PermissionModule[] = ["roomr", "campr"];
export const anyModuleReadPermission = (
  permissionSuffix: PermissionGroupKey,
  modules: PermissionModule[] = defaultModules,
): Permission[] =>
  modules.map((m) => `read/${m}/${permissionSuffix}` as Permission);

const createFullRouteRequirement = (
  modules: PermissionModule[],
  groupKey: PermissionGroupKey,
): Permission[] =>
  modules.map((module) => `read/${module}/${groupKey}` as Permission);

const createCurrentModuleRoute = (
  activeModule: PermissionModule,
  isCommon: boolean,
  groupKey: PermissionGroupKey,
): Permission => {
  if (isCommon) {
    return `read/common/${groupKey}`;
  }
  return `read/${activeModule}/${groupKey}`;
};

const regularModules: PermissionModule[] = ["campr", "roomr"];

const adminRoutes = [
  {
    path: ROUTES.SITES,
    component: <SuperadminSitesPage />,
  },
  {
    path: `${ROUTES.SITES}/:id`,
    component: <SuperadminSitePage />,
  },
  {
    path: `${ROUTES.SITES}/:id/:tab`,
    component: <SuperadminSitePage />,
  },
  {
    path: `${ROUTES.SITES}/:id/users/:userId`,
    component: <SuperadminUserPage />,
  },
];

const createRegularModuleRoutes = (
  moduleCluster: Clusters[],
  module: PermissionModule,
): RouteType[] => {
  const routes: RouteType[] = [];
  moduleCluster.forEach((cl) =>
    routes.push(
      {
        path: `${ROUTES.CATEGORY_GRID}/${cl.name}`,
        component: <CategoryGridPage tab={cl.categoryTypes} />,
        requirement: "booking/category-grid",
        moduleRequirements: [module],
      },
      {
        path: `${ROUTES.ASSET_GRID}/${cl.name}`,
        component: <AssetGridPage tab={cl.categoryTypes} />,
        requirement: "booking/bookable-grid",
        moduleRequirements: [module],
      },
      {
        path: `${ROUTES.ARRIVALS}/${cl.name}`,
        component: <ArrivalsPage tab={cl.categoryTypes} />,
        requirement: "reception/arrivals",
        moduleRequirements: [module],
      },
      {
        path: `${ROUTES.DEPARTURES}/${cl.name}`,
        component: <DeparturesPage tab={cl.categoryTypes} />,
        requirement: "reception/departures",
        moduleRequirements: [module],
      },
      {
        path: `${ROUTES.OCCUPANTS}/${cl.name}`,
        component: <OccupantsPage tab={cl.categoryTypes} />,
        requirement: "reception/occupants",
        moduleRequirements: [module],
      },
      {
        path: `${ROUTES.PRICE_RULES}/${cl.name}`,
        component: <PriceRulesPage tab={cl.categoryTypes} />,
        requirement: "pricing/price-seasons",
        moduleRequirements: [module],
      },
      {
        path: `${ROUTES.PRICE_CALENDAR}/${cl.name}`,
        component: <PriceCalendarPage tab={cl.categoryTypes} />,
        requirement: "pricing/price-calendar",
        moduleRequirements: [module],
      },
    ),
  );
  return routes;
};

export function Router(): JSX.Element {
  const { module, routePermissions, activeCluster, clusters } =
    useProfileContext();
  const [filteredRoutes, setFilteredRoutes] = useState<RouteType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!module || !routePermissions.length) {
      setIsLoading(user?.type !== "admin" ? true : false);
      return;
    }

    const camprRoutes = createRegularModuleRoutes(clusters.campr, "campr");
    const roomrRoutes = createRegularModuleRoutes(clusters.roomr, "roomr");

    let clusterRoutes: RouteType[] = [...camprRoutes, ...roomrRoutes];

    clusterRoutes.push(
      {
        path: `${ROUTES.ARRIVALS}/all`,
        component: <ArrivalsPage tab={["all"]} />,
        requirement: "reception/arrivals",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.DEPARTURES}/all`,
        component: <DeparturesPage tab={["all"]} />,
        requirement: "reception/departures",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.OCCUPANTS}/all`,
        component: <OccupantsPage tab={["all"]} />,
        requirement: "reception/occupants",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.COMMUNICATION_SPHERES}`,
        component: <CommunicationSpheresPage />,
        requirement: "settings/communication-spheres",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.COMMUNICATION_SPHERES}/:id`,
        component: <CommunicationSphereDetailsPage />,
        requirement: "settings/communication-spheres",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.RESERVATIONS}/:id`,
        component: <ReservationPage />,
        requirement: "booking/reservation",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.RESERVATIONS}`,
        component: <ReservationsPage />,
        requirement: "booking/reservation",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.UNALLOCATED}/:from/:to`,
        component: <UnallocatedPage />,
        requirement: "booking/reservation",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.LOCATIONS}`,
        component: <LocationsPage />,
        requirement: "settings/locations",
        moduleRequirements: ["common"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ASSETS}`,
        component: <Bookables tab="all" />,
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ROOMS}`,
        component: <Bookables tab="room" />,
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ROOMS}/:id`,
        component: <BookableDetails type={"room"} categoryTypes={["room"]} />,
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.ROOMS}/:id/duplicate`,
        component: <BookableDetails type={"room"} categoryTypes={["room"]} />,
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.AREAS}`,
        component: (
          <Bookables tab={module === "roomr" ? "area" : "campr-area"} />
        ),
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.AREAS}/:id`,
        component:
          module === "roomr" ? (
            <BookableDetails type={"area"} categoryTypes={["area"]} />
          ) : (
            <BookableDetails
              type={"campr-area"}
              categoryTypes={["campr-area"]}
            />
          ),
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.AREAS}/:id/duplicate`,
        component:
          module === "roomr" ? (
            <BookableDetails type={"area"} categoryTypes={["area"]} />
          ) : (
            <BookableDetails
              type={"campr-area"}
              categoryTypes={["campr-area"]}
            />
          ),
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.BEDS}`,
        component: <Bookables tab="bed" />,
        requirement: "settings/bookables",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.BEDS}/:id`,
        component: <BookableDetails type={"bed"} categoryTypes={["bed"]} />,
        requirement: "settings/bookables",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.BEDS}/:id/duplicate`,
        component: <BookableDetails type={"bed"} categoryTypes={["bed"]} />,
        requirement: "settings/bookables",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}`,
        component: <Bookables tab="dormitory" />,
        requirement: "settings/bookables",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}/:id`,
        component: (
          <CombinationDetailsPage
            type={"dormitory"}
            categoryTypes={["dormitory"]}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}/:id/duplicate`,
        component: (
          <CombinationDetailsPage
            type={"dormitory"}
            categoryTypes={["dormitory"]}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`,
        component: (
          <Bookables
            tab={module === "roomr" ? "combination" : "campr-combination"}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}/:id`,
        component: (
          <CombinationDetailsPage
            type={"combination"}
            categoryTypes={["room", "area"]}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.GROUPS}`,
        component: <GroupsPage />,
        requirement: "settings/grouping",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BRIDGES}`,
        component: <BridgesPage />,
        requirement: "settings/bridges",
        moduleRequirements: ["common"],
      },
      {
        path: `${ROUTES.TITLES}`,
        component: <TitlesPage />,
        requirement: "settings/titles",
        moduleRequirements: ["common"],
      },
      {
        path: `${ROUTES.CHANNELS}`,
        component: <ChannelsPage />,
        requirement: "settings/channels",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.TARGET_GROUPS}`,
        component: <TargetGroupsPage />,
        requirement: "settings/target-groups",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.CATEGORIES}/:type/`,
        component: <CategoriesPage />,
        requirement: "settings/categories",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.CATEGORIES}/:type/:id/`,
        component: <CategoriesDetailPage />,
        requirement: "settings/categories",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.CATEGORIES}/:type/:id/duplicate`,
        component: <CategoriesDetailPage />,
        requirement: "settings/categories",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.STATUS}`,
        component: <StatusPage />,
        requirement: "settings/booking-status",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.TAGS}`,
        component: <TagsPage />,
        requirement: "settings/tags",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.AMENITIES}`,
        component: <FixtureAndAmenityPage />,
        requirement: "settings/amenities",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BED_TYPES}`,
        component: <BedTypesPage />,
        requirement: "settings/bed-capacity",
        moduleRequirements: ["roomr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.PLOTS}`,
        component: <Bookables tab={"plot"} />,
        requirement: "settings/bookables",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.CABINS}`,
        component: <Bookables tab={"cabin"} />,
        requirement: "settings/bookables",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`,
        component: <Bookables tab={"combination"} />,
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}`,
        component: <Bookables tab={"misc"} />,
        requirement: "settings/bookables",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.CABINS}/:id`,
        component: (
          <BookableDetails
            type={"cabin"}
            categoryTypes={["cabin", "caravan", "glamping"]}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.PLOTS}/:id`,
        component: (
          <BookableDetails
            type={"plot"}
            categoryTypes={["tent", "motorhome", "caravan"]}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}/:id`,
        component: <BookableDetails type={"misc"} categoryTypes={["misc"]} />,
        requirement: "settings/bookables",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}/:id`,
        component: (
          <CombinationDetailsPage
            type={"combination"}
            categoryTypes={["room", "area"]}
          />
        ),
        requirement: "settings/bookables",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.FIRE_LIST}`,
        component: <FireListPage />,
        requirement: "reception/fire-list",
        moduleRequirements: regularModules,
      },
      {
        path: `${ROUTES.OUT_OF_ORDER}`,
        component: <OutOfOrder />,
        requirement: "out-of-order/out-of-order",
        moduleRequirements: ["maintainr"],
      },
      {
        path: `${ROUTES.CATEGORY_CLUSTER}`,
        component: <CategoryCluster />,
        requirement: "settings/category-clusters",
        moduleRequirements: ["campr"],
      },
      {
        path: `${ROUTES.SIMPLE_SEARCH}`,
        component: <SimpleSearchPage />,
        requirement: "general/search-quick",
        moduleRequirements: ["common"],
      },
      {
        path: `${ROUTES.CUSTOMER_LIST}/organization`,
        component: <CustomerListPage tab="organization" />,
        requirement: "customers/customer-list",
        moduleRequirements: ["common"],
      },
      {
        path: `${ROUTES.CUSTOMER_LIST}/people`,
        component: <CustomerListPage tab="people" />,
        requirement: "customers/customer-list",
        moduleRequirements: ["common"],
      },
    );
    /** Defined routes and its permissions */
    const routes: RouteType[] = clusterRoutes;

    /** Filter routes based on permissions */
    const filteredRoutes = routes.filter((route) =>
      hasPermissionMatch(
        routePermissions,
        createFullRouteRequirement(route.moduleRequirements, route.requirement),
      ),
    );
    setFilteredRoutes(filteredRoutes);
    setIsLoading(false);
  }, [module, routePermissions, activeCluster]);

  /** Converting route object into a flattened array with permissions */
  const flattenedPermissions = Array.from(
    new Set(
      filteredRoutes.flatMap((route) =>
        createCurrentModuleRoute(
          module,
          route.moduleRequirements.includes("common"),
          route.requirement,
        ),
      ),
    ),
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={`/`} element={<Root />} errorElement={<Error />}>
        {/* Public pages */}
        <Route path="/login" element={<Login />} {...loginLoaderAction} />
        <Route
          path="/superadmin"
          element={<Superadmin />}
          {...superadminLoaderAction}
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify" element={<Verify />} />

        {/* Protected routes */}
        <Route
          element={
            <Protected
              routePermissions={flattenedPermissions}
              module={module}
            />
          }
        >
          <Route path={`/${module}`} element={<DashboardPage />} />
          {/** Mapping routes for the selected module */}
          {filteredRoutes.map(({ path, component }) => (
            <Route key={path} path={`/${module}/${path}`} element={component} />
          ))}
          {user?.type === "admin" &&
            adminRoutes.map(({ path, component }) => (
              <Route key={path} path={`admin/${path}`} element={component} />
            ))}
        </Route>

        {/* Unmatched paths */}
        <Route
          path="*"
          element={
            !isLoading ? (
              <NotFound />
            ) : (
              <div className=" h-screen w-screen">
                <Loading />
              </div>
            )
          }
        />
      </Route>,
    ),
  );

  return (
    <Suspense
      fallback={
        <div className=" h-screen w-screen">
          <Loading />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default Router;
