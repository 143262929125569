import React, { useContext, useEffect } from "react";
import { SearchBar } from "@primitives/search-bar.tsx";
import { useTranslation } from "react-i18next";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { debounce } from "lodash";

export const Search = () => {
  const { t } = useTranslation();
  const {
    setOutOfOrders,
    searchTerm,
    setSearch,
    filteredData,
    outOfOrdersData,
  } = useContext(OutOfOrderContext);

  const executeSearch = debounce(async () => {
    if (!filteredData?.outOfOrders) return;
    const data = filteredData?.outOfOrders.filter(
      (u) =>
        u.asset.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        u.reason?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    searchTerm.length === 0
      ? setOutOfOrders(filteredData)
      : setOutOfOrders({ outOfOrders: data });
  }, 200);

  useEffect(() => {
    executeSearch();
    return () => executeSearch.cancel();
  }, [searchTerm, filteredData]);

  return (
    <div className="max-w-[275px]">
      <SearchBar
        placeholder={t("search")}
        onChange={(e) => setSearch(e.target.value)}
        value={searchTerm}
      />
    </div>
  );
};
