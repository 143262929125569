import React, { useContext, useEffect, useState } from "react";
import { OutOfOrderContext } from "@pages/out-of-order/out-of-order-context.tsx";
import { Button } from "@primitives/button.tsx";
import { useGetOutOfOrders } from "@api/out-of-order.ts";
import { Filter } from "lucide-react";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { useTranslation } from "react-i18next";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label.tsx";
import { CategoryType } from "../../../../../api-contracts/categories";
import {
  GetOutOfOrdersResponse,
  OutOfOrderStatus,
  OutOfOrderType,
} from "../../../../../api-contracts/out-of-order";

export const Filtering = () => {
  const { t } = useTranslation();
  const { setOutOfOrders, setFilter } = useContext(OutOfOrderContext);
  const { data: outOfOrders } = useGetOutOfOrders({});
  const [data, setData] = useState<GetOutOfOrdersResponse>({ outOfOrders: [] });

  const [filters, setFilters] = useState({
    status: ["active", "planned"] as OutOfOrderStatus[],
    categoryType: ["room", "area"] as string[],
    type: ["out-of-service", "out-of-order"] as OutOfOrderType[],
  });

  const handleCheckboxChange = (
    filterKey: "status" | "categoryType" | "type",
    value: string,
  ) => {
    setFilters((prevFilters) => {
      // @ts-ignore
      const isChecked = prevFilters[filterKey].includes(value);
      return {
        ...prevFilters,
        [filterKey]: isChecked
          ? prevFilters[filterKey].filter((item) => item !== value)
          : [...prevFilters[filterKey], value],
      };
    });
  };

  const filterOutOfOrders = () => {
    const filteredOutOfOrders = outOfOrders?.outOfOrders.filter((item) => {
      const statusMatch =
        filters.status.length === 0 || filters.status.includes(item.status);

      const typeMatch =
        filters.type.length === 0 || filters.type.includes(item.type);

      const categoryTypeMatch =
        filters.categoryType.length === 0 ||
        filters.categoryType.some((filter) =>
          item.categoryTypes.includes(filter as CategoryType),
        );
      if (!statusMatch || !typeMatch || !categoryTypeMatch) {
        setOutOfOrders({ outOfOrders: [] });
      }
      return statusMatch && typeMatch && categoryTypeMatch;
    });
    if (!filteredOutOfOrders) return;
    setFilter({ outOfOrders: filteredOutOfOrders });
  };

  useEffect(() => {
    if (outOfOrders?.outOfOrders) {
      filterOutOfOrders();
    }
  }, [filters, outOfOrders]);

  const resetFilters = () => {
    setFilters({
      status: ["active", "planned"] as OutOfOrderStatus[],
      categoryType: ["room", "area"],
      type: ["out-of-service", "out-of-order"] as OutOfOrderType[],
    });
  };

  const statuses: OutOfOrderStatus[] = [
    "active" as OutOfOrderStatus,
    "planned" as OutOfOrderStatus,
    "ended" as OutOfOrderStatus,
    "removed" as OutOfOrderStatus,
  ];
  const types: OutOfOrderType[] = [
    "out-of-order" as OutOfOrderType,
    "out-of-service" as OutOfOrderType,
  ];
  const categoryTypes: string[] = ["room", "area"];
  return (
    <>
      <DropdownMenuRoot>
        <DropdownMenuTrigger asChild>
          <Button variant={"secondary"} size={"md"}>
            <Filter
              strokeWidth={1}
              size={18}
              className={"mr-2 items-center "}
            />
            {t("show")}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="rad mr-2 flex max-h-[55vh] min-w-[300px] flex-col items-start justify-start overflow-y-auto rounded-lg px-4">
          <p className={" pb-2 pt-4  text-sm font-extrabold"}>
            {t("status").charAt(0).toUpperCase() + t("status").slice(1)}
          </p>
          <div>
            {statuses.map((status) => (
              <div className={"mb-2.5 items-center"} key={status}>
                <CheckboxWithLabel
                  label={t(status)}
                  checked={filters.status.includes(status)}
                  onCheckedChange={() => handleCheckboxChange("status", status)}
                />
              </div>
            ))}
          </div>
          <p className={" pb-2 text-sm font-extrabold"}>
            {t("block").charAt(0).toUpperCase() + t("block").slice(1)}
          </p>
          <div>
            {types.map((type) => (
              <div className={"mb-2.5 items-center"} key={type}>
                <CheckboxWithLabel
                  label={t(type)}
                  checked={filters.type.includes(type)}
                  onCheckedChange={() => handleCheckboxChange("type", type)}
                />
              </div>
            ))}
          </div>
          <p className={" pb-2 text-sm font-extrabold"}>
            {t("category-types").charAt(0).toUpperCase() +
              t("category-types").slice(1)}
          </p>
          <div>
            {categoryTypes.map((category) => (
              <div className={" items-center"} key={category}>
                <CheckboxWithLabel
                  label={t(category)}
                  checked={filters.categoryType.includes(category)}
                  onCheckedChange={() =>
                    handleCheckboxChange("categoryType", category)
                  }
                />
              </div>
            ))}
          </div>
          <div
            className={
              "mb-2.5 mt-2.5 flex h-10 w-full justify-center border-t-2 border-highlighted-backplate  pt-4"
            }
          >
            <p
              className={"text cursor-pointer text-sm font-extrabold "}
              onClick={resetFilters}
            >
              {t("reset-filters")}
            </p>
          </div>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </>
  );
};
