import { Filters } from "@pages/simple-search/search-types";
import { Address } from "./address";
import { InputWithLabel } from "./input-with-label";
import { Tags } from "./tags";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Organization = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    name: filters.organization.name,
    organizationNumber: filters.organization.organizationNumber,
    phone: filters.organization.phone,
    email: filters.organization.email,
    tags: filters.organization.tags,
    address: filters.organization.address,
    address2: filters.organization.address2,
    zip: filters.organization.zip,
    city: filters.organization.city,
    state: filters.organization.state,
    countryCode: filters.organization.countryCode, // Change to country?
  });

  const { t } = useTranslation();

  useEffect(() => {
    setTempInput({
      name: filters.organization.name,
      organizationNumber: filters.organization.organizationNumber,
      phone: filters.organization.phone,
      email: filters.organization.email,
      tags: filters.organization.tags,
      address: filters.organization.address,
      address2: filters.organization.address2,
      zip: filters.organization.zip,
      city: filters.organization.city,
      state: filters.organization.state,
      countryCode: filters.organization.countryCode,
    });
  }, [filters.organization]);

  const handleInputChange = (
    key: keyof typeof tempInput,
    value: string | string[],
  ) => {
    setTempInput((prev) => ({ ...prev, [key]: value }));
    const filterKey = key === "countryCode" ? "country" : key;
    updateFilter("organization", filterKey, value);
  };

  return (
    <div className="flex flex-col pb-4">
      <InputWithLabel
        label={t("name")}
        value={tempInput.name}
        onChange={(e) => handleInputChange("name", e.target.value)}
      />
      <InputWithLabel
        label={t("organization-number")}
        value={tempInput.organizationNumber}
        onChange={(e) =>
          handleInputChange("organizationNumber", e.target.value.trim())
        }
      />
      <InputWithLabel
        label={t("phone")}
        value={tempInput.phone}
        onChange={(e) => handleInputChange("phone", e.target.value.trim())}
      />

      <InputWithLabel
        label={t("email")}
        value={tempInput.email}
        onChange={(e) => handleInputChange("email", e.target.value.trim())}
      />
      <Tags
        disabled
        selectedTags={tempInput.tags ?? []}
        onValueChange={(newTags) => handleInputChange("tags", newTags)}
      />
      <Address
        address={{
          address: tempInput.address,
          address2: tempInput.address2,
          zip: tempInput.zip,
          city: tempInput.city,
          state: tempInput.state,
          countryCode: tempInput.countryCode,
        }}
        handleInputChange={handleInputChange}
      />
    </div>
  );
};
