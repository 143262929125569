import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Dot } from "lucide-react";
import { columnHeaderDef } from "./merge-steps";
import CustomFloatingLabelInput from "./custom-floating-input";
import { Organization } from "../../../../../../api-contracts/customer-list/organization";

export const CompanyDataStep = ({
  customers,
  companyData,
  setCompanyData,
  columnHeaders,
}: {
  customers: Organization[];
  companyData: Partial<
    Record<
      keyof Organization,
      { value: string; rowId: string; source: "table" | "input" }
    >
  >;
  setCompanyData: Dispatch<
    SetStateAction<
      Partial<
        Record<
          keyof Organization,
          { value: string; rowId: string; source: "table" | "input" }
        >
      >
    >
  >;
  columnHeaders: columnHeaderDef[];
}) => {
  const { t } = useTranslation();

  const handleRowSelect = (customer: Organization) => {
    setCompanyData((prev) => {
      const isFullySelected = columnHeaders.every((col) => {
        const key = col.key as keyof Organization;
        return prev[key]?.rowId === customer.id;
      });

      if (isFullySelected) {
        return columnHeaders.reduce(
          (acc, col) => {
            acc[col.key as keyof Organization] = {
              value: "",
              rowId: "",
              source: "table",
            };
            return acc;
          },
          {} as typeof prev,
        );
      } else {
        return columnHeaders.reduce(
          (acc, col) => {
            const key = col.key as keyof Organization;
            acc[key] = {
              value: String(customer[key] ?? ""),
              rowId: customer.id,
              source: "table",
            };
            return acc;
          },
          {} as typeof prev,
        );
      }
    });
  };

  const handleSelect = (column: columnHeaderDef, customer: Organization) => {
    const colKey = column.key as keyof Organization;
    const rowId = customer.id;
    const value = customer[colKey];

    setCompanyData((prev) => {
      const isAlreadySelected =
        prev[colKey]?.rowId === rowId && prev[colKey]?.value === value;

      if (isAlreadySelected) {
        return { ...prev, [colKey]: "" };
      }

      return {
        ...prev,
        [colKey]: { value, rowId },
      };
    });
  };

  const handleInputChange = (key: keyof Organization, value: string) => {
    setCompanyData((prev) => ({
      ...prev,
      [key]: { value, rowId: "custom", source: "input" },
    }));
  };

  return (
    <div className="flex h-full flex-col justify-between ">
      <div className="h-full overflow-auto">
        <table className="h-full w-full flex-grow border-collapse whitespace-nowrap text-left">
          <thead>
            <tr>
              <th />
              {columnHeaders.map((colHeader) => (
                <th
                  key={colHeader.key}
                  className="min-w-[210px] px-2 py-1 font-itc text-sm font-medium first:pl-0"
                >
                  {colHeader.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => {
              const isAllSelected = columnHeaders.every((col) => {
                const key = col.key as keyof Organization;
                return (
                  companyData[key]?.value === String(customer[key] ?? "") &&
                  companyData[key]?.rowId === customer.id
                );
              });
              return (
                <tr key={customer.id}>
                  <td className="my-1 flex h-[40px] items-center pr-2">
                    <button
                      onClick={() => handleRowSelect(customer)}
                      className={`flex h-[32px] w-[32px] items-center justify-center rounded-lg   hover:bg-hover ${isAllSelected ? "bg-amber-500" : "bg-highlighted-backplate"}`}
                    >
                      {isAllSelected ? (
                        <div className="rounded-lg bg-highlighted-backplate">
                          <Dot className="text-quaternary-text" />
                        </div>
                      ) : (
                        "\u00A0"
                      )}
                    </button>
                  </td>
                  {columnHeaders.map((col) => {
                    const isSelected =
                      companyData[col.key]?.rowId === customer.id &&
                      companyData[col.key]?.value === customer[col.key];
                    return (
                      <td key={col.key} className="px-2 py-1 first:pl-0">
                        <button
                          disabled={
                            customer[col.key]?.length === 0 ||
                            customer[col.key] === null
                          }
                          onClick={() => handleSelect(col, customer)}
                          className={`flex h-[40px] w-full min-w-[130px] max-w-[320px] items-center justify-between rounded-xl
                  ${
                    isSelected
                      ? "bg-amber-500 font-medium text-quaternary-text"
                      : "bg-secondary-card-backplate font-normal text-secondary-text"
                  }
                `}
                        >
                          <span className="mx-2 truncate">
                            {customer[col.key] || "\u00A0"}
                          </span>
                          <div className="mr-1 flex h-[32px] w-[32px] items-center justify-center rounded-lg bg-highlighted-backplate">
                            {isSelected && (
                              <Dot className="text-quaternary-text" />
                            )}
                          </div>
                        </button>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="h-full w-full align-bottom">
            <tr>
              <td className="pr-2" />
              {columnHeaders.map((col) => {
                const isColumnSelected = Boolean(companyData[col.key]?.value);
                const isInputSelected =
                  companyData[col.key]?.source === "input";
                return (
                  <td key={col.key} className="px-2 py-1 first:pl-0">
                    {col.key === "id" ? (
                      <div className="flex h-12 items-center">
                        <span className="font-itc text-sm font-light">
                          {t("add")}
                        </span>
                      </div>
                    ) : (
                      <CustomFloatingLabelInput
                        label={col.label}
                        value={
                          isInputSelected
                            ? companyData[col.key]?.value || ""
                            : ""
                        }
                        onChange={(e) =>
                          handleInputChange(col.key, e.target.value)
                        }
                        disabled={isColumnSelected && !isInputSelected}
                        isSelected={isInputSelected}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
