import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CreateGuestRequest,
  CreateGuestResponse,
  GetGuestResponse,
  PatchGuestRequest,
  PatchGuestResponse,
  SearchGuestQueryParams,
  SearchGuestResponse,
} from "../../../../api-contracts/guests";

export const GUESTS_QUERY_KEY = "guests";

export const useGetGuest = createQuery<GetGuestResponse, { id: string }>({
  primaryKey: GUESTS_QUERY_KEY,

  queryFn: async ({ queryKey: [, { id }] }) => {
    return api.get(`/api/${currentSite?.id}/${GUESTS_QUERY_KEY}/${id}`);
  },
});

export const useGetGuests = createQuery<
  SearchGuestResponse,
  SearchGuestQueryParams
>({
  primaryKey: GUESTS_QUERY_KEY,

  queryFn: async ({ queryKey: [, params] }) => {
    return api.get(
      `/api/${currentSite?.id}/${GUESTS_QUERY_KEY}`,
      Object.fromEntries(
        Object.entries(params)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => {
            if (typeof value === "string" || typeof value === "number") {
              return [key, encodeURIComponent(value)];
            }
            return [key, value];
          }),
      ),
    );
  },
});

export const useCreateGuest = createMutation<
  CreateGuestResponse,
  CreateGuestRequest
>({
  mutationFn: async (data: CreateGuestRequest) =>
    api.post(`/api/${currentSite?.id}/${GUESTS_QUERY_KEY}`, {
      ...data,
    }),
});

export const useUpdateGuest = createMutation<
  PatchGuestResponse,
  PatchGuestRequest & { id: string }
>({
  mutationFn: async ({ id, ...patch }: PatchGuestRequest & { id: string }) =>
    api.patch(`/api/${currentSite?.id}/${GUESTS_QUERY_KEY}/${id}`, {
      ...patch,
    }),
});
