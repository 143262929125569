import { useToast } from "@hooks/use-toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { queryClient } from "query-client";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationArrows } from "@components/navigation-arrows";
import { ROUTES } from "@shared/types/navigation";
import { Button } from "@primitives/button";
import { Card } from "@primitives/card";
import { FloatingLabelInput } from "@primitives/floating-input";
import DatePicker from "@primitives/date-picker";
import { User2 } from "lucide-react";
import { Label } from "@primitives/label";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { format } from "date-fns";
import { useGetImage, useUploadImage } from "@api/images";
import { FileUploader } from "react-drag-drop-files";
import { imageFileTypes } from "@components/media-library";
import { PostImageResponse } from "../../../../../api-contracts/images";
import { useCreateUser, useGetUser } from "@api/users";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group";
import { SITE_TABS } from "../site";
import { GenderType, User } from "../../../../../api-contracts/users";

const initialUser: User = {
  id: "",
  email: "",
  phone: null,
  firstName: "",
  lastName: null,
  employmentStartDate: null,
  employmentEndDate: null,
  gender: null,
  dateOfBirth: null,
  roles: [],
  active: false,
  description: null,
  languageCode: null,
  image: null,
};

const SuperadminUserPage = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { id, userId } = useParams();
  const [user, setUser] = useState<User>({ ...initialUser });
  const [uploadedImage, setUploadedImage] = useState<PostImageResponse>();
  const navigate = useNavigate();
  const uploadImg = useUploadImage();
  const createUser = useCreateUser();
  const newUser = userId === "new";
  const [isEditing, setIsEditing] = useState<boolean>(newUser);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetUser.getKey(),
    });
  }, []);

  const { data, isLoading, isRefetching } = useGetUser({
    variables: { id: userId ?? "", siteId: id },
    enabled: !!userId && !newUser,
  });

  const {
    data: imageData,
    isLoading: imageIsLoading,
    isRefetching: imageIsRefetching,
  } = useGetImage({
    variables: {
      id: uploadedImage?.id ?? user.image?.id ?? "",
      siteId: id,
      variant: "thumbnail",
    },
    enabled: !!uploadedImage,
  });

  useEffect(() => {
    setUser(data ? { ...data } : { ...initialUser });
    setUploadedImage(undefined);
  }, [data]);

  const handleCancel = () => {
    setUser(data ? { ...data } : { ...initialUser });
    setUploadedImage(undefined);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      if (!newUser) {
      } else {
        const res = await createUser.mutateAsync({
          email: user.email.trim(),
          firstName: user.firstName.trim(),
          lastName: user.lastName?.trim() ?? null,
          employmentStartDate: user.employmentStartDate ?? null,
          employmentEndDate: user.employmentEndDate ?? null,
          description: user.description?.trim() ?? null,
          phone: user.phone?.trim() ?? null,
          dateOfBirth: user.dateOfBirth?.trim() ?? null,
          gender: user.gender ?? null,
          imageId: uploadedImage?.id ?? user.image?.id ?? null,
          password: "123456", // TODO what do I set here?
          languageCode: user.languageCode ?? null,
          siteId: id,
          roleIds: [],
        });
        res.id;
        navigate(`/admin/${ROUTES.SITES}/${id}/${SITE_TABS.USERS}/${res.id}`, {
          replace: true,
        });
      }

      queryClient.invalidateQueries({
        queryKey: useGetUser.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: "TODO" }),
        className: "text-status-success",
        variant: "success",
      });
      setIsEditing(false);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  const uploadImage = async (file: File, tag: string, description: string) => {
    try {
      const res = await uploadImg.mutateAsync({
        file: file,
        tags: [tag],
        description,
        siteId: id,
      });
      setUploadedImage(res);
    } catch (err) {
      toast({
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div className="p-4 text-primary-text">
        <NavigationArrows rootPath={`/admin/${ROUTES.SITES}`} />
        <div className=" flex flex-nowrap items-center justify-between">
          <h2 className=" mt-4 text-2xl font-bold">
            {newUser
              ? t("create-new-user")
              : `${user.firstName ?? ""} ${user.lastName ?? ""}`}
          </h2>
          <div className=" flex items-center space-x-4">
            {isEditing && <Button onClick={handleSave}>{t("save")}</Button>}
            {isEditing && !newUser && (
              <Button variant="secondary" onClick={handleCancel}>
                {t("cancel")}
              </Button>
            )}
            {!isEditing && (
              <Button onClick={() => setIsEditing(true)}>{t("edit")}</Button>
            )}
          </div>
        </div>

        <div className=" mt-4 flex space-x-4">
          <Card className=" flex-1">
            <h3 className=" text-sm font-bold">{t("information")}</h3>
            <FloatingLabelInput
              disabled={!isEditing}
              required
              label={t("first-name")}
              value={user?.firstName}
              onChange={(e) =>
                setUser({
                  ...user,
                  firstName: e.target.value,
                })
              }
              id="first-name"
              className=" mt-4"
            />
            <FloatingLabelInput
              disabled={!isEditing}
              label={t("surname")}
              value={user?.lastName ?? undefined}
              onChange={(e) =>
                setUser({
                  ...user,
                  lastName: e.target.value,
                })
              }
              id="last-name"
              className=" mt-4"
            />
            <FloatingLabelInput
              disabled={!isEditing}
              label={t("phone")}
              value={user?.phone ?? undefined}
              onChange={(e) =>
                setUser({
                  ...user,
                  phone: e.target.value,
                })
              }
              id="phone"
              className=" mt-4"
            />
            <FloatingLabelInput
              disabled={!isEditing}
              label={t("description")}
              value={user?.description ?? undefined}
              onChange={(e) =>
                setUser({
                  ...user,
                  description: e.target.value,
                })
              }
              id="description"
              className=" mb-2 mt-4"
            />
            <Label
              htmlFor="date-of-birth"
              className=" text-xs text-secondary-text"
            >
              {t("date-of-birth")}
            </Label>
            <DatePicker
              disabled={!isEditing}
              id="date-of-birth"
              value={user.dateOfBirth ? new Date(user.dateOfBirth) : undefined}
              onValueChange={(date) => {
                setUser({
                  ...user,
                  dateOfBirth: date ? format(date, "yyyy-MM-dd") : null,
                });
              }}
            />
            <div className="my-4">
              <RadioGroup
                disabled={!isEditing}
                value={user.gender ?? ""}
                onValueChange={(g) =>
                  setUser({
                    ...user,
                    gender: g as GenderType,
                  })
                }
                defaultValue="option-one"
                id="gender"
                className=" flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="male" id="male" />
                  <Label htmlFor="male">{t("male")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="female" id="female" />
                  <Label htmlFor="female">{t("female")}</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="other" id="other" />
                  <Label htmlFor="other">{t("other")}</Label>
                </div>
              </RadioGroup>
            </div>

            <div className="flex items-center space-x-4 rounded-lg bg-primary-button-backplate p-4">
              <FileUploader
                disabled={!isEditing}
                handleChange={(file: File) => uploadImage(file, "user", "user")}
                name="file"
                types={imageFileTypes}
              >
                <div className="flex items-center space-x-4">
                  {imageData?.length ? (
                    <img
                      className=" h-[80px] w-[80px] rounded-full"
                      aria-description={imageData[0]?.description ?? undefined}
                      src={imageData[0]?.url}
                    />
                  ) : (
                    <div className=" flex h-[80px] w-[80px] items-center justify-center rounded-full bg-secondary-card-backplate">
                      <User2 size={24} />
                    </div>
                  )}
                  <Button size="sm" disabled={!isEditing}>
                    {t("select-image")}
                  </Button>
                </div>
              </FileUploader>
              <Button
                disabled={!isEditing}
                variant="danger"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setUploadedImage(undefined);
                  setUser((u) => ({ ...u, image: null }));
                }}
              >
                {t("remove")}
              </Button>{" "}
            </div>
          </Card>
          <Card className=" flex-1">
            <h3 className=" text-sm font-bold">{t("account-settings")}</h3>
            <FloatingLabelInput
              disabled={!isEditing}
              required
              label={t("email")}
              value={user?.email}
              onChange={(e) =>
                setUser({
                  ...user,
                  email: e.target.value,
                })
              }
              id="email"
              className=" mt-4"
            />
            <div className=" mt-2">
              <Label
                htmlFor="language"
                className=" text-xs text-secondary-text"
              >
                {t("language")}
              </Label>
              <Select
                value={user.languageCode ?? undefined}
                disabled={!isEditing}
              >
                <SelectTrigger id="language" className="min-w-[170px] text-xs">
                  <SelectValue placeholder={t("language")} />
                </SelectTrigger>
                <SelectContent></SelectContent>
              </Select>
            </div>
            <h3 className=" my-4 text-sm font-bold">{t("active")}</h3>
            <div className=" flex space-x-4 ">
              <div className=" flex-1">
                <Label className="text-xs font-normal">{t("from")}</Label>
                <DatePicker
                  disabled={!isEditing}
                  clearable={false}
                  value={
                    user.employmentStartDate
                      ? new Date(user.employmentStartDate)
                      : undefined
                  }
                  onValueChange={(date) =>
                    date &&
                    setUser({
                      ...user,
                      employmentStartDate: format(date, "yyyy-MM-dd"),
                    })
                  }
                />
              </div>
              <div className=" flex-1">
                <Label className="text-xs font-normal">{t("to")}</Label>
                <DatePicker
                  disabled={!isEditing}
                  value={
                    user.employmentEndDate
                      ? new Date(user.employmentEndDate)
                      : undefined
                  }
                  onValueChange={(date) =>
                    date &&
                    setUser({
                      ...user,
                      employmentEndDate: format(date, "yyyy-MM-dd"),
                    })
                  }
                />
              </div>
            </div>
          </Card>
          <Card className=" flex-1">
            <h3 className=" text-sm font-bold">{t("roles")}</h3>
          </Card>
        </div>
      </div>
    </>
  );
};

export default SuperadminUserPage;
